.my-shop {
  width: 100%;
  padding-bottom: 30px;
  h2 {
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 20px;
    text-align: Left;
    @media screen and (max-width: 992px) {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 22px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: solid 1px #dadce0;
    background-color: #ffffff;
    @media screen and (max-width: 768px) {
      border: none;
    }
    &-header {
      width: 100%;
      border-radius: 0;
      margin-bottom: 10px;
      &-shape {
        width: 100%;
        display: block;
        background-color: #4c617c;
        height: 90px;
        border-radius: 8px 8px 0 0;
      }
      &-inner {
        width: 400px;
        background-color: #ffffff;
        padding: 10px;
        border-radius: 8px;
        margin: -45px auto 30px auto;
        border: solid 1px #dadce0;
        @media screen and (max-width: 480px) {
          width: calc(100% - 50px);
          margin-bottom: 20px;
        }
        /*box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.25);*/
        display: flex;
        flex-direction: row;
        &-image {
          width: 90px;
          height: 90px;
          min-width: 90px;
          border-radius: 5px;
          @media screen and (max-width: 480px) {
            width: 75px;
            height: 75px;
            min-width: 75px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        &-infos {
          width: calc(100% - 100px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 7px 15px;
          @media screen and (max-width: 480px) {
            width: calc(100% - 75px);
            padding: 7px 0 7px 15px;
          }
          h3 {
            font-size: 20px;
            margin-bottom: 14px;
            @media screen and (max-width: 480px) {
              font-size: 18px;
            }
          }
          ul {
            display: flex;
            flex-direction: column;
            li {
              font-size: 14px;
              margin-bottom: 5px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
    &-body {
      width: calc(100% - 60px);
      padding: 0 30px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 0;
      @media screen and (max-width: 480px) {
        width: 100%;
        padding: 0;
      }
      .dashboard-ad-view {
        width: calc(50% - 15px);
        .dashboard-ad-view-link {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
        }
        .dashboard-ad-view-image {
          width: 180px;
        }
        .dashboard-ad-view-link-text {
          width: calc(100% - 180px);
          padding: 20px;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          .dashboard-ad-view-link-image {
            width: 180px;
          }
          .dashboard-ad-view-link-text {
            width: calc(100% - 220px);
            padding: 20px;
          }
        }
        @media screen and (max-width: 768px) {
          .dashboard-ad-view-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .dashboard-ad-view-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
    }
  }
}