@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes upAndDown {
  0% {
    transform: translateY(0rem);
  }
  20% {
    transform: translateY(.25rem);
  }
  40%,
  100% {
    transform: translateY(0rem);
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &-title {
    font-weight: 600;
    max-width: 6.3rem;
    height: 1.5rem;
    transform: scale(2);
    margin-bottom: 2rem;

    .text {
      fill: #f56b2b;
    }
  }

  &-letters {
    color: #f56b2b;
    margin-bottom: 1.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &-letter {
      display: block ;

      @for $i from 1 through 64 {
        &:nth-child(#{$i}) {
          animation: upAndDown 1s ease-in-out #{$i * .1}s infinite;
        }
      }
    }
  }

  &-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    width: 5rem;
    height: 5rem;
    z-index: 1;
    color: #f56b2b;
    transition: .2s;
    
    svg {
        position: absolute;

        &:first-child {
            animation: rotate 1s cubic-bezier(.785, .135, .15, .86) .4s infinite;
            font-size: 5rem;
        }

        &:nth-child(2) {
            animation: rotate 1s cubic-bezier(.785, .135, .15, .86) .2s infinite;
            font-size: 3.5rem;
        }

        &:last-child {
            animation: rotate 1s cubic-bezier(.785, .135, .15, .86) 0s infinite;
            font-size: 2rem;
        }                    
    }
}
}