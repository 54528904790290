.shop-details-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
  /*box-shadow: 0 1px 2px 0 rgba(60, 64, 67,  .3), 0 1px 3px 1px rgba(60, 64, 67, .15);*/
  transition: .2s ease-in-out;
  padding: 30px 30px 0 30px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    padding: 30px 20px 0 20px;
    flex-direction: column;
    border-radius: 0;
  }
  &-sub-categories {
    width: calc(50% - 15px);
    padding: 0 15px 0 0;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }
  }
  &-cities {
    width: calc(50% - 15px);
    padding: 0 0 0 15px;
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
    }
    button {
      width: 100%;
      display: flex;
      min-height: 40px;
      justify-content: flex-start;
      align-items: center;
      line-height: 22px;
      text-transform: none;
      font-size: 14px;
      color: #6f7072;
      border: 1px solid #cccccc !important;
      font-weight: normal;
      padding: 8px 30px 8px 10px;
      position: relative;
      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
    @media screen and (max-width: 480px) {
      margin-bottom: 0;
    }
    .MuiFormControl-root.select-town {
      width: 100%;
      font-size: .9rem !important;

      &.Mui-focused {
        background-color: #ffffff !important;
        color: #1877d3 !important;
        .MuiOutlinedInput-notchedOutline {
          border-color: #1877d3 !important;
        }
        .MuiInputLabel-outlined {
          transform: translate(14px, 14px) scale(1);
        }
      }
    }
  }
  &-submit {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: 0;
    .search-filters-submit {
      position: relative;
      top: 0;
      transform: translateY(50%);
      background: #1877d3 !important;
      color: #fff !important;
      width: max-content;
      align-self: center;
      box-shadow: 0 .1rem .2rem rgba(#000, .25) !important;
      padding: .375rem 3rem .25rem !important;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      border: solid 1px transparent !important;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        z-index: 1;
        top: 0;
      }
      &:hover {
        color: #1a73e9 !important;
        background: #ffffff !important;
        border-color: #1a73e9 !important;
      }
      &:disabled {
        background-color: #ccc;
        color: #000;
        border-color: #ccc;
        cursor: not-allowed;
        .MuiButton-label {
          .MuiCircularProgress-root {
            color: #000;
          }
        }
        &:hover {
          background-color: #ccc;
          color: #000000;
        }
      }
      .MuiButton-label {
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
          margin-left: 15px;
          color: #ffffff;
        }
      }
    }

    .search-filters-reset {
      position: relative;
      margin-left: 10px;
      //top: 10px;
      transform: translateY(50%);
      background: #f56b2b !important;
      color: #fff !important;
      width: max-content;
      align-self: center;
      box-shadow: 0 .1rem .2rem rgba(#000, .25) !important;
      padding: .375rem 3rem .25rem !important;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      border: solid 1px transparent !important;
      overflow: hidden;
      @media screen and (max-width: 768px) {
        z-index: 1;
        top: 0;
      }
      &:hover {
        color: #1a73e9 !important;
        background: #ffffff !important;
        border-color: #1a73e9 !important;
      }
      &:disabled {
        background-color: #ccc;
        color: #000;
        border-color: #ccc;
        cursor: not-allowed;
        .MuiButton-label {
          .MuiCircularProgress-root {
            color: #000;
          }
        }
        &:hover {
          background-color: #ccc;
          color: #000000;
        }
      }
      .MuiButton-label {
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
          margin-left: 15px;
          color: #ffffff;
        }
      }
    }
    
  }
}
