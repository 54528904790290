.discover-taari-container {
  width: 100%;
  display: flex;
  max-width: 65rem;
  flex-direction: column;
  margin: 70px auto 0 auto;
  padding: 70px 30px;
  height: auto;
  font-family: "Roboto", sans-serif;
  &-inner {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    margin-top: 30px;
    h2 {
      font-size: 22px;
      line-height: 26px;
      color: #000;
      margin: 30px 0 20px 0;
      font-weight: bolder;
    }
    h3 {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      font-size: 18px;
      line-height: 24px;
      color: #000;
      font-weight: 500;
      margin: 15px 0 5px 0;
      span {
        width: 34px;
        height: 34px;
        font-size: 18px;
        line-height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #1a73e9;
        margin-right: 15px;
        position: relative;
        border: solid 2px #1a73e9;
       /* &:after {
          content: "";
          display: block;
          width: 32px;
          height: 16px;
          border-top-left-radius: 40px;
          border-top-right-radius: 40px;
          border: 2px solid #1a73e9;
          border-bottom: 0;
          transform: rotate(45deg);
          position: absolute;
          right: -18px;
          top: -12px;
        }*/
      }
    }
    p {
      font-size: 16px;
      line-height: 22px;
      padding-left: 55px;
      &.except {
        padding-left: 0;
      }
      a {
        color: #1a73e9;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-header {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px 30px 0 30px;
      &-image {
        width: 80px;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      &-text {
        margin: 15px 0 0 0;
        h1 {
          font-weight: bolder;
          color: #ec6501;
          font-size: 28px;
          line-height: 32px;
          margin: 0;
        }
      }
    }
    &-body {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      margin: 40px 0;
      padding: 0 30px 30px 30px;
      &-item {
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
