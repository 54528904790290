.advertisements {
  width: 100%;
  max-width: 65rem;
  margin: 0 auto;
  @media only screen and (max-width: 1150px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
  @media only screen and (max-width: 7680px) {
    width: calc(100% - 30px);
    padding: 0 15px;
  }
  &-mobile {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1150px) {
      top: 0;
      padding: 0;
    }

    @media screen and (max-width: 992px) {
      display: none;
      top: 0;
      padding: 30px 0 0 0;
      margin-bottom: 0;
      margin-top: 0;
      //box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
    }

    @media screen and (max-width: 768px) {
      padding: 30px 20px 0;
    }

    @media screen and (max-width: 480px) {
      padding: 30px 10px 0;
    }

    &-bottom {
      display: none;
      justify-content: center;
      margin-bottom: -1rem;

      @media screen and (max-width: 1150px) {
        display: flex;
      }

      .button {
        position: relative;
        bottom: -.5rem;
        background: #f56b2b;
        color: #fff;
        border-radius: .2rem;
        box-shadow: 0 .1rem .2rem rgba(#000, .375);
        font-size: .8rem;
        text-align: center;
        font-weight: 600;
        padding-bottom: .5rem;
      }
    }

    &-top {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        align-items: flex-start;
      }

      @media screen and (max-width: 560px) {
        flex-direction: column;
      }

      &-left {
        display: none;
        flex-direction: row;

        @media screen and (max-width: 1150px) {
          display: flex;
          flex-direction: row;
          min-width: 50%;
        }

        &-small {
          color: #1a1a1a;
          margin: .25rem 0;
          @media screen and (max-width: 992px) {
            margin-bottom: 5px;
            line-height: 20px;
          }
        }

        &-strong {
          display: flex;
          align-items: center;
          color: #1a1a1a;
          margin: .25rem 0;
          svg {
            margin-right: .25rem;
          }
        }
      }
    }
  }

  &-orderby {
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    margin: 30px 0 20px 0;

    @media screen and (max-width: 1150px) {
      width: auto;
      min-width: calc(50% - 20px);
      color: #1877d3;
      //border: 1px solid #1877d3;
      padding: .5rem 0 .5em 20px;
    }

    @media screen and (max-width: 992px) {
      display: none;
    }

    @media screen and (max-width: 560px) {
      margin: .5rem 0;
    }

    &-left {
      width: 50%;
      &-breadcrumbs-wrapper {
        ul.breadcrumbs {
          margin-top: 0;
          li {
            font-size: 16px;
          }
        }
      }
      &-results {
        p {
          font-size: 16px;
        }
      }
    }

    &-right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      &-inner {
        width: auto;
        box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .7);
        border-radius: 8px;
        background: #fff;
        padding: 7px 12px;
        color: #666;
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          margin-right: 5px;
          position: relative;
          top: -2px;
        }
        .MuiInput-underline:before {
          border: none;
        }
        .MuiInput-underline:after,
        .MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: none;
        }
        .MuiSelect-select:focus {
          background-color: transparent;
        }
      }
    }
  }

  &-separator {
    display: flex;

    @media screen and (max-width: 1150px) {
      padding: 0;
    }

    &-content {
      width: calc(100% - 17rem);

      @media screen and (max-width: 1150px) {
        width: calc(100% - 250px);
      }

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      &-createadvertisement {
        background: #ec6501;
        color: #fff;
        border-radius: 50%;
        position: fixed;
        bottom: 1rem;
        right: 1.8rem;
        display: flex;
        width: 3.5rem;
        height: 3.5rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 20;
        box-shadow: rgba(0, 0, 0, .3) 0 4px 12px;
        //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
        transition: .2s background cubic-bezier(0.785, 0.135, 0.15, 0.86);

        &:hover {
          background: darken(#f56b2b, 20%);
        }

        svg {
          font-size: 1.5rem;
        }

        &--absoluted {
          position: absolute;
          bottom: -5rem;
        }
      }
    }

    &-ads {
      margin-top: 3rem;
      max-width: 17rem;
      width: 100%;
      margin-left: 1rem;

      @media screen and (max-width: 1150px) {
        margin-top: 1rem;
        width: 250px;
      }

      @media screen and (max-width: 768px) {
        display: none;
      }

      &-inner {
        position: -webkit-sticky;
        position: sticky;
        top: 105px;
        left: 0;
        &-item {
          width: 100%;
          margin-bottom: 30px;

          &.ads-adverts {
            margin-bottom: 25px;
           .add-item {
             margin-bottom: 35px;
             &:last-child {
               margin-bottom: 0;
             }
           }
          }
          &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: #ffffff;
            box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
            //box-shadow: 0 12px 24px 0 rgba(0,0,0,.13), 0 -1px 6px 1px rgba(24,24,24,.04);
            border-radius: 8px;
            position: relative;
            margin-bottom: 35px;
            font-family: 'Open sans', sans-serif;

            &:last-child {
              margin-bottom: 0;
            }

            &:hover .add-item-wrapper-text-title h3 {
              color: #f56b2b;
            }

            @media screen and (max-width: 768px) {
              width: 100%;
              flex-direction: column;
            }

            &-notice {
              position: absolute;
              background-color: #000000;
              top: -20px;
              left: 0;
              display: block;
              padding: 5px 12px 20px;
              border-radius: 14px 10px 14px 14px;
              z-index: 1;
              p {
                color: #ffffff;
                margin: 0;
                padding: 0;
                font-size: 12px;
              }
            }

            &-image {
              width: 100%;
              min-width: 100%;
              height: 200px;
              position: relative;
              border-radius: 8px 8px 0 0;
              overflow: hidden;
              background-color: #ffffff;
              z-index: 2;
              @media screen and (max-width: 768px) {
                width: 100%;
                height: auto;
                //max-height: 200px;
              }
              &:hover {
                .advertisements-separator-ads-inner-item-wrapper-image-description {
                  visibility: visible;
                  opacity: 1;
                  pointer-events: all;
                }
              }

              a {
                display: block;
                height: 100%;
                text-decoration: none;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }

              &-description {
                width: calc(100% - 60px);
                height: calc(100% - 60px);
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: rgba(0, 0, 0, .8);
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                transition: .3s ease-in-out;
                padding: 30px;
                z-index: 2;
                p {
                  width: calc(100% - 20px);
                  text-align: center;
                  color: #ffffff;
                  font-size: 16px;
                  line-height: 20px;
                }
              }

              &-count {
                position: absolute;
                right: 0;
                top: 0;
                padding: 4px 6px;
                border-radius: 0 8px 0 8px;
                background-color: rgba(0, 0, 0, .5);
                z-index: 3;
                @media screen and (max-width: 768px) {
                  bottom: 3px;
                }
                ul {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  li {
                    font-size: 18px;
                    color: #ffffff;
                    &:first-child {
                      margin-right: 6px;
                    }
                    &:last-child {
                      font-size: 14px;
                    }
                  }
                }
              }

              &-une {
                width: auto;
                background-color: #1a73e9;
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                padding: 7px 10px;
                border-radius: 0 0 8px 0;
                z-index: 3;
                p {
                  font-size: 15px;
                  line-height: 17px;
                  font-family: "open sans", sans-serif;
                  color: #ffffff;
                  margin: 0;
                  padding: 0;
                }
              }
            }

            &-text {
              display: flex;
              flex-direction: column;
              position: relative;
              width: calc(100% - 30px);
              padding: 25px 15px 15px 15px;
              border-radius: 0 0 8px 8px;
              background-color: #ffffff;
              border-top: 1px solid #f2f2f2;
              &-call {
                position: absolute;
                top: -18px;
                left: 10px;
                z-index: 3;
                button {
                  font-family: 'Open sans', sans-serif;
                  border-radius: 20px;
                  padding: 7px 12px;
                  cursor: pointer;
                  font-size: 13px;
                  font-weight: 400;
                  background-color: #E4733E;
                  color: #ffffff;
                  border: solid 1px #f56b2b;
                  outline: none;
                  transition: .3s ease-in-out;
                  box-shadow: 0px 3px 8px 1px rgba(200, 200, 200, .8);
                  &:hover {
                    background-color: #ffffff;
                    color: #f56b2b;
                  }
                }
              }
              &-title {
                h3 {
                  -webkit-transition: .2s ease-in-out;
                  -moz-transition: .2s ease-in-out;
                  transition: .2s ease-in-out;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  @media screen and (max-width: 768px) {
                    font-size: 18px;
                    line-height: 20px;
                  }
                  @media screen and (max-width: 480px) {
                    font-size: 15px;
                    line-height: 18px;
                  }
                  a {
                    font-size: 16px;
                    line-height: 20px;
                    color: #3b3b3b;
                    letter-spacing: 0;
                    font-family: 'Open sans', sans-serif;
                    font-weight: 800;
                    -webkit-transition: .3s ease-in-out;
                    -moz-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                    &:hover {
                      color: #ec6501;
                    }
                  }
                }
              }
              &-price {
                width: 100%;
                padding: 0;
                margin: 7px 0 0 0;
                p {
                  font-family: 'Open sans', sans-serif;
                  font-weight: 700;
                  color: #f56b2b;
                  font-size: 14px;
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  span {
                    margin-right: 4px;
                    &.pricing {
                      margin-right: 0;
                      color: #666666;
                      //font-weight: normal;
                      //text-transform: lowercase;
                    }
                  }
                  &.negociable {
                    margin-top: 3px;
                    font-weight: normal;
                    color: #1a73e9;
                    font-size: 12px;
                  }
                }
                .free-object {
                  font-size: 13px;
                  margin-top: 4px;
                  display: block;
                  color: #5cc171;
                }
              }
              &-location {
                width: 100%;
                padding: 12px 0 0 0;
                display: flex;
                flex-direction: column;
                font-family: 'Open sans', sans-serif;
                @media screen and (max-width: 768px) {
                  padding: 5px 0;
                  flex-direction: column;
                  .MuiSkeleton-root {
                    margin-bottom: 10px;
                  }
                }
                ul {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  li {
                    font-size: 13px;
                    line-height: 18px;
                    color: #888888;
                    font-weight: 500;
                    margin-right: 4px;
                    @media screen and (max-width: 768px) {
                      font-size: 13px;
                    }
                  }
                }
                &-left {
                  width: 100%;
                  margin: 0;
                }
                &-right {
                  width: 100%;
                  margin: 3px 0 0 0;
                }
              }
              &-favorite {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                position: absolute;
                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -17px;
                right: 10px;
                border: solid 1px #666666;
                background-color: #ffffff;
                box-shadow: 0 3px 8px 1px rgba(200, 200, 200, .8);

                @media screen and (max-width: 480px) {
                  bottom: 8px;
                  right: 8px;
                }

                label {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  color: #000;
                  font-size: .8rem;
                  cursor: pointer;
                }

                input[type=checkbox] {
                  display: none;

                  & ~ label {
                    position: relative;

                    .switch {
                      &-on,
                      &-off {
                        font-size: 1.25rem;
                        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                      }

                      &-off {
                        color: #757575;
                      }

                      &-on {
                        position: absolute;
                        opacity: 0;
                        color: #f56b2b;
                      }
                    }
                  }

                  &:checked {
                    & ~ label {
                      color: #f56b2b;

                      .switch {
                        &-inner {
                          transform: translateY(-50%) translateX(100%);
                          box-shadow: 0 0 .2rem rgba(#f56b2b, .75);
                        }

                        &-outer {
                          background: #f56b2b;
                          border-color: #f56b2b;
                        }

                        &-on {
                          opacity: 1;
                        }

                        &-off {
                          color: #f56b2b;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &-pub {
            width: 100%;
            padding: 0;
            box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
            border-radius: 8px;
            overflow: hidden;
            img {
              width: 100%;
              height: auto;
              vertical-align: bottom;
            }
          }
        }
      }
    }
  }

  &-results {
    display: block;
    font-size: .9rem;
    margin-top: .5rem;

    @media screen and (max-width: 1150px) {
      display: none;
    }
  }

  &-topcategories {
    &-title {
      display: block;
      font-family: 'Open sans', sans-serif;
      font-weight: 800;
      font-size: 26px;
      line-height: 28px;
      @media screen and (max-width: 1150px) {
        margin-top: 15px;
      }
      @media screen and (max-width: 992px) {
        margin-top: 20px;
      }
      @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-top: 0;
      }
    }
    width: 100%;
    &-list {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      /* @media screen and (max-width: 992px) {
         display: none;
       }*/
      &-item {
        width: 100%;
        height: 145px;
        position: relative;
        display: flex;
        flex-direction: row;
        padding: 0 8px 0 0;
        outline: none;
        &-inner {
          display: flex;
          flex-direction: column;
          width: calc(100% - 16px);
          height: 90px;
          padding: 12px 8px;
          box-shadow: 11px 4px 15px 1px rgba(200, 200, 200, .8);
          font-weight: 600;
          text-transform: uppercase;
          align-items: center;
          justify-content: center;
          background: #fff;
          font-size: .8rem;
          text-align: center;
          border-radius: 8px;
          cursor: pointer;
          font-family: 'AvenirMedium', sans-serif;
          margin: 0;
          position: relative;
          overflow: hidden;

          img {
            width: auto;
            height: 38px;
            object-fit: contain;
            margin: 0 0 12px 0;
            padding: 0;
          }

          &-name {
            font-size: 11px;
            line-height: 15px;
            display: flex;
            align-items: center;
            transition: .3s ease-in-out;
          }

          &-count {
            position: absolute;
            display: block;
            background: #1a73e9;
            color: #fff;
            text-transform: initial;
            bottom: 0;
            opacity: 0;
            width: calc(100% - 1rem);
            padding: .75rem .5rem;
            transform: translateY(100%);
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          }
          &:hover {
            .advertisements-topcategories-list-item-inner-count {
              opacity: 1;
              transform: translateY(0);
            }
          }
        }

        &:hover {
          .advertisements-topcategories-list-item-inner-name {
            color: #f56b2b;
          }
        }
      }
      .rec.rec-pagination {
        display: none;
      }
      .rec-item-wrapper {
        overflow: visible;
      }
      .rec-carousel {
        position: relative;
      }
      .rec-carousel .rec.rec-arrow-left,
      .rec-carousel .rec.rec-arrow-right {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        line-height: 30px;
        position: absolute;
        z-index: 30;
        opacity: 1;
        font-size: 14px;
        background-color: #ec6501;
        color: #fff;
        border: none;
        box-shadow: none;
        &:disabled {
          background-color: #ccc;
          color: #333;
        }
      }
      .rec-carousel .rec.rec-arrow-left {
        left: 15px;
        right: unset;
      }
      .rec-carousel .rec.rec-arrow-right {
        right: 15px;
        left: unset;
      }
      .rec-slider {
        padding-left: 0;
        min-height: 140px;
      }
      .rec-slider-container {
        width: 100%;
        padding: 15px 0 0 0;
        margin: 0;
      }
    }
    .mobile-top-list {
      width: 100%;
      display: none;
      margin: 0;
      padding: 10px 0 0 0;
      @media screen and (max-width: 992px) {
        display: flex;
      }
      &-container {
        margin: 0;
        padding: 5px 0 0 0;
        overflow: auto;
        &-horizontal-scroll {
          width: 100%;
          display: flex;
          flex-wrap: nowrap;
          overflow: auto;
          align-items: center;
          white-space: nowrap;
          -ms-overflow-style: none; /* IE 11 */
          scrollbar-width: none; /* Firefox 64 */
          &::-webkit-scrollbar {
            display: none;
          }
          ul {
            display: flex;
            flex-direction: row;
            li {
              margin-right: 8px;
              &:last-child {
                margin-right: 5px;
              }
              button {
                max-height: 36px;
                padding: 6px 15px;
                border-radius: 20px;
                background: #ffffff;
                color: #1a1a1a;
                text-transform: none;
                font-size: 14px;
                border: solid 1px #cccccc;
                &.active {
                  background: #f56b2b;
                  color: #ffffff;
                }
              }
              &.allCategories {
                display: flex;
                flex-direction: row;
                align-items: center;
                svg {
                  margin-right: 3px;
                  font-size: 16px;
                }
              }
              &.tri {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #ffffff;
                border: solid 1px #cccccc;
                padding: 5px 10px 5px 15px;
                border-radius: 25px;
                span {
                  display: block;
                  margin-right: 10px;
                }
                .MuiInput-root {
                  display: inline-flex;
                  width: max-content;
                  margin: 0;
                }
                .MuiInput-underline {
                  &:before {
                    border-bottom: none;
                  }
                  &:after {
                    display: none;
                  }
                }
                .MuiNativeSelect-select {
                  outline: none;
                  padding-top: 0;
                  padding-bottom: 0;
                }
                .MuiNativeSelect-select:focus {
                  background-color: transparent !important;
                }
                .MuiInput-underline:hover:not(.Mui-disabled):before {
                  border-bottom: none;
                }
              }
            }
          }
          input[type=radio]:checked ~ label {
            background-color: #f56b2b !important;
            color: #ffffff !important;
            border-color: #f56b2b !important;
            outline: none;
          }
          .custom-radio.radio-button .custom-radio-row label {
            padding: .6rem .7rem !important;
            background-color: #ffffff;
          }
        }
      }
      .adType {
        margin: 7px 0 0 0;
        .MuiCheckbox-colorSecondary.Mui-checked {
          color: #f56b2b;
        }
      }
      .mobile-top-list-container ::-webkit-scrollbar {
        display: none;
      }
      .top-categories  {
        margin: 12px 0 10px;
        @media only screen and (max-width: 768px) {
          margin: 5px 0 25px 0;
        }
      }
    }
  }

  &-categories {
    font-family: 'AvenirMedium', sans-serif;
    margin-top: 80px;
    padding: 6px;
    background: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .8rem;
    text-transform: uppercase;
    //border: solid 1px #dadce0;
    //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);

    @media only screen and (max-width: 1150px) {
      margin-top: 50px;
    }

    @media only screen and (max-width: 992px) {
      display: none;
    }

    &-item {
      padding: .8rem 1rem;
      border-radius: 8px;
      cursor: default;
      color: #666;
      font-weight: 600;
      transition: .3s background-color cubic-bezier(0.785, 0.135, 0.15, 0.86),
      .2s color cubic-bezier(0.785, 0.135, 0.15, 0.86);

      &:hover {
        color: #ffffff;
        background: #f56b2b;
      }

      &-active {
        position: relative;
        cursor: initial;
        color: #ffffff;
        /*border: 1px solid transparent;
        border-bottom: none;*/
        background: #f56b2b;
        //padding: 1.1rem .75rem 1rem;

        /*&:hover {
          background: #fff;
          color: #f56b2b;
        }*/

        /*&::after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 3px;
          background: #f56b2b;
          width: 100%;
        }*/
      }
    }

   /* @media screen and (max-width: 1150px) {
      display: none;
    }*/
  }
}
