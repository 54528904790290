.breadcrumbs {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 1150px) {
    display: none;
  }

  &-item {
    font-size: .8rem;

    a {
      color: #1877d3;
    }

    &:not(:last-child)::after {
      content: '/';
      margin: 0 .75rem;
      color: #989898;
    }

    &--current {
      color: #989898;
    }
  }
}