.confirmation-page {
  width: 100%;
  max-width: 65rem;
  height: calc(100vh - 134px);
  padding: 30px 0;
  margin: 74px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  @media only screen and (max-width: 992px) {
    width: 100vw;
    margin-top: 70px;
    height: 100vh;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    padding: 0 30px;
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px;
  }
  &-image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      padding: 0;
      max-width: 100%;
    }
  }
  &-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    h2 {
      font-family: 'Open sans', sans-serif;
      font-size: 24px;
      line-height: 26px;
      font-weight: bold;
      margin: 30px 0 20px 0;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 15px;
      }
      @media only screen and (max-width: 468px) {
        text-align: center;
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-weight: bold;
      }
    }
  }
  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 25px 0 0 0;
    @media only screen and (max-width: 468px) {
      flex-direction: column;
    }
    a {
      width: auto;
      background-color: #1a73e9;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      padding: 10px 30px;
      border: solid 1px #1a73e9;
      display: block;
      margin-right: 20px;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      @media only screen and (max-width: 468px) {
        width: calc(100% - 60px);
        padding: 15px 30px;
      }
      &:first-child {
        background-color: transparent;
        color: #1a73e9;
        @media only screen and (max-width: 468px) {
          margin-bottom: 20px;
        }
        &:hover {
          background-color: #1a73e9;
          color: #fff;
        }
      }
      &:last-child {
        margin-right: 0;
        &:hover {
          background-color: transparent;
          color: #1a73e9;
        }
      }
    }
  }
}
