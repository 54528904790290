.ask-container {
  width: 100%;
  max-width: 67.5rem;
  margin: 70px auto 0 auto;
  height: calc(100% - 70px);
  padding: 70px 0;
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    padding: 40px 30px 60px 30px;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 30px 20px 50px 20px;
  }
  &-form {
    width: 100%;
    margin: 30px 0 0 0;
    &-title {
      color: #ec6501;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
      margin: 0;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
    &-fields {
      width: 100%;
      margin: 30px 0 0 0;
      display: flex;
      flex-direction: column;
      &-item {
        width: 100%;
        max-width: 520px;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
        &.file {
          .file-title {
            font-size: 18px;
            color: #1a73e9;
          }
          button {
            margin: 12px 0 0 0;
            border: solid 1px #d8d8d8;
          }
        }
        &.submit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-top: 20px;
          button {
            width: 100%;
            margin: 0 auto;
            min-height: 42px;
            font-size: 16px;
            background-color: #1a73e9;
            color: #fff;
            border: solid 1px #1a73e9;
            &:hover {
              background-color: #fff;
              color: #1a73e9;
            }
          }
        }
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }
  }
}
