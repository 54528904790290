.skeleton-advert-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
        width: calc(50% - 10px);
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .skeleton-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            &-image {
                width: 100%;
                display: flex;
                margin-bottom: 10px;
                .MuiSkeleton-root {
                    border-radius: 4px;
                }
            }
            &-text {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 0px;
                &-title {
                    width: 100%;
                    display: flex;
                    margin-bottom: 8px;
                }
                &-location {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 8px;
                }
            }
            &-price {
                width: 100%;
                display: flex;
            }
        }
    }
}