.filter-results {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 70px 0 0 0;
  min-height: calc(100vh - 70px);
  background-color: #ffffff;
  &-content {
    width: 65rem;
    padding: 60px 50px;
    margin: 70px auto 0 auto;
    min-height: 500px;
    h1 {
      font-size: 26px;
      span {
        font-weight: bold;
      }
    }
    &-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      margin: 50px 0 0 0;
      .add-item {
        width: calc(25% - 12px);
        break-inside: avoid-column;
        &:nth-child(4n+1) {
          padding: 0 12px 0 0;
        }
        &:nth-child(4n+2) {
          padding: 0 8px 0 4px;
        }
        &:nth-child(4n+3) {
          padding: 0 4px 0 8px;
        }
        &:nth-child(4n+4) {
          padding: 0 0 0 12px;
        }
        @media screen and (max-width: 992px) {
          width: calc(33% - 12px);
          &:nth-child(3n+1) {
            padding: 0 10px 0 0;
          }
          &:nth-child(3n+2) {
            padding: 0 5px;
          }
          &:nth-child(3n+3) {
            padding: 0 0 0 10px;
          }
        }
        @media screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 14px;
          padding: 0 !important;
          .add-item-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .add-item-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
    }
  }
}