.MuiSnackbar-root.MuiSnackbar-anchorOriginTopRight {
  top: 10px;
  right: 10px;
  .MuiSnackbarContent-root {
    position: relative;
    min-width: auto;
    background-color: rgba(129, 173, 90, .9);
    padding: 6px 25px 6px 16px;
    .MuiSnackbarContent-action {
      position: absolute;
      top: 0;
      right: 7px;
      button {
        svg {
          font-size: 16px;
        }
      }
    }
  }
  &.red {
    .MuiSnackbarContent-root {
      background-color: rgba(255, 0, 0, .9);
    }
  }
  &.green {
    .MuiSnackbarContent-root {
      background-color: rgba(129, 173, 90, .9);
    }
  }
}
