.diffusion-rules-container {
  width: 100%;
  display: flex;
  max-width: 65rem;
  flex-direction: column;
  margin: 70px auto 0 auto;
  padding: 70px 30px;
  height: auto;
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    max-width: 100%;
    margin: 50px auto 0 auto;
  }
  @media only screen and (max-width: 992px) {
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
    margin: 70px auto 0 auto;
    padding: 30px 20px 40px 20px;
  }
  &-inner {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    margin-top: 30px;
    padding: 30px;
    @media only screen and (max-width: 768px) {
      box-shadow: none;
      padding: 0;
    }
    h1 {
      font-size: 28px;
      line-height: 32px;
      color: #ec6501;
      margin: 0 0 20px 0;
      font-weight: bolder;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
    h2 {
      font-size: 22px;
      line-height: 26px;
      color: #000;
      margin: 30px 0 20px 0;
      font-weight: bolder;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
        line-height: 24px;
        margin: 20px 0 10px 0;
      }
    }
    h3 {
      font-size: 18px;
      line-height: 24px;
      color: #000;
      margin: 15px 0;
      font-weight: bolder;
      @media only screen and (max-width: 768px) {
        margin: 10px 0;
      }
    }
    h4 {
      font-size: 16px;
      line-height: 22px;
      color: #000;
      margin: 20px 0 5px 0;
      font-weight: bolder;
      @media only screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
    p {
      font-size: 16px;
      line-height: 22px;
      a, .fake-link {
        color: #1a73e9;
        text-decoration: none;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        transition: all .3s ease;
        cursor: pointer;
        &:hover {
          color: #000000;
          text-decoration: underline;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .table-wrapper {
      width: 100%;
      display: block;
      overflow-x: auto;
      margin: 30px 0;
      table {
        width: 100%;
        min-width: 768px;
        border: solid 1px #ddd;
        thead {
          tr {
            th {
              border: solid 1px #ddd;
              border-top: none;
              padding: 7px 10px;
              text-align: center;
            }
          }
        }
        tbody {
          tr {
            td {
              border: solid 1px #ddd;
              border-bottom: none;
              padding: 7px 10px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
