.not-found {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-height: calc(100vh - 70px);
  background-color: #f3f3f4;
  @media only screen and (max-width: 992px) {
    min-height: calc(100vh - 58px);
  }
  @media only screen and (max-width: 818px) {
    background-color: #e5e8eb;
  }

  &-container {
    width: 700px;
    height: 420px;
    border-radius: 8px;
    background-color: #e5e8eb;
    padding: 50px;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 992px) {
      padding: 30px;
    }
    @media only screen and (max-width: 818px) {
      width: calc(100% - 60px);
      padding: 30px;
      height: auto;
      border-radius: 0;
    }
    &-inner {
      width: 100%;
      height: calc(100% - 80px);
      display: flex;
      flex-direction: row;
      background-color: #ffffff;
      padding: 40px 10px 40px 40px;
      border-radius: 8px;
      @media only screen and (max-width: 818px) {
        flex-direction: column-reverse;
        padding: 40px;
      }
      &-left {
        width: 40%;
        height: 100%;
        display: flex;
        padding-right: 40px;
        flex-direction: column;
        justify-content: center;
        @media only screen and (max-width: 818px) {
          width: 100%;
          align-items: center;
        }
        h2 {
          font-size: 26px;
          font-weight: 700;
          margin-bottom: 15px;
          @media only screen and (max-width: 818px) {
            text-align: center;
          }
        }
        p {
          max-width: 200px;
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 20px;
          @media only screen and (max-width: 818px) {
            max-width: 100%;
            text-align: center;
          }
        }
        a {
          display: block;
          background-color: #f56b2b;
          border: solid 1px #f56b2b;
          color: #ffffff;
          border-radius: 4px;
          width: auto;
          font-size: 16px;
          line-height: 18px;
          padding: 10px 20px;
          margin: 0 auto 0 0;
          transition: .3s ease-in-out;
          @media only screen and (max-width: 818px) {
            margin: 0 auto;
          }
          &:hover {
            color: #f56b2b;
            background-color: #ffffff;
          }
        }
      }
      &-right {
        width: 60%;
        padding-left: 40px;
        @media only screen and (max-width: 818px) {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-left: 0;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          @media only screen and (max-width: 818px) {
            width: 260px;
            height: auto;
          }
          @media only screen and (max-width: 768px) {
            width: 200px;
          }
          @media only screen and (max-width: 480px) {
            width: 170px;
          }
          @media only screen and (max-width: 320px) {
            width: 150px;
          }
        }
      }
    }
  }
}