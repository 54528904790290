.shop {
  position: relative;
  background: #f2f2f2;
  //border-radius: 8px;
  padding: 0;
  //box-shadow: 0 1px 2px 0 rgba(60, 64, 67,  .3), 0 1px 3px 1px rgba(60, 64, 67, .15);
  width: 65rem;
  max-width: 100%;
  margin: 0 auto;

  &-filters {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    transition: .2s ease-in-out;
    padding: 30px 40px 10px 40px;

    @media screen and (max-width: 1150px) {
      padding: 25px 20px 0 20px;
      margin: 25px 0 30px 0;
    }

    @media screen and (max-width: 768px) {
      border-radius: 0;
      padding: 30px 20px 0 20px;
      margin: 0 -15px 40px -15px;
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
      &-row {
        width: 33.33%;
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
        }
        &.left {
          padding-right: 20px;
          @media only screen and (max-width: 992px) {
            padding-right: 10px;
          }
          @media only screen and (max-width: 768px) {
            padding-right: 0;
          }
          .MuiInputBase-root {
            min-height: 40px !important;
            .MuiOutlinedInput-input {
              padding: 10px !important;
            }
          }
        }
        &.center {
          padding: 0 10px;
          position: relative;
          display: flex;
          flex-direction: column-reverse;
          @media only screen and (max-width: 768px) {
            padding: 0;
          }
          label {
            display: flex;
            align-items: center;
            position: absolute;
            background: #fff;
            padding: 0 .25rem;
            top: -.45rem;
            left: 25px;
            font-size: .8rem;
            width: max-content;
            color: #6f7072;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

            svg {
              font-size: 1rem;
              margin-right: .25rem;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            }
          }

          input {
            padding: 11px 10px;
            border: 1px solid #cccccc;
            border-radius: .2rem;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

            &:focus {
              border-color: #1a73e8;
              outline: none;
              ~ label {
                color: #1a73e8;
              }
            }
          }
        }
        &.right {
          padding-left: 20px;
          @media only screen and (max-width: 992px) {
            padding-left: 10px;
          }
          @media only screen and (max-width: 768px) {
            padding-left: 0;
          }
          button {
            width: 100%;
            display: flex;
            min-height: 40px;
            justify-content: flex-start;
            align-items: center;
            line-height: 22px;
            text-transform: none;
            font-size: 14px;
            color: #6f7072;
            border: 1px solid #cccccc !important;
            font-weight: normal;
            padding: 8px 30px 8px 10px;
            position: relative;
            svg {
              position: absolute;
              top: 50%;
              right: 10px;
              transform: translateY(-50%);
            }
          }
          @media screen and (max-width: 480px) {
            margin-bottom: 0;
          }
          .MuiFormControl-root.select-town {
            width: 100%;
            font-size: .9rem !important;

            &.Mui-focused {
              background-color: #ffffff !important;
              color: #1877d3 !important;
              .MuiOutlinedInput-notchedOutline {
                border-color: #1877d3 !important;
              }
              .MuiInputLabel-outlined {
                transform: translate(14px, 14px) scale(1);
              }
            }
          }
        }
      }
    }

    &-submit {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      top: 0;
      .search-filters-submit {
        position: relative;
        top: 10px;
        transform: translateY(50%);
        background: #1877d3 !important;
        color: #fff !important;
        width: max-content;
        align-self: center;
        box-shadow: 0 .1rem .2rem rgba(#000, .25) !important;
        padding: .375rem 3rem .25rem !important;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        border: solid 1px transparent !important;
        overflow: hidden;
        @media screen and (max-width: 768px) {
          z-index: 1;
          top: 0;
        }
        &:hover {
          color: #1a73e9 !important;
          background: #ffffff !important;
          border-color: #1a73e9 !important;
        }
        &:disabled {
          background-color: #ccc;
          color: #000;
          border-color: #ccc;
          cursor: not-allowed;
          .MuiButton-label {
            .MuiCircularProgress-root {
              color: #000;
            }
          }
          &:hover {
            background-color: #ccc;
            color: #000000;
          }
        }
        .MuiButton-label {
          .MuiCircularProgress-root {
            width: 20px !important;
            height: 20px !important;
            margin-left: 15px;
            color: #ffffff;
          }
        }
      }
      .search-filters-reset {
        position: relative;
        margin-left: 10px;
        top: 10px;
        transform: translateY(50%);
        background: #f56b2b !important;
        color: #fff !important;
        width: max-content;
        align-self: center;
        box-shadow: 0 .1rem .2rem rgba(#000, .25) !important;
        padding: .375rem 3rem .25rem !important;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        border: solid 1px transparent !important;
        overflow: hidden;
        @media screen and (max-width: 768px) {
          z-index: 1;
          top: 0;
        }
        &:hover {
          color: #1a73e9 !important;
          background: #ffffff !important;
          border-color: #1a73e9 !important;
        }
        &:disabled {
          background-color: #ccc;
          color: #000;
          border-color: #ccc;
          cursor: not-allowed;
          .MuiButton-label {
            .MuiCircularProgress-root {
              color: #000;
            }
          }
          &:hover {
            background-color: #ccc;
            color: #000000;
          }
        }
        .MuiButton-label {
          .MuiCircularProgress-root {
            width: 20px !important;
            height: 20px !important;
            margin-left: 15px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.search-wrapper {
  .footer {
    &-categories {
      display: flex;
      padding-top: 1rem;
    }
  }
}
