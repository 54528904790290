.security-container {
  width: 100%;
  max-width: 67.5rem;
  margin: 70px auto 0 auto;
  height: calc(100% - 70px);
  padding: 70px 0;
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    padding: 40px 30px 60px 30px;
  }
  @media only screen and (max-width: 1040px) {
    width: calc(100% - 40px);
    padding: 30px 20px 50px 20px;
  }
  &-breadcrumb {
    width: 100%;
    ul {
      display: flex;
      flex-direction: row;
      align-items: center;
      li {
        font-size: 14px;
        &.separator {
          margin: 0 10px;
        }
        a {
          color: #1a73e9;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  &-header {
    width: 100%;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 50px;
    }
    h1 {
      color: #ec6501;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
      margin: 0 0 0 12px;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }
  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
    ul {
      margin: 5px 0 20px 0;
      padding: 0 0 0 25px;
      display: flex;
      flex-direction: column;
      li {
        list-style: circle;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 7px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
     p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
       strong {
         font-weight: 500;
       }
      a {
        color: #1a73e9;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
