.createadvertisement {
  margin: 8rem 0;
  @media only screen and (max-width: 768px) {
    margin: 70px 0 50px 0;
  }
  &-custom-radio {
    width: calc(100% / 3);
    padding-top: 12px;
    @media only screen and (max-width: 768px) {
      width: auto;
      //margin-right: 15px;
      .custom-radio-desc {
        display: none;
      }
    }
    @media only screen and (max-width: 480px) {
      flex-direction: column;
    }
  }

  &-form {
    max-width: 65rem;
    width: 95%;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
      width: calc(100% - 40px);
      padding: 30px 20px 0 20px;
    }
    @media only screen and (max-width: 480px) {
      width: calc(100% - 30px);
      padding: 30px 15px 0 15px;
    }
    &-inner {
      /*width: 100%;*/
      max-width: 780px;
      @media only screen and (max-width: 840px) {
        padding: 0 30px;
      }
      @media only screen and (max-width: 768px) {
        padding: 0;
      }
      @media only screen and (max-width: 480px) {
        padding: 0;
      }
    }

    .name-input-submit-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px 0 0 0;
      position: relative;
      @media only screen and (max-width: 768px) {
        margin-top: 10px;
        flex-direction: column;
        .MuiFormControlLabel-root {
          width: 100%;
          margin-bottom: 20px;
        }
        .errorMessage {
          bottom: 45px;
          line-height: 14px;
        }
        Button {
          width: 100%;
          margin-top: 20px;
        }
      }
      .MuiFormControlLabel-root {
        .MuiTypography-root {
          label {
            font-size: 14px;
            a {
              color: #1a73e9;
              text-decoration: underline;
            }
          }
        }
      }
      Button {
        background-color: #1a73e9;
        color: #ffffff;
        text-transform: none;
        font-size: 15px;
        &:disabled {
          background-color: #1a73e9;
        }
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
          color: #ffffff !important;
          margin-left: 10px;
        }
      }
    }

    &-title {
      font-size: 1.5rem;
      margin-bottom: 4rem;
      margin-left: 1.5rem;
      @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
        margin-left: 0;
      }
      @media only screen and (max-width: 480px) {
        margin-bottom: 20px;
      }
    }

    &-firstpart {
      .advert-infos {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .add-title,
        .add-description,
        .add-pricing,
        .add-price {
          position: relative;
          display: flex;
          flex-direction: row;
          .price-wrapper {
            width: calc(100% - 124px);
            position: relative;
            margin-right: 15px;
            .currency {
              font-size: 13px;
              position: absolute;
              top: 50%;
              color: #333;
              right: 10px;
              transform: translateY(-50%);
            }
          }
          .MuiInputBase-input {
            padding-right: 50px !important;
          }
          .MuiFormControlLabel-root {
            width: 124px;
            margin-right: 0;
          }
          /*.MuiFormControl-root {
            padding-right: 40px;
          }*/
          /*.MuiSvgIcon-root {
            color: #dadce0;
          }*/
          &.is-invalid {
            .MuiFormLabel-root {
              color: #ff4444;
            }
            .MuiInputBase-root {
              svg.MuiSelect-icon {
                color: #ff4444 !important;
              }
            }
          }
          .errorMessage {
            bottom: -24px;
          }
        }
        .add-title {
          width: 100%;
          margin-bottom: 30px;
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
          .MuiFormControl-root {
            width: 100%;
            margin-right: 0;
            .MuiInputBase-input {
              padding-right: 55px !important;
              @media only screen and (max-width: 480px) {
                padding-right: 50px !important;
              }
            }
          }
          .title-counter {
            position: absolute;
            right: 20px;
            font-size: 14px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            margin-top: 0;
            color: #666976;
            @media only screen and (max-width: 480px) {
              right: 10px;
            }
          }
        }
        .add-description {
          width: 100%;
          margin-bottom: 30px;
          .MuiFormControl-root {
            width: 100%;
            margin-right: 0;
            .MuiInputBase-root.MuiInputBase-multiline {
              padding: 0;
              textarea {
                padding: 20px 15px !important;
              }
            }
          }
        }
        .add-price {
          width: calc(50% - 15px);
          margin-bottom: 30px;
          @media only screen and (max-width: 768px) {
            width: 100%;
            .MuiFormControlLabel-root {
              margin-right: 0;
            }
          }
          .MuiFormControl-root {
            width: 100%;
          }
        }
        .add-pricing {
          width: calc(50% - 15px);
          margin-bottom: 30px;
          .MuiFormControl-root.pricing {
            width: 100%;
            margin-right: 0;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .add-town {
          width: calc(50% - 15px);
          position: relative;
          height: 40px;
          .errorMessage {
            bottom: -24px;
          }
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
          .MuiFormControl-root {
            width: 100%;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
          }
          .MuiFormControl-root.MuiTextField-root {
            height: 0;
            .MuiInputBase-root {
              height: 0;
              &:before {
                height: 0;
              }
              input {
                padding: 0;
                height: 0;
              }
            }
          }
        }
      }
    }

    &-block {
      display: flex;
      justify-content: space-between;
      border: 1px solid #cccccc;
      padding: 2rem 1.5rem;
      border-radius: 8px;
      margin-bottom: 2rem;
      @media only screen and (max-width: 768px) {
        padding: 15px 0;
        border: none;
        margin-bottom: 0;
      }

      &-title {
        margin-bottom: 1rem;
        //margin-left: .5rem;
      }

      &.add-first-part {
        flex-direction: column;
        @media (max-width: 480px) {
          padding: 0;
          border: none;
        }
        .category {
          @media only screen and (max-width: 768px) {
            margin-bottom: 30px;
          }
          .MuiFormControl-root {
            .MuiInputBase-input {
              height: 0;
              padding: 0;
            }
          }
        }
      }
    }

    &-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      &.category {
        position: relative;
        .errorMessage {
          bottom: -24px;
        }
        .MuiFormControl-root {
          margin: 0;
        }
      }

      &.add-type {
        width: 100%;
        position: relative;
        .errorMessage {
          bottom: -25px;
        }
        &-title {
          @media only screen and (max-width: 480px) {
            margin-bottom: 5px;
          }
        }
        .addTypeWrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          @media only screen and (max-width: 768px) {
            margin-bottom: 30px;
          }
          .MuiFormGroup-root {
            width: 100%;
            display: flex;
            flex-direction: row;
            &.is-invalid {
              .radio-wrapper {
                .MuiButtonBase-root {
                  .PrivateRadioButtonIcon-root-5 {
                    svg {
                      color: #ff4444 !important;
                    }
                  }
                }
                .MuiTypography-root {
                  color: #ff4444;
                }
              }
            }
            .radio-wrapper {
              width: calc(25% - 15px);
              margin-right: 15px;
              @media only screen and (max-width: 768px) {
                width: calc(50% - 15px);
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
              @media only screen and (max-width: 480px) {
                width: 100%;
                margin-right: 0;
                margin-bottom: 15px;
              }
              .MuiFormControlLabel-root {
                margin-left: -7px;
                .MuiButtonBase-root {
                  padding: 5px;
                  &.Mui-checked {
                    color: #f56b2b;
                    .MuiIconButton-label {
                      .MuiSvgIcon-root {
                        color: #f56b2b;
                      }
                    }
                  }
                  .MuiIconButton-label {
                    .MuiSvgIcon-root {
                      color: #cccccc;
                    }
                  }
                }
                .MuiTypography-root {
                  font-family: 'AvenirMedium', sans-serif;
                  font-weight: 400;
                }
              }
              p {
                margin-left: 1.2rem;color: #748bb1;
                font-size: .8rem;
                padding: 0 .5rem;
              }
            }
          }
        }
        .add-type-radio {
          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
          .custom-radio {
            margin-right: 15px;
          }
        }
      }

      &:nth-child(1) {
        width: 50%;
        margin-bottom: 30px;
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 25px;
        }
      }

      &:nth-child(2) {
        width: 100%;
        margin-left: 0 !important;
      }

      & ~ & {
        margin-left: 2rem;
      }

      &.color {
        button {
          width: 100%;
          color: rgba(0, 0, 0, 0.54);
          padding: 9px 32px 9px 12px;
          font-size: 1rem;
          background-color: transparent;
          box-shadow: none;
          border: solid 1px #ccc;
          position: relative;
          svg {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 6px;
            width: 24px;
            height: 24px;
            font-size: 1.5rem;
          }
          .MuiButton-label {
            display: block;
            text-align: left;
            line-height: 20px;
          }
        }
      }

      .fuel-container {
        width: 100% !important;
      }

      &-row {
        display: flex;
        justify-content: space-between;

        &.fuel-choice {
          flex-wrap: wrap;
          .addFuelWrapper {
            .MuiFormGroup-root {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              &.is-invalid {
                .MuiTypography-root{
                  color: #ff4444;
                  border-color: #ff4444 !important;
                }
              }
              .MuiFormControlLabel-root {
                margin-left: -5px;
                .MuiButtonBase-root {
                  display: none;
                }
                .MuiTypography-root {
                  border: 1px solid #cccccc;
                  border-radius: 99999999rem;
                  margin: .5rem .25rem;
                  transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  padding: 7px 12px !important;
                  font-size: 14px;
                  font-family: 'AvenirThin', sans-serif;
                }
                .MuiButtonBase-root.Mui-checked ~ .MuiTypography-root {
                  color: #f56b2b;
                  border-color: #f56b2b;
                }
              }
            }
          }
        }
      }

      &-title {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        margin-bottom: 1.25rem;
        margin-left: 1rem;
        @media only screen and (max-width: 768px) {
          margin: 0 0 30px 0;
        }
        &-bubble {
          background: #1877d3;
          color: #fff;
          width: 1.25rem;
          height: 1.25rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin-right: .5rem;
          font-size: .85rem;
          font-weight: 600;
        }
      }
    }

    &-secondpart {
      margin: 30px 0 60px 0;
      @media only screen and (max-width: 768px) {
        margin-top: 40px;
        margin-bottom: 0;
      }
      &-container {
        display: flex;
        justify-content: center;
        border: 1px solid #cccccc;
        padding: 40px 30px 10px 30px;
        border-radius: 8px;
        position: relative;
        @media only screen and  (max-width: 768px) {
          padding: 0;
          border: none;
        }
        .errorMessage {
          bottom: 5px;
          left: 50%;
          font-size: 15px;
          -webkit-transform: translateX(-50%);
          -moz-transform: translateX(-50%);
          transform: translateX(-50%);
        }
        &-inner {
          width: 642px;
          margin: 0 auto;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: flex-start;
          @media only screen and (max-width: 768px) {
            width: 300px;
            justify-content: space-between;
          }
          .image-picker-item {
            width: 160px;
            height: 160px;
            border-radius: .5rem;
            border: dashed 2px #f56b2b;
            display: flex;
            margin-bottom: 30px;
            flex-direction: column;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            @media only screen and (max-width: 768px) {
              width: 120px;
              height: 120px;
            }
            /*@media only screen and (max-width: 480px) {
              width: 110px;
              height: 110px;
            }*/
            &:nth-child(3n+1) {
              margin-right: 50px;
              @media only screen and (max-width: 768px) {
                margin-right: 0;
              }
            }
            &:nth-child(3n+2) {
              margin-left: 25px;
              margin-right: 25px;
              @media only screen and (max-width: 768px) {
                margin-left: 0;
                margin-right: 0;
              }
            }
            &:nth-child(3n+3) {
              margin-left: 50px;
              @media only screen and (max-width: 768px) {
                margin-left: 0;
              }
            }
            &:nth-child(1) {
              border: solid 2px #f56b2b;
              .image-picker-item-inner {
                svg {
                  color: #f56b2b;
                }
                span {
                  color: #f56b2b;
                }
              }
            }
            &.rest-images {
              width: 160px;
              height: 160px;
              @media only screen and (max-width: 768px) {
                width: 120px;
                height: 120px;
              }
              /*@media only screen and (max-width: 480px) {
                width: 110px;
                height: 110px;
              }*/
              .image-picker-item-inner {
                height: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                svg {
                  color: #b9b9b9;
                  font-size: 3rem;
                  margin-bottom: 10px;
                  @media only screen and (max-width: 768px) {
                    font-size: 2rem;
                  }
                }
                span {
                  text-align: center;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 17px;
                  @media only screen and (max-width: 768px) {
                    font-size: 14px;
                  }
                }
              }
            }
            &-inner {
              height: 100%;
              display: flex;
              flex-direction: column;
              padding: 0;
              @media only screen and (max-width: 768px) {
                padding: 15px;
              }
              input {
                display: none;
              }
              input:disabled ~ label {
                cursor: default;
              }
              label {
                width: calc(100% - 40px);
                height: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                svg {
                  color: #b9b9b9;
                  font-size: 3rem;
                  margin-bottom: 10px;
                  @media only screen and (max-width: 768px) {
                    font-size: 2rem;
                  }
                }
                span {
                  text-align: center;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 17px;
                  @media only screen and (max-width: 768px) {
                    font-size: 14px;
                  }
                }
              }
            }
            &-image {
              width: 100%;
              height: 100%;
              background-color: #000000;
              position: absolute;
              top: 0;
              left: 0;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                vertical-align: unset;
              }
              button {
                position: absolute;
                top: 0;
                right: 0;
                width: 30px;
                height: 30px;
                border-radius: 0 0 0 15px;
                cursor: pointer;
                background-color: #f56b2b;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                //border: solid 1px #f56b2b;
                svg {
                  color: #ffffff;
                  font-size: 22px;
                  position: relative;
                  top: -2px;
                  left: 2px;
                }
              }
              .hover-pricipale-indication {
                display: block;
                width: 100%;
                position: absolute;
                left: 0;
                bottom: 0;
                p {
                  color: #ffffff;
                  font-size: 13px;
                  line-height: 15px;
                  margin: 0;
                  background-color: rgba(0, 0, 0, .4);
                  left: 0;
                  bottom: 0;
                  padding: 7px 10px;
                  display: flex;
                  justify-content: center;
                  @media only screen and (max-width: 480px) {
                    padding: 5px 7px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-thirdpart {
      width: 100%;
      margin: 40px 0;
      @media only screen and (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 0;
      }
      &-container {
        border: 1px solid #cccccc;
        padding: 30px;
        border-radius: 8px;
        @media (max-width: 768px) {
          border: none;
          padding: 0;
          margin-top: 20px;
        }
        &-inner {
          width: 100%;
          /*max-width: 630px;*/
          max-width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          /*.MuiInputLabel-outlined.MuiInputLabel-marginDense {
            transform: translate(14px, 10px) scale(1);
          }*/
          .name-input-item {
            width: calc(50% - 15px);
            margin-bottom: 20px;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            .MuiFormControl-root {
              width: 100%;
            }
          }
          .password-input-item {
            width: calc(50% - 15px);
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            .MuiFormControl-root {
              width: 100%;
              .MuiInputBase-input {
                padding-right: 45px !important;
              }
            }
            .show-password {
              pointer-events: none;
              cursor: default;
              svg {
                color: #cccccc;
              }
              &:after {
                background-color: #cccccc;
              }
            }
          }
          .phone-input-item {
            width: calc(50% - 15px);
            margin-bottom: 20px;
            @media only screen and (max-width: 768px) {
              width: 100%;
              margin-bottom: 10px;
            }
            .react-tel-input {
              margin: 20px 0 5px 0;
              input {
                &:disabled {
                  opacity: .5;
                  &:hover {
                    border-color: #cccccc;
                  }
                }
              }
              .special-label {
                display: none;
              }
              .form-control {
                padding: .55rem 1rem;
                margin-left: 3rem;
                width: calc(100% - 3rem);

                & ~ .flag-dropdown {
                  &::before {
                    left: 3.5rem;
                    color: #6b6f74;
                    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                    content: attr(data-placeholder)!important;
                    position: absolute;
                    top: -7px;
                    display: block;
                    background: #fff;
                    padding: 0 5px;
                    font-size: 13px;
                    white-space: nowrap;
                  }

                  .selected-flag {
                    padding: 0;
                  }
                }

                &:focus {
                  & ~ .flag-dropdown {
                    &::before {
                      color: #0073f1;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .published {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 20px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
      button {
        height: 24px;
        padding: 0;
        background-color: transparent;
        color: #1a73e9;
      }
    }
  }

  .specs {
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 0;
    border: none;
    padding: 0;
    @media (max-width: 480px) {
      padding: 0;
      border: none;
    }
    .createadvertisement-form-block-auto,
    .createadvertisement-form-block-estate,
    .createadvertisement-form-block-state,
    .createadvertisement-form-block-multimedia,
    .createadvertisement-form-block-color {
      width: calc(100% - 60px);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border: 1px solid #cccccc;
      border-radius: 8px;
      padding: 30px;
      margin-bottom: 30px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
        padding: 0;
        border: none;
      }
    }

    .createadvertisement-form-block-multimedia {
      padding-bottom: 15px;
    }

    .createadvertisement-form-block-state,
    .createadvertisement-form-block-color {
      .createadvertisement-form-section {
        margin-bottom: 0;
        &-wrapper {
          width: 100%;
        }
      }
    }

    .createadvertisement-form-block-estate {
      .createadvertisement-form-section,
      .createadvertisement-form-color {
        margin-bottom: 0;
      }
    }

    .createadvertisement-form-block-auto,
    .createadvertisement-form-block-estate,
    .createadvertisement-form-block-multimedia,
    .createadvertisement-form-block-color {
      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
      .createadvertisement-form-section {
        &-wrapper {
          @media only screen and (max-width: 768px) {
            width: 100%;
            &:nth-child(2n) {
              margin-bottom: 25px !important;
            }
            &:nth-child(2n+1) {
              margin-bottom: 25px !important;
            }
          }
        }
      }
    }

    .createadvertisement-form-section {
      margin: .5rem 0 20px 0;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      position: relative;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0;
      }

      .createadvertisement-form-block-title {
        margin: 10px 0 5px 0;
      }

      &-wrapper {
        width: calc(50% - 15px);
        position: relative;
        &:nth-child(2n) {
          margin: 0 !important;
        }
        &:nth-child(2n+1) {
          margin: 0 !important;
        }
        .MuiFormControl-root {
          width: 100%;
          &.is-invalid {
            svg.MuiSelect-icon {
              color: #ff4444 !important;
            }
          }
        }
        .errorMessage {
          bottom: -22px;
        }
      }

      &.column {
        flex-direction: column;

        .createadvertisement-form-section-row {
          justify-content: flex-start;

          .createadvertisement-custom-radio {
            width: max-content;
          }
        }
      }

      &.gearbox {
        margin-bottom: 0;
        .addTypeWrapper {
          .MuiFormGroup-root {
            width: auto;
            display: flex;
            flex-direction: row;
            &.is-invalid {
              .radio-wrapper {
                .MuiButtonBase-root {
                  .PrivateRadioButtonIcon-root-5 {
                    svg {
                      color: #ff4444 !important;
                    }
                  }
                }
                .MuiTypography-root {
                  color: #ff4444;
                }
              }
            }
            .MuiFormControlLabel-root {
              .MuiButtonBase-root {
                &.Mui-checked {
                  color: #f56b2b;
                  .MuiIconButton-label {
                    .MuiSvgIcon-root {
                      color: #f56b2b;
                    }
                  }
                }
                .MuiIconButton-label {
                  .MuiSvgIcon-root {
                    color: #cccccc;
                  }
                }
              }
              .MuiTypography-root {
                font-family: 'AvenirMedium', sans-serif;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }

  .select {
    &-category,
    &-brand,
    &-model,
    &-year,
    &-km,
    &-door,
    &-seat {
      width: 22rem;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
  }
}

.MuiInputBase-root {
  font-size: .9rem !important;
}

.MuiInputBase-root.Mui-focused {
  background-color: #ffffff !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #1877d3 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1877d3 !important;
}

.MuiFormControl-root .MuiInputLabel-outlined {
  transform: translate(14px, 14px) scale(1);
}

/*.MuiOutlinedInput-input {
  padding: 14px 32px 10px 10px !important;
}*/

.custom-radio.radio-button .custom-radio-row label {
  padding: .6rem .7rem !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #f56b2b !important;
}

.MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: #cccccc !important;
}

.custom-arrow-button {
  border-color: #cccccc !important;
}

.color-popper {
  .MuiPaper-root.MuiPopover-paper {
    padding: 0;
    margin-top: -40px;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    .color-list {
      width: 343px;
      padding-bottom: 0;
      .MuiListItem-container {
        .MuiButtonBase-root {
          display: flex;
          flex-direction: row;
          align-items: center;
          span {
            width: auto;
            height: auto;
            margin-right: unset;
          }
          .MuiListItemIcon-root {
            min-width: 30px;
            span {
              display: block;
              width: 16px;
              height: 16px;
            }
          }
          .MuiListItemText-root {

          }
        }
        .MuiListItemSecondaryAction-root {
          .MuiIconButton-label {
            height: 24px;
          }
        }
      }
      &-footer {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 10px 0 0 0;
        border-top: solid 1px #dbdce0;
        button {
          width: 50%;
          margin: 0;
          display: flex;
          justify-content: center;
          min-height: 44px;
          border-radius: 0;
          padding: 10px;
          font-size: 16px;
          outline: none;
          &:first-child {
            border-right: solid 1px #dbdce0;
            background-color: #f0f0f0;
            color: #000;
          }
          &:last-child {
            color: #fff;
            background-color: #1a73e9;
          }
          span {
            width: auto;
            height: auto;
          }
        }
      }
    }
  }
}

.dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      .error-message {
        width: 300px;
        .MuiDialogTitle-root {
          h2 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            svg {
              margin-right: 10px;
              color: #f44336;
            }
          }
        }
        .MuiDialogContent-root {
          p {
            line-height: 22px;
            text-align: center;
          }
        }
      }
    }
  }
}

.is-invalid {
  .MuiFormControl-root.MuiTextField-root {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #ff4444 !important;
    }
  }
}


