.login {
  width: 100%;
  height: calc(100vh - 74px);
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  @media only screen and (max-width: 992px) {
    width: calc(100% - 30px);
    padding: 0 15px;
    height: calc(100vh - 70px);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 40px 3rem;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    @media only screen and (max-width: 992px) {
      width: calc(100vh - 40px);
      padding: 30px 20px;
      max-width: 30rem;
    }

    @media only screen and (max-width: 768px) {
      border: none;
      width: calc(100% - 50px);
      max-width: 30rem;
      padding: 30px 25px;
    }

    @media only screen and (max-width: 480px) {
      width: calc(100% - 30px);
      padding: 30px 15px;
    }

    .full-form-wrapper {
      .tel-input {
        .is-invalid {
          input {
            border-color: #ff4444;
            color: #ff4444;
            & ~ .flag-dropdown {
              &::before {
                color: #ff4444;
              }
            }
          }
        }
        .errorMessage {
          bottom: -24px;
        }
      }
      .errorMessage {
        bottom: -7px;
      }
    }

    .react-tel-input {
      margin: 20px 0;

      .special-label {
        display: none;
      }
      .form-control {
        padding: 1.2rem 1rem;
        margin-left: 3rem;
        width: calc(100% - 3rem);

        & ~ .flag-dropdown {
          &::before {
            left: 3.5rem;
            color: #6b6f74;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            content: attr(data-placeholder)!important;
            position: absolute;
            top: -7px;
            display: block;
            background: #fff;
            padding: 0 5px;
            font-size: 13px;
            white-space: nowrap;
          }

          .selected-flag {
            padding: 0;
          }
        }

        &:focus {
          & ~ .flag-dropdown {
            &::before {
              color: #0073f1;
            }
          }
        }
      }
      &.is-invalid {
        .form-control {
          &:focus {
            box-shadow: 0 0 0 1px #f4f4f4;
            & ~ .flag-dropdown {
              &::before {
                color: #ff4444;
              }
            }
          }
        }
      }
    }

    .forgot-password {
      margin: 15px 0;
      button {
        margin-left: -10px;
      }
    }

    &-field {
      &.MuiFormControl-root {
        .MuiInputLabel-outlined {
          transform: translate(14px, 20px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
          }
        }
      }
      .MuiInputBase-input {
        height: 36px;
      }
    }

    &-title {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
      font-family: 'Roboto', AvenirMedium, sans-serif;
    }

    &-subtitle {
      text-align: center;
      font-size: 1.125rem;
      margin-bottom: 1rem;
      font-family: 'Roboto', AvenirMedium, sans-serif;
    }

    a {
      color: #0073f1;
      margin: 1rem 0 1rem .5rem;

      &:hover {
        text-decoration: underline;
      }
    }

    .MuiButton-label {
      color: #0073f1;
      text-transform: initial;
      font-family: 'AvenirMedium', sans-serif;
      font-size: 1rem;
    }

    .MuiFormLabel-root.Mui-focused {
      color: #0073f1!important;
    }

    .MuiTextField-root {
      width: 100%;
      margin: 1rem 0;

      .MuiFormLabel-root.Mui-focused {
        color: #0073f1!important;
      }

      .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #0073f1!important;
        }
      }

      &.is-invalid {
        .MuiFormLabel-root.Mui-focused {
          color: #ff4444 !important;
        }

        .MuiOutlinedInput-root.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ff4444!important;
          }
        }
      }
    }

    &-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .half-form-wrapper:nth-child(2) {
        display: flex;
        justify-content: flex-end;
      }
    }

    .login-menu {
      margin-left: -10px;
    }

    &-button {
      background: #0073f1!important;
      &:disabled {
        background-color: #1a73e9;
      }
      .MuiCircularProgress-root {
        width: 20px !important;
        height: 20px !important;
        color: #ffffff !important;
        margin-left: 10px;
      }

      span {
        color: #fff!important;
      }
    }
  }
}
