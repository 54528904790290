.number-confirmation {
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 74px);
  overflow: auto;
  @media only screen and (max-width: 992px) {
    height: calc(100vh - 70px);
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22.5rem;
    //min-height: 323px;
    padding: 45px 10px;
    margin: 0;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    @media only screen and (max-width: 768px) {
      border: none;
      padding: 40px 20px;
    }
    h1 {
      font-size: 18px;
      text-align: center;
      margin-bottom: 15px;
    }
    p {
      text-align: center;
      font-size: 14px;
      line-height: 18px;
      margin: 12px 0 0 0;
      padding: 0 15px;
    }
    form {
      width: calc(100% - 16px);
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 8px 0 0 0;
      padding: 0 8px;
      .react-tel-input {
        margin: 20px 0 20px;

        .special-label {
          display: none;
        }
        .form-control {
          padding: .7rem 1rem;
          margin-left: 3rem;
          width: calc(100% - 3rem);

          & ~ .flag-dropdown {
            &::before {
              left: 3.5rem;
              color: #6b6f74;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              content: attr(data-placeholder)!important;
              position: absolute;
              top: -7px;
              display: block;
              background: #fff;
              padding: 0 5px;
              font-size: 13px;
              white-space: nowrap;
            }

            .selected-flag {
              padding: 0;
            }
          }

          &:focus {
            & ~ .flag-dropdown {
              &::before {
                color: #0073f1;
              }
            }
          }
        }
        &.is-invalid {
          .form-control {
            &:focus {
              box-shadow: 0 0 0 1px #f4f4f4;
              & ~ .flag-dropdown {
                &::before {
                  color: #ff4444;
                }
              }
            }
          }
        }
      }
      p {
        font-size: 13px;
        color: #b7b7b7;
        line-height: 18px;
        margin-bottom: 8px;
        text-align: center;
        padding: 0 15px;
        &.send-message {
          color: #000000;
          font-size: 14px;
          margin-bottom: 8px;
        }
        &.send-fees {
          margin-bottom: 22px;
        }
        span {
          display: unset;
        }
      }
      .verification-button {
        background-color: #1a73e9;
        color: #ffffff;
        border-radius: 25px;
        padding: 10px 22px;
        border: solid 1px #1a73e9;
        transition: .3s ease-in-out;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        display: flex;
        justify-self: center;
        &:hover {
          background-color: #ffffff;
          color: #1a73e9;
        }
      }
    }
  }
}