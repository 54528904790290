.profile-page {
  margin: 70px 0 0;
  padding: 0 0 50px 0;
  background-color: #ffffff;

  @media screen and (max-width: 1150px) {
    margin: 58px 0 0;
  }

  &-back {
    background-color: #ffffff;
    height: 300px;
    @media screen and (max-width: 992px) {
      height: 200px;
    }
    @media screen and (max-width: 768px) {
      height: 170px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-header {
    width: 768px;
    margin: -100px auto 50px;
    display: flex;

    @media screen and (max-width: 1150px) {
      margin-bottom: 40px;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 768px) {
      padding: 20px 0 0 0;
      margin-top: -40px;
      margin-bottom: 20px;
      width: 100%;
    }

    &-inner {
      width: 100%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      /*border: solid 1px #dadce0;*/
      border-radius: 8px;
      box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
      //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
      padding: 20px 0 0 0;
      @media screen and (max-width: 768px) {
        padding: 15px 0 0 0;
        border-radius: 0;
      }

      &-image {
        width: 92px;
        height: 92px;
        padding: 7px;
        background-color: #ffffff;
        margin: -60px 0 20px 0;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        -webkit-box-shadow: 0 10px 20px 2px rgba(100,100,100,0.3);
        -moz-box-shadow: 0 10px 20px 2px rgba(100,100,100,0.3);
        box-shadow: 0 10px 20px 2px rgba(100,100,100,0.3);
        @media screen and (max-width: 768px) {
          width: 75px;
          height: 75px;
        }
        svg {
          color: #1a73e9;
          font-size: 5.75rem;
          position: relative;
          z-index: 1;
          @media screen and (max-width: 768px) {
            font-size: 4.68rem;
          }
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: center;
          position: relative;
          z-index: 2;
        }
      }
      &-infos {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        h4 {
          font-size: 24px;
          margin: 0 0 20px 0;
          padding: 0 20px;
          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
        &-inner {
          width: calc(100% - 40px);
          display: flex;
          flex-direction: row;
          padding: 20px;
          border-top: solid 1px #dbdce0;
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
          @media screen and (max-width: 480px) {
            flex-direction: column;
            align-items: center;
          }
          ul {
            width: 40%;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (max-width: 768px) {
              width: 100%;
              margin-bottom: 20px;
              align-items: center;
            }
            @media screen and (max-width: 480px) {
              margin-bottom: 15px;
              align-items: center;
            }
            li {
              font-size: 16px;
              color: #1a1a1a;
              margin: 0 0 10px 0;
              &:first-child {
                span {
                  text-transform: uppercase;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          &-contact {
            width: 60%;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 768px) {
              width: 100%;
              justify-content: center;
            }
            @media screen and (max-width: 480px) {
              justify-content: center;
            }
            button {
              display: flex;
              align-items: center;
              background-color: #1a73e9;
              color: #ffffff;
              border-radius: 25px;
              text-transform: none;
              padding: 8px 25px;
              font-size: 15px;
              border: solid 1px #1a73e9;
              margin-right: 15px;
              &:first-child {
                background-color: #05cd51;
                border-color: #05cd51;
                &:hover {
                  color: #05cd51;
                }
              }
              &:last-child {
                margin-right: 0;
              }
              svg {
                font-size: 1.5rem;
                margin-right: 5px;
              }
              &:hover {
                background-color: transparent;
                color: #1a73e9;
              }
            }
          }
        }
      }
    }
  }

  &-items {
    width: 65rem;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1080px){
      width: calc(100% - 40px);
      padding: 20px;
    }
    @media screen and (max-width: 840px){
      width: 100%;
      padding: 0;
    }
    @media screen and (max-width: 480px){
      width: 100%;
      padding: 10px 0;
    }
    &-header {
      margin: 0 0 15px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 780px) {
        padding: 0 15px;
      }
      @media screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
      }
      &-left {
        @media screen and (max-width: 480px) {
          margin-bottom: 15px;
        }
        p {
          font-size: 16px;
        }
      }
      &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #ffffff;
        padding: 2px 15px;
        border: solid 1px #dadce0;
        border-radius: 8px;
        @media screen and (max-width: 480px) {
          width: calc(100% - 30px);
          justify-content: space-between;
        }
        &-tri {
          margin: 0 10px 0 0;
        }
        .MuiFormControl-root {
          .MuiInput-underline {
            &:before {
              border-bottom: none;
            }
            &:after {
              display: none;
            }
          }
          .MuiSelect-select {
            &:focus {
              background-color: transparent !important;
            }
          }
          .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: none;
          }
        }
      }
    }
    &-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media screen and (max-width: 768px) {
        padding: 10px;
        display: block;
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-gap: 10px;
        -moz-column-gap: 10px;
        column-gap: 10px;
        -moz-column-fill: auto;
        column-fill: auto;
        > * {
          display: inline-block;
          width: 100%;
          -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
          page-break-inside: avoid; /* Firefox */
          break-inside: avoid; /* IE 10+ */
        }
      }
      @media screen and (max-width: 480px) {
        padding: 10px;
      }

      .add-item {
        width: calc(25% - 12px);
        &:nth-child(4n+1) {
          padding: 0 12px 0 0;
        }
        &:nth-child(4n+2) {
          padding: 0 6px;
        }
        &:nth-child(4n+3) {
          padding: 0 6px;
        }
        &:nth-child(4n+4) {
          padding: 0 0 0 12px;
        }
        @media screen and (max-width: 992px) {
          width: calc(33.33% - 12px);
          &:nth-child(3n+1) {
            padding: 0 12px 0 0;
          }
          &:nth-child(3n+2) {
            padding: 0 6px;
          }
          &:nth-child(3n+3) {
            padding: 0 0 0 12px;
          }
        }
        @media screen and (max-width: 768px) {
          width: auto;
          padding: 0 !important;
          .add-item-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .add-item-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
    }
  }
}
