@import "~react-image-gallery/styles/scss/image-gallery.scss";

.advert-details-page {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .details-page-scroll-header {
    position: fixed;
    top: 70px;
    left: 0;
    width: calc(100% - 20px);
    padding: 10px;
    background-color: #ffffff;
    display: flex;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 1302;
    flex-direction: row;
    box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.25);
    -webkit-transition: opacity, visibility, .3s ease-in-out;
    -moz-transition: opacity, visibility, .3s ease-in-out;
    transition: opacity, visibility, .3s ease-in-out;
    @media screen and (max-width: 992px) {
      top: 58px;
    }
    &.show {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    }
    &-left {
      width: 50%;
      display: flex;
      flex-direction: row;
      z-index: 10;
      @media only screen and (max-width: 1400px) {
        width: 40%;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0 20px 0 0;
      }
      &-image {
        width: 100px;
        height: 70px;
        @media screen and (max-width: 1400px) {
          width: 80px;
          height: 60px;
        }
        @media screen and (max-width: 768px) {
          width: 90px;
          height: 60px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &-text {
        width: calc(100% - 100px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
        @media screen and (max-width: 1150px) {
          padding: 0 0 0 15px;
        }
        @media screen and (max-width: 768px) {
          flex-direction: column;
          padding: 0 0 0 15px;
          align-items: flex-start;
          justify-content: flex-start;
        }
        &-title {
          padding: 0 15px 0 0;
          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0;
            margin-bottom: 10px;
          }
          h3 {
            font-size: 1.3rem;
            //font-weight: 600;
            line-height: 1.4rem;
            margin: 0;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            @media only screen and (max-width: 1400px) {
              font-size: 1.2rem;
              line-height: 1.3rem;
            }
            @media screen and (max-width: 1200px) {
              font-size: 1.1rem;
              line-height: 1.2rem;
            }
            @media screen and (max-width: 768px) {
              font-size: 1rem;
              line-height: 1.1rem;
            }
          }
        }
        &-price {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          padding: 0 0 0 15px;
          @media screen and (max-width: 768px) {
            align-items: flex-start;
            padding-left: 0;
          }
          @media screen and (max-width: 480px) {
            width: 100%;
            padding: 0;
          }
          p {
            color: #f56b2b;
            font-size: 1rem;
            margin: 0;
            @media screen and (max-width: 1150px) {
              font-size: .9rem;
            }
          }
          span {
            display: block;
            margin-top: 4px;
            font-size: 14px;
          }
        }
      }
    }
    &-right {
      width: 50%;
      padding: 0 0 0 30px;
      display: flex;
      @media only screen and (max-width: 1400px) {
        width: 60%;
        padding-left: 20px;
      }
      @media screen and (max-width: 992px) {
        display: none;
      }
      &-inner {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        a {
          display: inline-flex;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          font-size: 0.875rem;
          min-width: 64px;
          line-height: 1.75;
          border-radius: 4px;
          letter-spacing: 0.02857em;
          height: 42px;
        }
        &-button {
          max-width: 300px;
          width: calc(33% - 30px);
          margin-right: 30px;
          height: 42px;
          text-transform: none !important;
          -webkit-transition: .2s ease-in-out;
          -moz-transition: .2s ease-in-out;
          transition: .2s ease-in-out;
          @media only screen and (max-width: 1400px) {
            width: calc(33% - 20px);
            margin-right: 20px;
            font-size: 14px;
          }
          @media only screen and (max-width: 1200px) {
            width: calc(33% - 15px);
            margin-right: 15px;
            font-size: 14px;
          }
          &:last-child {
            margin-right: 0;
          }
          svg {
            font-size: 1.75rem;
            margin-right: .5rem;
            @media only screen and (max-width: 1400px) {
              font-size: 1.4rem;
            }
            @media screen and (max-width: 1200px) {
              font-size: 1.1rem;
              margin-right: 6px;
            }
          }
          &.hidden-phone {
            cursor: not-allowed;
            &:hover {
              color: #fff;
              background-color: #ec6501;
            }
          }
          &.orange {
            background: #f56b2b;
            color: #ffffff;
            border: solid 1px #f56b2b;
            &:hover {
              color: #f56b2b;
              background-color: #ffffff;
            }
          }
          &.outline {
            background-color: #ffffff;
            color: #1877d3;
            border: solid 1px #1877d3;
            &:hover {
              color: #ffffff;
              background-color: #1877d3;
            }
          }
          &.green {
            cursor: pointer;
            background: #05cd51;
            color: #ffffff;
            border: solid 1px #05cd51;
            font-size: 14px;
            border-radius: 4px;
            line-height: 1.75;
            &:hover {
              color: #05cd51;
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }
  .advertisement {
    width: 100%;
    padding: 0;
    margin: 70px 0;
    background: #ffffff;
    display: flex;

    @media screen and (max-width: 1150px) {
      width: calc(100% - 40px);
      padding: 20px;
    }

    @media screen and (max-width: 992px) {
      width: 100%;
      margin: 70px 0 60px 0;
      padding: 0;
    }

    .container {
      width: 100%;
      max-width: 65rem;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1150px) {
        width: 100%;
        max-width: 100%;
        margin: 0;
      }
    }

    &-flexbox {
      display: flex;
      justify-content: space-between;
      margin-top: 1.5rem;

      @media screen and (max-width: 1150px) {
        flex-direction: row;
        margin-top: 0;
      }

      @media screen and (max-width: 991px) {
        flex-direction: column;
      }
    }

    &-primary {
      width: 45rem;
      margin-right: .75rem;
      max-width: 45rem;

      @media screen and (max-width: 1150px) {
        width: calc(100% - 345px);
        max-width: 100%;
        margin: 0;
        padding: 0 20px 0 0
      }

      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
      }

      &-carousel {
        width: 100%;
        padding: 0;
        margin-bottom: 20px;
        box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
        border-radius: 8px;
        overflow: hidden;
        //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
        background-color: #ffffff;
        @media only screen and (max-width: 992px) {
          border-radius: 0;
        }
        .default-image {
          width: 100%;
          vertical-align: middle;
          max-height: 545px;
          object-fit: cover;
          object-position: center center;
          @media only screen and (max-width: 992px) {
            border-radius: 0;
            max-height: 400px;
          }
          @media only screen and (max-width: 768px) {
            max-height: 320px;
          }
          @media only screen and (max-width: 480px) {
            max-height: 280px;
          }
        }
        &-inner {
          width: 100%;
        }
      }

      &-details {
        background: #fff;
        display: flex;
        flex-direction: column;
        box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
        border-radius: 8px;
        @media only screen and (max-width: 992px) {
          border-radius: 0;
        }
        @media only screen and (max-width: 768px) {
          box-shadow: none;
          margin-top: 0;
        }
        &-title {
          font-family: 'AvenirMedium', sans-serif;
          margin: 1.5rem .75rem 1.2rem .75rem;
          font-size: 20px;
          line-height: 28px;
          @media only screen and (max-width: 992px) {
            font-size: 1.2rem;
            line-height: 28px;
            margin: 30px 20px 20px 20px;
          }
          @media only screen and (max-width: 768px) {
            margin: 30px 15px 20px 15px;
          }
          @media only screen and (max-width: 480px) {
            font-size: 1.1rem;
            line-height: 26px;
            margin-top: 5px;
            margin-bottom: 15px;
          }
        }

        .mobile-price {
          display: none;
          @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            margin: 0 15px 15px 15px;
            padding-bottom: 20px;
            border-bottom: solid 1px #dadce0;
            li {
              color: #000000;
              margin-bottom: 12px;
              &:first-child {
                color: #f56b2b;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &-table {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          border-top: 1px solid #e5e5e5;
          @media screen and (max-width: 768px) {
            width: calc(100% - 10px);
            border-top: none;
            margin-bottom: 15px;
            padding: 0 5px;
          }
          &-item {
            width: calc(50% - 26px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 12px 8px;
            font-size: .9rem;
            border-bottom: 1px solid #e5e5e5;
            @media screen and (max-width: 992px) {
              width: calc(50% - 32px);
              align-items: flex-start;
              &:nth-child(2n+1) {
                padding-left: 20px;
                padding-right: 10px;
              }
              &:nth-child(2n) {
                padding-left: 10px;
                padding-right: 20px;
              }
            }
            @media only screen and(max-width: 768px) {
              flex-direction: column;
              border-bottom: none;
              font-size: 16px;
            }
            @media only screen and(max-width: 480px) {
              width: calc(50% - 22px);
              &:nth-child(2n+1) {
                padding-left: 10px;
                padding-right: 10px;
              }
              &:nth-child(2n) {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
            &.colors {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              div {
                margin-top: 6px;
                ul {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  padding: 0 0 0 18px;
                  li {
                    list-style: circle;
                    line-height: 18px;
                    position: relative;
                    margin-right: 15px;
                    color: #748292;
                    &:after {
                      content: "|";
                      display: block;
                      position: absolute;
                      height: 80%;
                      right: -9px;
                      top: -1.5px;
                      margin: 0;
                    }
                    &:last-child {
                      &:after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            &.price {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: flex-start;
              @media screen and (max-width: 768px) {
                display: none;
              }
              .price-wrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                span {
                  margin-bottom: 2px;
                }
                span {
                  display: block;
                  &.negociable {
                    font-size: 12px;
                    font-weight: normal;
                    margin-bottom: 0;
                    color: #1a73e9 !important;
                  }
                }
              }
            }

            &:nth-child(odd) {
              border-right: 1px solid #e5e5e5;
              @media screen and (max-width: 768px) {
                border-right: none;
              }
            }

            small {
              padding-right: 10px;
              @media only screen and (max-width: 768px) {
                margin-bottom: 5px;
                text-transform: uppercase;
                font-size: 14px;
              }
              @media only screen and(max-width: 480px) {
                padding-right: 0;
                line-height: 18px;
              }
            }

            em {
              padding-left: 10px;
              color: #748292;
              @media only screen and(max-width: 480px) {
                padding-left: 0;
                line-height: 18px;
              }
            }

            &-left {
              &.orange {
                color: #f56b2b;
              }
            }
          }
        }

        &-links {
          border-top: 1px solid #d8d8d8;
          margin-top: .5rem;
          padding: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: .9rem;
          @media only screen and (max-width: 768px) {
            width: calc(100% - 30px);
            margin: 0 auto;
            padding: 1rem 0;
          }
          &-button {
            width: auto;
            &:first-child {
              display: flex;
              justify-content: flex-start;
            }
            &:nth-child(2) {
              display: flex;
              justify-content: center;
            }
            label {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              color: #000;
              font-size: .8rem;
              cursor: pointer;
              position: relative;
              border-radius: 4px;
              &:hover {
                background-color: rgba(0, 0, 0, 0.04);
              }
              button {
                pointer-events: none;
                position: relative;
                padding-left: 30px;
                svg {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  margin: 0 5px;
                  transform: translateY(-50%);
                }
              }
            }

            input[type=checkbox] {
              display: none;

              & ~ label {
                position: relative;

                .switch {
                  &-on,
                  &-off {
                    font-size: 1.25rem;
                    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  }

                  &-off {
                    color: #757575;
                  }

                  &-on {
                    position: absolute;
                    opacity: 0;
                    color: #f56b2b;
                  }
                }
              }

              &:checked {
                & ~ label {
                  color: #f56b2b;

                  .switch {
                    &-inner {
                      transform: translateY(-50%) translateX(100%);
                      box-shadow: 0 0 .2rem rgba(#f56b2b, .75);
                    }

                    &-outer {
                      background: #f56b2b;
                      border-color: #f56b2b;
                    }

                    &-on {
                      opacity: 1;
                    }

                    &-off {
                      color: #f56b2b;
                    }
                  }
                }
              }
            }
            button {
              svg {
                font-size: 1rem;
                margin-right: 5px;
              }
            }
          }
          .share-wrapper {
            width: auto;
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            button {
              svg {
                font-size: 1rem;
                margin-right: 5px;
              }
            }
            .share-buttons-content {
              width: auto;
              display: inline-flex;
              position: absolute;
              top: -55px;
              left: 50%;
              visibility: hidden;
              opacity: 0;
              transition: .3s ease-in-out;
              -webkit-transform: translateX(-50%) translate(0, 15px);
              -moz-transform: translateX(-50%) translate(0, 15px);
              transform: translateX(-50%) translate(0, 15px);
              flex-direction: row;
              @media only screen and (max-width: 1040px) {
                left: calc(50% - 30px);
              }
              &.show {
                visibility: visible;
                opacity: 1;
                transform: translateX(-50%) translate(0, 0);
              }
              ul {
                display: flex;
                flex-direction: row;
                padding: 5px 7px 3px;
                /*border: solid 1px #dadce0;*/
                border-radius: 22px;
                background-color: #ececec;
                &:after {
                  content: "";
                  display: block;
                  width: 0;
                  height: 0;
                  border-right: 12px solid transparent;
                  border-left: 12px solid transparent;
                  border-top: 12px solid #ececec;
                  position: absolute;
                  bottom: -11px;
                  left: 50%;
                  -webkit-transform: translateX(-50%);
                  -moz-transform: translateX(-50%);
                  transform: translateX(-50%);
                }
                li {
                  margin-right: 7px;
                  &:last-child {
                    margin-right: 0;
                  }
                  button {
                    outline: none;
                    svg {
                      margin-right: 0;
                    }
                  }
                  .copy-link {
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                    min-height: 32px;
                    border-radius: 50%;
                    background-color: #ccc;
                    color: #000;
                  }
                }
              }
            }
          }
        }

        &-description {
          padding: 1.5rem .75rem;
          @media only screen and (max-width: 768px) {
            margin: 0 auto;
            max-width: calc(100% - 30px);
            border-top: solid 1px #dadce0;
            padding: 1.5rem 0;
          }
          &-title {
            font-size: 18px;
            line-height: 22px;
            margin: 0 0 18px 0;
            &:after {
              content: "";
              display: block;
              width: 30px;
              height: 2px;
              position: relative;
              bottom: -8px;
              left: 0;
              background-color: #666;
            }
          }
          .description-text {
            margin-top: 20px;
            display: block;
            .read-more-text {
              line-height: 24px;
              font-size: 16px;
            }
          }
        }
      }

      &-pro-adverts {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 60px 0 0 0;
        @media only screen and (max-width: 992px) {
          width: calc(100% - 40px);
          padding: 0 20px;
        }
        @media only screen and (max-width: 768px) {
          width: calc(100% - 30px);
          padding: 0 15px;
          margin-top: 20px;
        }
        &-title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
          h2 {
            font-size: 20px;
            margin: 0;
            @media only screen and (max-width: 480px) {
              margin-bottom: 20px;
            }
          }
          a {
            color: #1a73e9;
            text-decoration: none;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            align-items: center;
            &:hover {
              text-decoration: underline;
            }
            svg {
              font-size: 14px;
              margin-left: 4px;
            }
          }
        }
        &-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin: 25px 0 0 0;
          @media only screen and (max-width: 768px) {
            flex-direction: row;
            flex-wrap: wrap;
          }
          .add-item {
            width: calc(33% - 10px);
            &:nth-child(3n+1) {
              padding-right: 10px;
            }
            &:nth-child(3n+2) {
              padding: 0 10px;
            }
            &:nth-child(3n+3) {
              padding-left: 10px;
            }
            @media only screen and (max-width: 768px) {
              width: calc(50% - 7px);
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
              &:nth-child(2n+1) {
                padding: 0 7px 0 0;
              }
              &:nth-child(2n) {
                padding: 0 0 0 7px;
              }
            }
            /* @media only screen and (max-width: 480px) {
               width: 100%;
               padding: 0 !important;
             }*/
          }
        }
      }

      &-others {
        width: 100%;
        padding: 40px 0 0 0;
        @media only screen and (max-width: 992px) {
          width: calc(100% - 40px);
          padding: 40px 20px 0 20px;
        }
        @media only screen and (max-width: 768px) {
          width: calc(100% - 30px);
          padding: 40px 15px 0 15px;
        }
        @media only screen and (max-width: 480px) {
          width: calc(100% - 20px);
          padding: 40px 10px 0 10px;
        }
        h2 {
          font-size: 20px;
          margin: 0 0 5px 0;
          @media only screen and (max-width: 768px) {
            margin: 0 10px;
            line-height: 22px;
          }
        }
        .react-multi-carousel-list {
          padding: 20px 0;
          margin: 0 -10px;
          ul.react-multi-carousel-track {
            li.react-multi-carousel-item {
              .add-item {
                width: calc(100% - 20px);
                height: 351px;
                padding: 0 10px;
                @media only screen and (max-width: 768px) {
                  height: auto;
                }
                &-inner {
                  box-shadow: 0 4px 10px 1px rgba(200, 200, 200, .8);
                  &-image {
                    min-height: 186px;
                    @media only screen and (max-width: 768px) {
                      min-height: 0;
                      height: 180px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &-secondary {
      width: 20rem;
      max-width: 20rem;
      margin: 0 0 auto 1.25rem;
      box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
      border-radius: 8px;
      overflow: hidden;
      /*position: sticky;
      top: 170px;*/
      //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);

      @media screen and (max-width: 1150px) {
        width: 345px;
        max-width: unset;
        margin: 0 0 auto 0;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        border-radius: 0;
      }

      &-seller {
        background: #fff;
        padding: 0;
        border-radius: .1rem;
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 .1rem rgba(#000, .1);
        .entreprise {
          width: 100%;
          padding: 0 0 5px 0;
          .back-space {
            width: 100%;
            height: 70px;
            background-color: #4c617c;
            display: block;
          }
          &-name {
            padding: 15px;
            display: flex;
            margin: -35px 0 0 0;
            flex-direction: row;
            align-items: flex-end;
            &-left {
              width: 80px;
              height: 60px;
              display: block;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            &-right {
              width: calc(100% - 70px);
              padding: 0 10px;
              h4 {
                line-height: 18px;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }

          &-activity-title {
            padding: 10px 15px 25px 15px;
          }

          &-store-view {
            padding: 15px;
            display: flex;
            flex-direction: column;
            a {
              padding: 12px 20px;
              display: block;
              border: solid 1px #1877d3;
              color: #1877d3;
              border-radius: 4px;
              text-align: center;
              -webkit-transition: .3s ease-in-out;
              -moz-transition: .3s ease-in-out;
              transition: .3s ease-in-out;
              @media only screen and (max-width: 992px) {
                margin: 0 auto;
                width: auto;
                min-width: 255px;
              }
              @media only screen and (max-width: 768px) {
                width: calc(100% - 42px);
              }
              @media only screen and (max-width: 480px) {
                min-width: 255px;
              }
              &:hover {
                background-color: #1a73e9;
                color: #ffffff;
              }
            }
          }
        }
        .particular {
          width: 100%;
          a {
            width: 100%;
            padding: 0;
            text-decoration: none;
            color: #000000;
            display: block;
            .advertisement-secondary-seller-header {
              width: calc(100% - 2rem);
              display: flex;
              flex-direction: row;
              align-items: center;
              &-left {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                p {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
              &-right {
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                svg {
                  font-size: 20px;
                  color: #6f6f6f;
                  margin-right: 0;
                }
              }
            }
          }
        }
        &-header {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
          padding: 1rem;
          svg {
            font-size: 2.5rem;
            color: #1877d3;
            margin-right: 0.5rem;
            margin-top: 0;
            margin-left: 0;
          }
          a {
            color: #1a1a1a;
            &:hover {
              text-decoration: underline !important;
            }
          }
        }

        &-buttons {
          border-top: 1px solid #d8d8d8;
          padding: 15px;
          display: flex;
          @media screen and (max-width: 992px) {
            flex-direction: row;
            justify-content: space-between;
          }
          @media screen and (max-width: 768px) {
            flex-direction: column;
          }
          &-inner {
            width: 100%;
            display: flex;
            flex-direction: column;
            a {
              display: inline-flex;
              font-family: "Roboto", "Helvetica", "Arial", sans-serif;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              font-size: 0.875rem;
              min-width: 64px;
              line-height: 1.75;
              border-radius: 4px;
              letter-spacing: 0.02857em;
              height: 42px;
            }
            &-button {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #fff;
              margin: 0 0 10px 0 !important;
              border-radius: .3rem;
              min-height: 2.5rem;
              font-size: .9rem;
              border: 1px solid transparent;
              cursor: pointer;
              outline: none;
              text-transform: none;
              -webkit-transition: .2s ease-in-out;
              -moz-transition: .2s ease-in-out;
              transition: .2s ease-in-out;

              @media screen and (max-width: 992px) {
                width: 31%;
                height: 42px;
                margin: 0 !important;
              }

              @media screen and (max-width: 768px) {
                width: 100%;
                height: 42px;
                margin: 0 0 10px 0 !important;
              }

              &:last-child {
                margin-bottom: 0 !important;
              }

              svg {
                font-size: 1.75rem;
                margin-right: .5rem;
              }

              &.hidden-phone {
                cursor: not-allowed;
              }

              &.orange {
                background-color: #f56b2b;
                border: solid 1px #f56b2b;
                color: #ffffff;
                &:hover {
                  background-color: #ffffff;
                  color: #f56b2b;
                }
              }

              &.outline {
                color: #1877d3;
                border: solid 1px #1877d3;
                background-color: #ffffff;
                &:hover {
                  background-color: #1877d3;
                  color: #ffffff;
                }
              }

              &.green {
                background-color: #05cd51;
                border: solid 1px #05cd51;
                color: #ffffff;
                &:hover {
                  background-color: #ffffff;
                  color: #05cd51;
                }
              }
            }
          }
        }

        &-links {
          border-top: 1px solid #d8d8d8;
          margin-top: .5rem;
          padding: 1rem 1rem .25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: .9rem;

          a {
            display: flex;
            align-items: center;
            color: #1a1a1a;

            svg {
              margin-right: .25rem;
              margin-top: -.1rem;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
  footer {
    .container-wrapper {
      display: none;
    }
    .footer-banner {
      padding-top: 30px;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 58px;
    }
  }
}

.react-multi-carousel-list .react-multiple-carousel__arrow:hover {
  background: rgba(236, 101, 1, .8);
}

.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slide img {
  object-fit: contain;
}

.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slide > div {
  height: 100%;
}

.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-slide > div img {
  height: 100%;
}

.image-gallery.fullscreen-modal {
  z-index: 40;
}

.dialog .MuiPaper-rounded .change-container {
  max-height: unset;
  .dialog-container {
    &-item {
      position: relative;
      margin-bottom: 25px;
      .errorMessage {
        font-size: 13px !important;
        bottom: -20px !important;
      }
    }
  }
  .confirmation-button {
    button {
      &:disabled {
        background-color: #1a73e9;
      }
      .MuiCircularProgress-root {
        width: 20px !important;
        height: 20px !important;
        color: #ffffff !important;
        margin-left: 10px;
      }
    }
  }
}

.react-multiple-carousel__arrow:hover {
  box-shadow: 0 3px 8px 1px rgba(100, 100, 100, .5);
  background-color: rgba(236, 101, 1, 1) !important;
}

.image-gallery .image-gallery-content .image-gallery-slide-wrapper .image-gallery-fullscreen-button {
  outline: none;
}

.read-more-text {
  .read-or-hide {
    color: #1a73e9;
    cursor: pointer;
    .read-less {
      margin-left: 5px;
    }
    svg {
      font-size: 15px;
      margin-left: 3px;
      position: relative;
      top: 3px;
      color: #1a73e9;
    }
  }
}

