.help-content {
  margin: 70px auto 0 auto;
  height: calc(100% - 70px);
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    padding: 0 0 60px 0;
  }
  @media only screen and (max-width: 1040px) {
    padding: 0 0 50px 0;
  }
  @media only screen and (max-width: 1040px) {
    padding: 0 0 20px 0;
  }
  &-header {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    @media only screen and (max-width: 1200px) {
      width: calc(100% - 60px);
      padding: 0 30px;
    }
    @media only screen and (max-width: 768px) {
      height: 300px;
    }
    @media only screen and (max-width: 480px) {
      height: 250px;
      width: calc(100% - 40px);
      padding: 0 20px;
    }
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .5);
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      z-index: -1;
    }
    h3 {
      color: #fff;
      font-size: 40px;
      margin: 0;
      @media only screen and (max-width: 1200px) {
        font-size: 34px;
        line-height: 42px;
      }
      @media only screen and (max-width: 992px) {
        font-size: 28px;
        line-height: 34px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 22px;
        line-height: 28px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    &-form {
      width: 100%;
      margin: 25px 0 0 0;
      max-width: 65rem;
      &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: 65rem;
        margin: 0 auto;
        border-radius: 999999rem;
        background: #fff;
        overflow: hidden;
        padding: 0 20px;
        box-shadow: 0 .1rem .2rem rgba(#000, .2);
        box-sizing: border-box;
        @media only screen and (max-width: 768px) {
          padding: 0 20px;
        }
        svg {
          color: #999;
          font-size: 2rem;
        }
        input {
          width: 100%;
          padding: 18px 10px;
          border: none;
          outline: none;
          font-size: 18px;
          @media only screen and (max-width: 768px) {
            padding: 14px 10px;
            font-size: 16px;
          }
          &::placeholder {
            color: #999;
            opacity: 1;
          }
        }
      }
    }
  }
  &-body {
    width: 100%;
    max-width: 67.5rem;
    margin: 0 auto;
    padding: 100px 0 50px 0;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 1200px) {
      width: calc(100% - 60px);
      padding: 50px 30px;
    }
    @media only screen and (max-width: 992px) {
      width: calc(100% - 60px);
      padding: 30px;
    }
    @media only screen and (max-width: 768px) {
      width: calc(100% - 40px);
      padding: 30px 20px;
    }
    &-header {
      width: 100%;
      margin-top: 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        @media only screen and (max-width: 768px) {
          width: 55px;
        }
      }
      h1 {
        color: #ec6501;
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        margin: 0 0 0 12px;
        @media only screen and (max-width: 768px) {
          font-size: 24px;
          height: 28px;
        }
      }
    }
    &-links {
      width: 100%;
      margin: 50px 0 0 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media only screen and (max-width: 768px) {
        margin-top: 30px;
      }
      &-item {
        width: calc(33.33% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0;
        &:nth-child(3n+1) {
          padding: 0 40px 0 0;
        }
        &:nth-child(3n+2) {
          padding: 0 20px;
        }
        &:nth-child(3n+3) {
          padding: 0 0 0 40px;
        }
        @media only screen and (max-width: 768px) {
          width: 100%;
          margin-bottom: 40px;
          &:last-child {
            margin-bottom: 0;
          }
          &:nth-child(3n+1) {
            padding: 0;
          }
          &:nth-child(3n+2) {
            padding: 0;
          }
          &:nth-child(3n+3) {
            padding: 0;
          }
        }
        &-image {
          width: 100px;
          height: 100px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          margin: 0;
          @media only screen and (max-width: 768px) {
            height: 70px;
          }
          img {
            width: 60px;
            height: 60px;
            margin: 0;
            @media only screen and (max-width: 768px) {
              width: 50px;
              height: auto;
            }
          }
        }
        h4 {
          margin: 20px 0 10px 0;
          font-size: 24px;
          line-height: 30px;
          color: #000;
          font-weight: 500;
          text-align: center;
          @media only screen and (max-width: 768px) {
            margin-top: 10px;
            font-size: 20px;
            line-height: 26px;
          }
          &:after {
            content: "";
            display: block;
            width: 40px;
            height: 2px;
            left: 50%;
            background-color: #1a73e9;
            transform: translateX(-50%);
            position: relative;
            bottom: -15px;
          }
        }
        p {
          font-size: 14px;
          line-height: 20px;
          margin: 20px 0 0 0;
          text-align: center;
        }
        a {
          margin: 30px 0 0 0;
          padding: 10px 8px 10px 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px transparent;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          transition: .3s ease-in-out;
          border-radius: 4px;
          @media only screen and (max-width: 768px) {
            margin-top: 20px;
          }
          &:hover {
            border-color: #1a73e9;
            span, svg {
              color: #1a73e9;
            }
          }
          span {
            font-size: 14px;
            display: block;
            text-transform: uppercase;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
          }
          svg {
            font-size: 15px;
            margin-left: 5px;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
          }
        }
      }
    }
  }
  &-satisfy {
    width: 100%;
    max-width: 67.5rem;
    margin: 0 auto;
    .medium {
      background: #d8d8d8;
      height: 1px;
      width: 95%;
      max-width: 40rem;
      margin: 0 auto;
      border: none;
    }
    &-title {
      font-size: 1.42rem;
      text-align: center;
      margin: 50px 0 15px 0;
    }
    &-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &.is-invalid {
          .MuiTypography-root{
            color: #ff4444;
            border-color: #ff4444 !important;
          }
        }
        .MuiFormControlLabel-root {
          margin-left: -5px;
          .MuiButtonBase-root {
            display: none;
          }
          &.yes {
            .MuiTypography-root {
              &:before {
                content: "";
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
                background: url("../../imgs/done_black.svg");
                background-size: 20px;
              }
            }
          }
          &.no {
            .MuiTypography-root {
              &:before {
                content: "";
                width: 20px;
                height: 20px;
                display: block;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 20px;
                background: url("../../imgs/close_black.svg");
                background-size: 20px;
              }
            }
          }
          &.yes, &.no {
            .MuiButtonBase-root.Mui-checked ~ .MuiTypography-root {
              &:before {
                filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(40deg) brightness(200%) contrast(200%);
              }
            }
          }
          .MuiTypography-root {
            border: 1px solid #1a73e9;
            border-radius: .2rem;
            color: #1a73e9;
            margin: .5rem .25rem;
            transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            padding: 6px 25px 6px 45px !important;
            font-size: 15px;
            position: relative;
            font-family: 'AvenirThin', sans-serif;
          }
          .MuiButtonBase-root.Mui-checked ~ .MuiTypography-root {
            color: #fff;
            background-color: #1a73e9;
          }
        }
      }
      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: .5rem;
        padding: .5rem .5rem .375rem;
        min-width: 5rem;
        border-radius: .2rem;
        color: #1a73e9;
        text-align: center;
        border: 1px solid #1a73e9;
        cursor: pointer;
        transition: .3s ease-in-out;
        &:hover {
          background-color: #1a73e9;
          color: #ffffff;
        }
        svg {
          margin-right: .375rem;
          margin-top: -.125rem;
          font-size: 1.25rem;
        }
      }
    }
    &-choice-results {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
    }
    &-subtitle {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 3rem auto;
      line-height: 22px;
      @media only screen and (max-width: 992px) {
        margin: 2rem auto;
      }
      @media only screen and (max-width: 768px) {
        margin: 2rem auto 1rem;
      }
      a {
        margin-left: .5rem;
        color: #1a73e9;
      }
    }
    &-suggest {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff0e8;
      padding: 3rem 4rem;
      margin: 30px 0 100px;
      border-radius: 4px;
      -webkit-box-shadow: 0 3px 11px 2px #e3e3e3;
      -moz-box-shadow: 0 3px 11px 2px #e3e3e3;
      box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
      /*box-shadow: 0 3px 11px 2px #e3e3e3;*/
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        text-align: center;
        &-left {
          margin-bottom: 2rem;
        }
      }
      @media only screen and (max-width: 768px) {
        padding: 2rem 3rem;
        margin: 2rem -10px 3rem;
      }
      &-left {
        line-height: 1.5rem;
        font-size: .9rem;
        &-title {
          font-size: 1rem;
        }
      }
      button {
        background: #ff6b20;
        color: #fff;
        padding: .75rem 1rem;
        border-radius: .3rem;
        border: solid 1px #f56b2b;
        transition: .3s ease-in-out;
        &:hover {
          background-color: #ffffff;
          color: #f56b2b;
        }
      }
    }
  }
}

