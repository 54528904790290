.homepage {
  position: relative;

  &-popup {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 97.5%;
    width: 18rem;
    max-height: calc(100vh - 90px);
    box-shadow: 0 .1rem .2rem rgba(#000, .25);

    &-container {
      display: flex;
      position: fixed;
      align-items: center;
      justify-content: center;
      z-index: 100;
      background: rgba(#000, .5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      @media only screen and (max-width: 992px) {
        height: calc(100% - 70px);
        top: 70px;
      }
    }

    &-close {
      position: absolute;
      top: -3.5rem;
      right: -1.5rem;
      padding: 1rem;
      color: #fff;
      font-size: 2rem;
      cursor: pointer;
    }

    &-header {
      background: #f56b2b;
      text-align: center;
      color: #fff;
      padding: 1rem 3rem;
      font-weight: 500;
      line-height: 1.25rem;
      border-radius: .2rem .2rem 0 0;
    }

    &-list {
      width: 100%;
      border-radius: 0 0 .2rem .2rem;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;
      padding: 0;
      height: calc(100% - 72px);
      max-height: 400px;
      overflow-y: auto;

      &-inner {
        width: calc(100% - 1.5rem);
        padding: .75rem;
        display: block;
        max-height: 100%;
        overflow: auto;

        &-item {
          display: flex;
          align-items: center;
          width: calc(100% - .5rem);
          padding: .25rem;
          margin: .25rem 0;
          cursor: pointer;
          position: relative;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

          &-loading {
            justify-content: center;

            &:hover {
              box-shadow: none!important;
              cursor: initial;
            }
          }

          &:hover {
            box-shadow: 0 0 .1rem rgba(#000, .25);
          }

          &-chevron {
            margin-right: 0;
            margin-left: auto;
            color: #666666;
            font-size: 1.5rem;
          }

          img {
            width: auto;
            margin-right: 1rem;
            height: 20px;
            object-fit: contain;
          }
        }
      }
    }
  }

  &-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 3rem;
    color: #fff;
    z-index: 50;
    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    @media only screen and (max-width: 1024px) {
      padding: .5rem 30px;
    }

    @media only screen and (max-width: 768px) {
      padding: .5rem 20px;
    }

    &-dropdown {
      cursor: pointer;
      flex-direction: column;
      align-items: flex-start !important;

      &.closed {
        .first-line {
          background-color: transparent;
          border-radius: 4px;
          border: solid 1px #334761;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          transition: .3s ease-in-out;

          &:hover {
            background-color: #334761;
            span {
              color: #ffffff;
            }
            svg {
              color: #ffffff;
            }
          }
          @media only screen and (max-width: 992px) {
            &:hover {
              background-color: #334761;
              span {
                color: #000 !important;
              }
              svg {
                color: #000 !important;
              }
            }
          }
        }
      }

      .first-line {
        background-color: #ffffff;
      }

      img {
        width: 20px;
        height: auto;
        object-fit: contain;
        object-position: center;
        margin-right: .375rem;
        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }

      svg {
        color: #334761;
        margin: 0 0 -3px 0;
        font-size: 1rem;
        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }

      &.closed:hover {
        .first-line img {
          filter: brightness(80%) grayscale(.25);
        }
      }
    }

    &-logo {
      color: #000000;
      font-size: 4rem;
      line-height: 3rem;
      text-transform: lowercase;
      font-family: 'airstream-webfont', sans-serif;
      transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      @media only screen and (max-width: 992px) {
        font-size: 3rem;
        line-height: 54px;
      }
    }

    &-nav {
      font-size: .75rem;
      font-weight: 600;
      display: block;
      @media only screen and (max-width: 992px) {
        display: none;
      }

      &-burger {
        display: none;
        font-size: 2rem;
        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        cursor: pointer;

        &:hover {
          color: darken(#fff, 20%);
        }
      }

      ul {
        display: flex;

        li {
          position: relative;
          display: flex;
          align-items: center;
          margin-left: 20px;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

          .first-line {
            width: auto;
            min-width: max-content;
            display: flex;
            align-items: center;
            border: solid 1px transparent;
            border-bottom-color: #334761;
            padding: 7px 10px 7px 12px;
            border-radius: 4px 4px 0 0;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            span {
              margin-right: 7px;
              font-family: 'AvenirMedium', sans-serif;
              font-weight: 500;
              font-size: 15px;
              color: #334761;
            }
            &:hover {
              background-color: transparent;
              span {
                color: #334761;
              }
              svg {
                color: #334761;
              }
            }
          }

          .homepage-header-dropdown {
            &-list {
              max-height: 300px;
              position: absolute;
              cursor: initial;
              top: 0;
              z-index: -1;
              left: 0;
              display: flex;
              flex-direction: column;
              border-radius: 4px;
              padding: 2rem 0 .5rem 0;
              width: 150px;
              font-size: 13px;
              overflow: auto;
              background: #fff;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              box-shadow: 0 .1rem .2rem rgba(#000, .25);

              &-inner {
                width: calc(100% - .5rem);
                display: block;
                max-height: 300px;
                overflow-y: auto;
                padding: 0 .25rem;
                &-item {
                  width: calc(100% - .5rem);
                  padding: .25rem;
                  margin: .25rem 0;
                  color: #000;
                  cursor: pointer;
                  font-family: 'AvenirMedium', sans-serif;

                  &:hover {
                    color: #f56b2b;
                  }
                }
              }
            }
          }

          &:hover {
            color: darken(#fff, 20%);
          }

          &.homepage-header-dropdown {
            &:not(.closed) {
              color: #000;

              svg {
                transform: rotate(180deg);
                //color: #334761;
              }
            }

            &.closed {
              .homepage-header-dropdown-list {
                opacity: 0;
                pointer-events: none;
              }
            }
          }

          a {
            font-size: 15px;
            color: #334761;
            padding: 7px 12px;
            border-radius: 4px;
            border:  solid 1px #334761;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            &:hover {
              background-color: #334761;
              color: #ffffff;
            }
          }
        }
      }
    }

    &.scrolled {
      background: #fff;
      color: #000;
      box-shadow: 0 .1rem .5rem rgba(#000, .25);

      .homepage-header-logo,
      .homepage-header-nav-burger  {
        color: #f56b2b;
      }

      li {
        &:hover {
          color: #f56b2b;

          img {
            filter: none;
          }
        }
      }

      .main-header-mobile-opener {
        button {
          svg {
            color: #f56b2b;
          }
        }
      }
    }

    .main-header-mobile-opener {
      display: none;
      button {
        padding: 5px;
        svg {
          color: #ffffff;
          font-size: 32px;
        }
      }
      @media only screen and (max-width: 992px) {
        display: block;
      }
    }
    .main-header-nav-container {
      display: none;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      position: fixed;
      background-color: #ffffff;
      top: 0;
      left: -105%;
      transition: .2s left cubic-bezier(0.785, 0.135, 0.15, 0.86),
      .2s transform cubic-bezier(0.785, 0.135, 0.15, 0.86);

      @media only screen and (max-width: 992px) {
        display: block;
      }

      .mobile-close {
        position: absolute;
        right: 20px;
        top: 15px;
        display: none;
        @media screen and (max-width: 992px){
          display: block;
          right: 0;
        }
        button {
          svg {
            font-size: 32px;
            color: #ffffff;
          }
        }
      }

      .main-header-nav {
        .mobile-logo {
          margin: 20px 0 0;
          font-size: 3.5rem;
          text-transform: lowercase;
          font-weight: normal;
          font-family: 'airstream-webfont', sans-serif;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          color: #f56b2b;
          display: none;
          text-align: left;
          padding: 0 20px;

          @media screen and (max-width: 992px) {
            display: block;
          }
        }
        ul {
          display: flex;
          align-items: center;

          @media screen and (max-width: 992px) {
            align-items: flex-start;
          }

          li {
            height: 100%;
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 22px;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

            @media screen and (max-width: 1150px) {
              margin-left: 18px;
            }

            @media screen and (max-width: 992px) {
              width: calc(100% - 40px);
              margin-left: 0;
              height: auto;
              &.mobile-search {
                border-bottom: solid 1px #cccccc;
              }
            }

            &.spacer {
              width: 1px;
              height: 2rem;
              background: #dbdce0;

              @media screen and (max-width: 1150px) {
                display: none;
              }
            }

            a {
              display: flex;
              height: 70px;
              align-items: center;
              position: relative;
              font-size: 16px;
              font-weight: 400;
              //border-bottom: 3px solid transparent;
              -webkit-transition: .3s ease-in-out;
              -moz-transition: .3s ease-in-out;
              transition: .3s ease-in-out;

              @media screen and (max-width: 1150px) {
                font-size: 15px;
              }

              @media screen and (max-width: 992px) {
                padding: 10px 0;
                font-size: 16px;
                height: auto;
              }

              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                bottom: -2px;
                width: 0;
                height: 4px;
                background-color: #1a73e9;
                transform: translateX( -50% );
                -webkit-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                -o-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
                transition:all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
              }

              &.selected {
                color: #1a73e9;
                border-bottom: solid 4px #1a73e9;
                @media screen and (max-width: 992px) {
                  border-bottom: none;
                  color: #f56b2b;
                }
                &:after {
                  display: none;
                }
              }

              &:hover {
                color: #1a73e9;
                @media screen and (max-width: 992px) {
                  color: #f56b2b;
                }
                &:after {
                  width:100%;
                  @media screen and (max-width: 992px) {
                    display: none;
                  }
                }
              }

              &.btn {
                color: #ffffff;
                font-size: 14px;
                height: 20px;
                border-bottom: none;
                padding: .7rem;
                &:after {
                  display: none;
                }
                &:hover {
                  color: #ffffff;
                }
              }

              .badge {
                position: absolute;
                top: 25px;
                right: 0;
                transform: translateY(-100%);
                background: #1d77d0;
                color: #fff;
                padding: .2rem .25rem .1rem;
                border-radius: .15rem;
                font-size: .7rem;
                font-weight: 600;
                @media screen and (max-width: 992px) {
                  top: 6px;
                }
              }

              svg {
                margin-right: .5rem;
                font-size: 1.25rem;
              }
            }

            .first-line {
              width: calc(10rem - 2rem);
              padding: .5rem 1rem .5rem 0;
              display: flex;
              align-items: center;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              font-family: 'AvenirMedium', sans-serif;
              z-index: 20;
              font-weight: 500;
              position: relative;
              border: none !important;
              outline: none;
              @media screen and (max-width: 992px) {
                width: 100%;
                padding: .5rem 0;
              }
              svg {
                margin: 0;
                position: absolute;
                top: 11px;
                right: 0;
              }
            }

            .homepage-header-dropdown {
              &-list {
                font-family: 'AvenirMedium', sans-serif;
                position: absolute;
                cursor: initial;
                top: 0;
                left: -.5rem;
                display: flex;
                flex-direction: column;
                border-radius: .2rem;
                padding: 2rem 0 0 0;
                min-width: 10rem;
                overflow: hidden;
                background: #fff;
                transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                font-size: .9rem;
                box-shadow: 0 .1rem .2rem rgba(#000, .25);

                @media screen and (max-width: 992px) {
                  position: relative;
                  min-width: 100%;
                  box-shadow: none;
                  left: 0;
                  margin-top: 5px;
                  padding: 0;
                }

                &-inner {
                  width: calc(100% - 1rem);
                  display: block;
                  padding: 0 .5rem .5rem .5rem;
                  max-height: 300px;
                  overflow: auto;
                  @media screen and (max-width: 992px) {
                    width: 100%;
                    padding: 0;
                  }
                  &-item {
                    width: 100%;
                    padding: .25rem 0;
                    margin: .25rem 0;
                    color: #000;
                    cursor: pointer;

                    img {
                      width: 1.3rem;
                      height: auto;
                    }

                    &:hover {
                      color: #f56b2b;
                    }
                  }
                }
              }
            }

            /* &:hover {
               color: #f56b2b;
             }*/

            &.homepage-header-dropdown {
              .first-line {
                img {
                  width: 22px;
                  height: auto;
                  margin-left: 0;
                }
              }
              @media screen and (max-width: 992px) {
                width: calc(100% - 2rem) !important;
                outline: none;
                &:hover {
                  border-color: transparent;
                }
                .first-line {
                  display: flex;
                  align-items: center;
                  font-size: 16px;
                  img {
                    margin-right: 10px;
                  }
                  svg {
                    margin-left: 10px;
                    margin-bottom: 0;
                  }
                }
              }
              &:not(.closed) {
                color: #000;

                svg {
                  transform: rotate(180deg);
                  color: #f56b2b;
                }
              }

              .homepage-header-dropdown-list {
                @media only screen and (max-width: 992px) {
                  display: flex;
                }
              }

              &.closed {
                .homepage-header-dropdown-list {
                  opacity: 0;
                  pointer-events: none;
                  @media only screen and (max-width: 992px) {
                    display: none;
                  }
                }
              }
            }

            &.user-profile {
              @media screen and (max-width: 992px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 0;
                width: 100%;
              }
              button {
                text-transform: none;
                font-size: 16px;
                @media screen and (max-width: 992px) {
                  padding: 6px 0;
                  margin-left: 20px;
                }
                .MuiButton-label {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  .user-icon {
                    width: 20px;
                    font-size: 20px;
                    margin-right: 5px;
                    position: relative;
                    top: -2px;
                  }
                  .user-caret {
                    width: 30px;
                    font-size: 23px;
                    margin-left: 0;
                  }
                }
              }
              .user-popper {
                top: 20px !important;
                outline: none;
                @media screen and (max-width: 992px) {
                  position: relative !important;
                  top: 0 !important;
                  left: 0 !important;
                  transform: none !important;
                  width: 100%;
                }
                .MuiPaper-root {
                  min-width: 330px;
                  margin-top: 0;
                  border-radius: 8px;
                  outline: none;
                  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
                  @media screen and (max-width: 992px) {
                    box-shadow: none;
                    border-top: none;
                    border-bottom: none;
                  }
                  .MuiList-root {
                    width: 100%;
                    padding: 0;
                    .user-profile-inner {
                      width: 100%;
                      padding: 15px 0 0 0;
                      @media only screen and (max-width: 992px) {
                        padding-top: 0;
                      }
                      &-top {
                        display: flex;
                        flex-direction: column;
                        margin: 10px 0 10px 0;
                        align-items: center;
                        justify-content: center;
                        @media only screen and (max-width: 992px) {
                          align-items: flex-start;
                          padding: 0;
                          margin: 0;
                        }
                        &-image {
                          width: 70px;
                          height: 70px;
                          border-radius: 50%;
                          border: solid 1px #ececec;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: center;
                          box-shadow: 0 4px 10px 2px rgba(150, 150, 150, 0.3);
                          @media screen and (max-width: 992px) {
                            display: none;
                          }
                          svg {
                            font-size: 4.5rem;
                            color: #1a73e9;
                          }
                          img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-position: center;
                            object-fit: cover;
                          }
                        }
                        &-username {
                          margin: 10px 0 6px 0;
                          @media screen and (max-width: 992px) {
                            display: none;
                          }
                          h3 {
                            font-size: 20px;
                            font-weight: normal;
                          }
                        }
                        &-email {
                          margin-bottom: 15px;
                          @media screen and (max-width: 992px) {
                            display: none;
                          }
                          ul {
                            display: flex;
                            flex-direction: row;
                            font-weight: normal;
                            li {
                              margin-left: 0;
                              font-size: 14px;
                              color: #6f6f6f;
                              &:nth-child(2) {
                                margin: 0 5px;
                              }
                            }
                          }
                        }
                        a {
                          border:  solid 1px #cacaca;
                          padding: 10px 20px;
                          border-radius: 20px;
                          margin-bottom: 5px;
                          text-transform: none;
                          font-size: 15px;
                          height: auto;
                          color: #000000;
                          @media screen and (max-width: 992px) {
                            padding: 20px 10px;
                            border: none;
                            margin-bottom: 0;
                            margin-left: 35px;
                          }
                          &:hover {
                            background-color: #f2f2f2;
                            color: #1a73e9;
                            &:after {
                              display: none;
                            }
                          }
                        }
                      }
                      &-bottom {
                        padding: 5px 0;
                        border-top: solid 1px #dbdce0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        @media only screen and (max-width: 992px) {
                          align-items: flex-start;
                          padding: 0;
                          margin: 0;
                          border-top: none;
                        }
                        a {
                          width: calc(100% - 20px);
                          margin-left: 0;
                          height: auto;
                          padding: 15px 10px;
                          text-align: center;
                          color: #000000;
                          justify-content: center;
                          transition: .3s ease-in-out;
                          @media only screen and (max-width: 992px) {
                            width: auto;
                            padding: 20px 10px;
                            margin-left: 35px;
                          }
                          &:hover {
                            background-color: #f2f2f2;
                            color: #1a73e9;
                            &:after {
                              display: none;
                            }
                          }
                          .MuiBadge-badge {
                            right: -12px;
                            top: 2px;
                            background-color: #eb4c6e;
                            color: #ffffff;
                          }
                        }
                        @media only screen and (max-width: 992px) {
                          .mobile-hide {
                            display: none;
                          }
                        }
                      }
                      &-footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding: 10px 0;
                        border-top: solid 1px #dbdce0;
                        @media only screen and (max-width: 992px) {
                          justify-content: flex-start;
                          padding: 0;
                          border-top: none;
                        }
                        button {
                          border: solid 1px #cacaca;
                          padding: 5px 20px;
                          text-transform: none;
                          transition: .3s ease-in-out;
                          @media only screen and (max-width: 992px) {
                            padding: 20px 10px 10px 10px;
                            border: none;
                            margin-left: 35px;
                            .MuiButton-label {
                              line-height: 16px;
                            }
                          }
                          &:hover {
                            background-color: #f2f2f2;
                            color: #1a73e9;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.opened {
        display: block;
        left: 0;
        z-index: 0;
        background: rgba(#000, .25);
      }
    }
  }

  &-splash {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    padding:  0;
    color: #fff;
    background-color: #f2eeee;
    //background: radial-gradient(circle, rgba(255,255,255,0) 17%, rgba(23,31,42,0.6954131994594712) 70%);
    z-index: 10;
    @media only screen and (max-width: 1300px) {
      padding-top: 3.8rem;
    }
    @media only screen and (max-width: 1100px) {
      padding-top: 7rem;
    }
    @media only screen and (max-width: 992px) {
      padding: 0;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
    }

    &:after {
      content: "";
      display: block;
      clip-path: polygon(100% 0%, 100% 60%, 50% 50%, 0 50%, 0 0);
      background-color: #ffffff;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-inner {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin: 0;
      padding: 0;
      @media only screen and (max-width: 992px) {
        padding: 0;
        flex-direction: column;
      }
      //max-width: 65rem;

      &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center right;
        z-index: -1;
        @media only screen and (max-width: 992px) {
          object-fit: cover;
          min-height: 500px;
          position: relative;
        }
        @media only screen and (max-width: 768px) {
          min-height: 450px;
        }
      }

      &-container {
        width: 100%;
        margin: 10rem auto 0 3rem;
        position: relative;
        @media only screen and (max-width: 1100px) {
          margin: 30px auto 2.5rem 30px;
        }
        @media only screen and (max-width: 992px) {
          width: calc(100% - 60px);
          max-width: 100%;
          margin: 0;
          padding: 50px 30px 30px 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &-title {
          max-width: 500px;
          color: #000000;
          font-size: 3rem;
          line-height: 3.4rem;
          margin-bottom: 1rem;
          font-weight: 700;
          letter-spacing: -2px;
          font-family: 'Open sans', sans-serif;
          @media only screen and (max-width: 1300px) {
            max-width: 400px;
            font-size: 2.2rem;
            line-height: 3rem;
          }
          @media only screen and (max-width: 1100px) {
            font-size: 1.8rem;
            line-height: 2.2rem;
            max-width: 400px;
          }
          @media only screen and (max-width: 992px) {
            max-width: 100%;
            text-align: center;
            display: none;
            br.breaker {
              display: none !important;
            }
          }
          &-mobile {
            display: none;
            @media only screen and (max-width: 992px) {
              display: block;
              font-size: 2rem;
              line-height: 2.4rem;
              color: #000000;
              text-align: center;
              margin-bottom: 1rem;
              font-weight: 700;
              letter-spacing: -2px;
              font-family: 'Open sans', sans-serif;
            }
          }
        }
        &-desc {
          max-width: 500px;
          font-size: 20px;
          line-height: 28px;
          color: #000000;
          letter-spacing: -1px;
          @media only screen and (max-width: 1300px) {
            max-width: 400px;
            font-size: 16px;
            line-height: 20px;
          }
          @media only screen and (max-width: 1100px) {
            max-width: 400px;
          }
          @media only screen and (max-width: 992px) {
            max-width: 100%;
            text-align: center;
          }
        }
        &-button {
          width: auto;
          display: inline-block;
          position: relative;
          font-size: 18px;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          //border: 1px solid #fff;
          overflow: hidden;
          color: #fff;
          background: #da8a6f;
          z-index: 1;
          border-radius: 4px;
          margin: 60px auto 55px 0;
          padding: 12px 40px !important;
          text-align: center;

          @media only screen and (max-width: 1024px) {
            margin: 34px 0 50px 0;
          }

          @media only screen and (max-width: 992px) {
            margin: 34px auto 50px auto;
          }

          @media only screen and (max-width: 768px) {
            margin-bottom: 0;
          }

          @media only screen and (max-width: 480px) {
            width: calc(100% - 40px);
            font-size: 16px;
            padding: 12px 20px !important;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 100%;
            width: 0;
            background: #334761;
            transition: .5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            z-index: -1;
          }

          &:hover {
            &::after {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &-countries {
    position: relative;
    background: #fff;
    padding: 2rem 0 6rem;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      height: 20rem;
      width: 100%;
      background: #fff;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 92.5%, 0 100%);
      z-index: 1;
    }

    .container {
      width: 100%;
      max-width: 46rem;
      position: relative;
      z-index: 2;
      margin: 0 auto;
    }

    &-title {
      width: calc(100% - 2rem);
      padding: 0 1rem;
      text-align: center;
      margin-bottom: 3rem;
      font-size: .9rem;
      @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 480px) {
        margin-bottom: 1.5rem;
      }
    }

    &-subtitle {
      text-align: center;
      font-size: .8rem;
      margin: 0 auto;
      display: block;
    }

    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      font-size: .75rem;
      text-align: center;

      li {
        width: auto;
        margin: 0 .5rem 1rem .5rem;
        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        border-radius: .25rem;
        background: #fff;
        padding: 0 .5rem .5rem .5rem;
        text-align: center;

        @media screen and (max-width: 768px) {
          width: calc(25% - .5rem);
          padding: 0 .25rem;
          margin: .75rem 0;
        }

        @media screen and (max-width: 480px) {
          margin: .5rem 0;
          width: calc(33.33% - .5rem);
          padding: 0 .25rem;
        }

        a {
          display: flex;
          flex-direction: column;
          align-items: center;

          .image {
            width: 70px;
            height: 70px;
            max-width: 70px;
            max-height: 70px;
            margin: 0 0 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 768px) {
              width: 100%;
              height: 65px;
              /* max-width: 50px;
               max-height: 50px;*/
            }

            @media screen and (max-width: 480px) {
              width: 60px;
              height: 55px;
              /*max-width: 35px;
              max-height: 35px;*/
            }

            img {
              width: 70px;
              height: 70px;
              object-fit: contain;
              margin: auto;
              padding: 0;
              opacity: 1;
              transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              @media screen and (max-width: 768px) {
                width: 65px;
                height: 60px;
              }
              @media screen and (max-width: 480px) {
                width: 60px;
                height: 55px;
              }
            }
          }

          span {
            font-size: 14px;
            line-height: 14px;
            display: block;
            margin-bottom: 3px;
          }
        }


        &:hover {
          transform: translateY(-.25rem);
          box-shadow: 0 .25rem .5rem rgba(#000, .25);
          .image {
            img {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &-testimonials {
    position: relative;
    padding: 4rem 0;
    margin-top: -2rem;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;

    &-title {
      color: #fff;
      text-align: center;
      margin-bottom: 6rem;
      font-size: 1.25rem;
      font-family: 'AvenirMedium', sans-serif;
      @media only screen and (max-width: 768px) {
        margin-bottom: 3rem;
      }
    }

    div:not(.homepage-testimonials-user) {
      height: 20rem;
      max-height: 20rem;
    }

    .jBnCOy {
      margin-top: -1rem;
    }

    .testimonials {
      display: flex;
      align-self: stretch;
      max-width: 70rem;
      margin: 0 auto;

      .rec {
        &-dot {
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          border: none;
          margin: .25rem;
          background: #fff;
          height: 1rem;
          width: .5rem;
          box-shadow: none;
          outline: none;
          font-size: 1rem;
          transform: scale(.5);

          &_active {
            background: #f56b2b;
          }

          &:focus,
          &:hover {
            box-shadow: none;
          }
        }
      }

      .testimonial {
        display: flex;
        flex-direction: column;
        background: #fff;
        padding: 1rem;
        border-radius: .5rem;
        margin: .5rem;
        height: 15rem;

        > img {
          margin: 0 auto;
          height: auto;
          width: 3rem;
          opacity: .3;
        }

        > p {
          text-align: justify;
          text-align-last: center;
          margin: 1rem 0;
          color: #9b9b9b;
          line-height: 1.375rem;
          height: 100%;
          font-family: 'AvenirMedium', sans-serif;

          &::before,
          &::after {
            content: '"';
            font-style: italic;
            margin-right: .25rem;
          }
        }
      }
    }

    &-user {
      display: flex;
      align-items: center;

      img {
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        object-fit: cover;
        object-position: center;
      }

      > p {
        margin-left: 1rem;
        display: flex;
        line-height: 1.5rem;
        flex-direction: column;
        font-family: 'AvenirMedium', sans-serif;

        .country {
          color: #5b5959;
        }

        span {
          font-size: .9rem;
          line-height: 1rem;
        }
      }
    }
  }

  &-prefooter {
    position: relative;
    background: #fff;
    padding: 40px 0 80px;
    @media only screen and (max-width: 992px) {
      padding: 30px 0 60px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0 0 30px;
    }
    .Home-illustration {
      width: 100%;
      max-width: 67.5rem;
      display: flex;
      padding: 0;
      //border-radius: 8px;
      margin: 0 auto 40px auto;
      background-color: #f9e8e1;
      @media only screen and (max-width: 768px) {
        flex-direction: column-reverse;
        margin-bottom: 30px;
      }
      &-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 20px;
        @media only screen and (max-width: 768px) {
          align-items: center;
          width: calc(100% - 40px);
        }
        h3 {
          font-size: 18px;
          line-height: 22px;
          font-weight: 600;
          color: #000000;
          margin-bottom: 15px;
          @media only screen and (max-width: 768px) {
            text-align: center;
          }
        }
        p {
          text-align: left;
          margin-bottom: 15px;
          @media only screen and (max-width: 1120px) {
            width: 100%;
            padding: 0;
          }
          @media only screen and (max-width: 768px) {
            text-align: center;
          }
        }
        a {
          width: auto;
          display: inline-block;
          margin: 0 auto 0 0;
          padding: 10px 20px;
          border-radius: 8px;
          color: #ffffff;
          font-size: 16px;
          background-color: #ffb69c;
          transition: .3s ease-in-out;
          &:hover {
            background-color: #e07f69;
          }
          @media only screen and (max-width: 768px) {
            margin: 0 auto;
          }
        }
      }
      &-image {
        width: 50%;
        padding: 0;
        display: flex;
        align-items: flex-end;
        @media only screen and (max-width: 768px) {
          width: 100%;
          padding: 20px 0 0 0;
        }
        img {
          width: 100%;
        }
      }
    }

    p {
      width: 100%;
      max-width: 67.5rem;
      padding: 0;
      text-align: center;
      line-height: 1.5rem;
      margin: 0 auto;

      @media only screen and (max-width: 1120px) {
        width: calc(100% - 40px);
        padding: 0 20px;
      }

      a {
        color: #0078e1;
      }
    }
  }

  .footer {
    &-categories {
      display: flex;
    }
  }
}