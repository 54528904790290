.main-header {
  background: #fff;
  color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  z-index: 1301;
  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  box-shadow: 0 .1rem .5rem rgba(#000, .25);

  @media only screen and (max-width: 1150px) {
    padding: 0 25px;
  }

  @media only screen and (max-width: 992px) {
    padding: 5px 10px;
  }

  .bigger {
    font-size: 16px;
    @media only screen and (max-width: 1150px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 992px) {
      font-size: 16px;
    }
  }

  .ml {
    margin-left: 22px;
    @media only screen and (max-width: 992px) {
      margin-left: 0;
    }
  }

  .login-link {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 992px) {
      margin-left: -10px;
    }
    .user-icon {
      width: 20px;
      font-size: 20px;
      margin: 0 7px 0 5px;
      position: relative;
      top: -2px;
    }
    &:hover,
    &.selected {
      .user-icon {
        .user-icon {
          fill: #1a73e9;
          @media screen and (max-width: 992px) {
            fill: #f56b2b;
          }
        }
      }
    }
    svg {
      width: 15px;
      margin-left: 8px;
      position: relative;
      top: -2px;
      .user-icon {
        -webkit-transition: .2s ease-in-out;
        -moz-transition: .2s ease-in-out;
        transition: .2s ease-in-out;
      }
      @media screen and (max-width: 992px) {
        margin-left: 0;
      }
    }
  }

  &-dropdown {
    cursor: pointer;
    flex-direction: column;
    align-items: flex-start!important;
    width: 10rem;

    img {
      width: 1rem !important;
      height: .75rem;
      object-fit: contain;
      object-position: center;
      margin-right: .375rem;
      transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    svg {
      margin-left: auto;
      margin-right: .5rem;
      font-size: .8rem;
      transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }

    &.closed:hover {
      .first-line img {
        filter: brightness(80%) grayscale(.25);
      }
    }
  }

  &-logo {
    padding: 10px 0;
    font-size: 3rem;
    text-transform: lowercase;
    font-family: 'airstream-webfont', sans-serif;
    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    color: #f56b2b;
    @media only screen and (max-width: 992px) {
      padding: 6px 10px;
    }
  }

  .mobile-close {
    position: absolute;
    right: 10px;
    top: 15px;
    display: none;
    @media screen and (max-width: 992px){
      display: block;
    }
    button {
      svg {
        font-size: 32px;
        color: #ffffff;
      }
    }
  }

  &-nav {
    font-size: .85rem;
    font-weight: 600;

    @media only screen and (max-width: 992px) {
      width: calc(100% - 80px);
      height: 100%;
      overflow-y: auto;
      top: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      background: #ffffff;
      color: #1a1a1a;
      z-index: 1;
      padding: 0;

      ul {
        width: 100%;
        flex-direction: column;
        text-align: center;
        margin: 0 auto;
        padding: 20px 0;

        li {
          margin: 10px 0;
          justify-content: flex-start;
          padding: 0 20px;

          &.homepage-header-dropdown {
            border: 2px solid #fff;
            border-radius: .125rem;
            padding: .5rem 1rem;
            min-width: 10rem;
            &:hover {
              border-color: #f56b2b;
              @media only screen and (max-width: 992px) {
                border: none;
              }
            }
          }

          a {
            font-weight: 400;
          }
        }
      }

      &-container {
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        position: fixed;
        background-color: #ffffff;
        top: 0;
        left: -105%;
        transition: .2s left cubic-bezier(0.785, 0.135, 0.15, 0.86),
        .2s transform cubic-bezier(0.785, 0.135, 0.15, 0.86);

        &.opened {
          display: block;
          left: 0;
          z-index: 0;
          background: transparent;
          &:before {
            content: "";
            background-color: rgba(0, 0, 0, .5);
            width: 100%;
            height: 100%;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 768px) {
      width: calc(100% - 50px);
      &-container {
        .mobile-close {
          right: 0;
          button {
            padding: 8px;
          }
        }
      }
    }

    .mobile-logo {
      margin: 20px 0 0;
      font-size: 3.5rem;
      text-transform: lowercase;
      font-weight: normal;
      font-family: 'airstream-webfont', sans-serif;
      transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      color: #f56b2b;
      display: none;
      text-align: left;
      padding: 0 40px;

      @media only screen and (max-width: 992px) {
        display: block;
      }
    }

    ul {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 992px) {
        align-items: flex-start;
      }

      li {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 22px;
        transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

        @media only screen and (max-width: 1150px) {
          margin-left: 18px;
        }

        @media only screen and (max-width: 992px) {
          width: calc(100% - 40px);
          margin-left: 0;
          height: auto;
          &.mobile-search {
            border-bottom: solid 1px #cccccc;
          }
        }

        &.spacer {
          width: 1px;
          height: 2rem;
          background: #dbdce0;

          @media only screen and (max-width: 1150px) {
            display: none;
          }
        }

        a {
          display: flex;
          height: 70px;
          align-items: center;
          position: relative;
          font-size: 16px;
          font-weight: 400;
          //border-bottom: 3px solid transparent;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          transition: .3s ease-in-out;

          @media only screen and (max-width: 1150px) {
            font-size: 15px;
          }

          @media only screen and (max-width: 992px) {
            width: 100%;
            padding: 10px 0;
            font-size: 16px;
            height: auto;
          }

          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 50%;
            bottom: -2px;
            width: 0;
            height: 4px;
            background-color: #1a73e9;
            transform: translateX( -50% );
            -webkit-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            -o-transition: all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
            transition:all .3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
          }

          &.selected {
            color: #1a73e9;
            border-bottom: solid 4px #1a73e9;
            @media only screen and (max-width: 992px) {
              border-bottom: none;
              color: #f56b2b;
            }
            &:after {
              display: none;
            }
          }

          &:hover {
            color: #1a73e9;
            @media only screen and (max-width: 992px) {
              color: #f56b2b;
            }
            &:after {
              width:100%;
              @media only screen and (max-width: 992px) {
                display: none;
              }
            }
          }

          &.btn {
            color: #ffffff;
            font-size: 14px;
            height: 20px;
            border-bottom: none;
            padding: .7rem;
            &:after {
              display: none;
            }
            &:hover {
              color: #ffffff;
            }
          }

          .badge {
            position: absolute;
            top: 25px;
            right: 0;
            transform: translateY(-100%);
            background: #1d77d0;
            color: #fff;
            padding: .2rem .25rem .1rem;
            border-radius: .15rem;
            font-size: .7rem;
            font-weight: 600;
            @media only screen and (max-width: 992px) {
              top: 6px;
              right: unset;
              left: 65px;
            }
          }

          svg {
            margin-right: .5rem;
            font-size: 1.25rem;
          }
        }

        &.closed .first-line:hover svg {
          color: #000000;
        }

        .first-line {
          width: calc(10rem - 2rem);
          padding: .5rem 1rem .5rem 0;
          display: flex;
          align-items: center;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          font-family: 'AvenirMedium', sans-serif;
          z-index: 20;
          font-weight: 500;
          position: relative;
          background-color: transparent !important;
          border: none !important;
          svg {
            margin: 0;
            position: absolute;
            top: 11px;
            right: 0;
          }
        }

        .homepage-header-dropdown {
          &-list {
            font-family: 'AvenirMedium', sans-serif;
            position: absolute;
            cursor: initial;
            top: 0;
            left: -.5rem;
            display: flex;
            flex-direction: column;
            border-radius: .2rem;
            padding: 2rem 0 0 0;
            min-width: 10rem;
            overflow: hidden;
            background: #fff;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            font-size: .9rem;
            box-shadow: 0 .1rem .2rem rgba(#000, .25);

            @media only screen and (max-width: 992px) {
              display: none;
            }

            &-inner {
              width: calc(100% - 1rem);
              display: block;
              padding: 0 .5rem .5rem .5rem;
              max-height: 300px;
              overflow: auto;
              &-item {
                width: 100%;
                padding: .25rem 0;
                margin: .25rem 0;
                color: #000;
                cursor: pointer;

                img {
                  width: 1.3rem;
                  height: auto;
                }

                &:hover {
                  color: #f56b2b;
                }
              }
            }
          }
        }

        /* &:hover {
           color: #f56b2b;
         }*/

        &.homepage-header-dropdown {
          .first-line {
            img {
              width: 22px;
              height: auto;
              margin-left: 0;
            }
          }
          @media only screen and (max-width: 992px) {
            width: calc(100% - 2rem) !important;
            .first-line {
              width: calc(100% - 16px);
              display: flex;
              align-items: center;
              font-size: 16px;
              img {
                margin-right: 10px;
              }
              svg {
                margin-left: 10px;
                margin-bottom: 0;
              }
            }
          }
          &:not(.closed) {
            color: #000;

            svg {
              transform: rotate(180deg);
              color: #f56b2b;
            }
          }

          &.closed {
            .homepage-header-dropdown-list {
              opacity: 0;
              pointer-events: none;
            }
          }
        }

        &.user-profile {
          @media only screen and (max-width: 992px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 0;
            width: 100%;
          }
          button {
            text-transform: none;
            font-size: 16px;
            @media screen and (max-width: 992px) {
              padding: 6px 0;
              margin-left: 20px;
            }
            .MuiButton-label {
              display: flex;
              flex-direction: row;
              align-items: center;
              .user-icon {
                width: 20px;
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: -2px;
              }
              .user-caret {
                width: 30px;
                font-size: 23px;
                margin-left: 0;
              }
            }
          }
          .user-popper {
            top: 20px !important;
            outline: none;
            @media only screen and (max-width: 992px) {
              position: relative !important;
              top: 0 !important;
              left: 0 !important;
              transform: none !important;
              width: 100%;
            }
            .MuiPaper-root {
              min-width: 330px;
              margin-top: 0;
              border-radius: 8px;
              outline: none;
              box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
              @media only screen and (max-width: 992px) {
                box-shadow: none;
                border-top: none;
                border-bottom: none;
              }
              .MuiList-root {
                width: 100%;
                padding: 0;
                .user-profile-inner {
                  width: 100%;
                  padding: 15px 0 0 0;
                  @media only screen and (max-width: 992px) {
                    padding-top: 0;
                  }
                  &-top {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0 10px 0;
                    align-items: center;
                    justify-content: center;
                    @media only screen and (max-width: 992px) {
                      align-items: flex-start;
                      padding: 0;
                      margin: 0;
                    }
                    &-image {
                      width: 88px;
                      height: 88px;
                      border-radius: 50%;
                      border: solid 1px #ececec;
                      padding: 5px;
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: center;
                      box-shadow: 0 4px 10px 2px rgba(150, 150, 150, 0.3);
                      @media only screen and (max-width: 992px) {
                        display: none;
                      }
                      svg {
                        font-size: 4.5rem;
                        color: #1a73e9;
                      }
                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-position: center;
                        object-fit: cover;
                      }
                    }
                    &-username {
                      margin: 10px 0 6px 0;
                      @media only screen and (max-width: 992px) {
                        display: none;
                      }
                      h3 {
                        font-size: 20px;
                        font-weight: normal;
                      }
                    }
                    &-email {
                      margin-bottom: 15px;
                      @media only screen and (max-width: 992px) {
                        display: none;
                      }
                      ul {
                        display: flex;
                        flex-direction: row;
                        font-weight: normal;
                        li {
                          margin-left: 0;
                          font-size: 14px;
                          color: #6f6f6f;
                          &:nth-child(2) {
                            margin: 0 5px;
                          }
                        }
                      }
                    }
                    a {
                      border:  solid 1px #cacaca;
                      padding: 10px 20px;
                      border-radius: 20px;
                      margin-bottom: 5px;
                      text-transform: none;
                      font-size: 15px;
                      height: auto;
                      color: #000000;
                      @media only screen and (max-width: 992px) {
                        padding: 20px 10px;
                        border: none;
                        margin-bottom: 0;
                        margin-left: 35px;
                        border-radius: 0;
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                        @media only screen and (max-width: 992px) {
                          background-color: transparent;
                        }
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                  &-bottom {
                    padding: 5px 0;
                    border-top: solid 1px #dbdce0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    @media only screen and (max-width: 992px) {
                      align-items: flex-start;
                      padding: 0;
                      margin: 0;
                      border-top: none;
                    }
                    a {
                      width: calc(100% - 20px);
                      margin-left: 0;
                      height: auto;
                      padding: 15px 10px;
                      text-align: center;
                      color: #000000;
                      justify-content: center;
                      transition: .3s ease-in-out;
                      @media only screen and (max-width: 992px) {
                        width: auto;
                        padding: 20px 10px;
                        margin-left: 35px;
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                        &:after {
                          display: none;
                        }
                      }
                      .MuiBadge-badge {
                        right: -12px;
                        top: 2px;
                        background-color: #ec6501;
                        color: #ffffff;
                      }
                    }
                    @media only screen and (max-width: 992px) {
                      .mobile-hide {
                        display: none;
                      }
                    }
                  }
                  &-footer {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    padding: 10px 0;
                    border-top: solid 1px #dbdce0;
                    @media only screen and (max-width: 992px) {
                      justify-content: flex-start;
                      padding: 0;
                      border-top: none;
                    }
                    button {
                      border: solid 1px #cacaca;
                      padding: 5px 20px;
                      text-transform: none;
                      transition: .3s ease-in-out;
                      @media only screen and (max-width: 992px) {
                        padding: 20px 10px 10px 10px;
                        border: none;
                        margin-left: 35px;
                        .MuiButton-label {
                          line-height: 16px;
                        }
                      }
                      &:hover {
                        background-color: #f2f2f2;
                        color: #1a73e9;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .chat-badge {
    .MuiBadge-badge {
      right: -6px;
      top: -5px;
      background-color: #ec6501;
      color: #ffffff;
    }
  }

  &-mobile {
    &-opener {
      display: none;
      @media only screen and (max-width: 992px) {
        display: block;
      }
      button {
        svg {
          font-size: 32px !important;
          color: #f56b2b;
        }
      }
    }
    &-search {
      display: none;
      @media only screen and (max-width: 992px) {
        display: block;
      }
      a {
        display: block;
      }
      button {
        transition: .2s ease-in-out;
        svg {
          color: #f56b2b;
        }
      }
      .filter-close {
        color: #aab6c6;
      }
    }
  }

  &-desktop-menu {
    @media only screen and (max-width: 992px) {
      display: contents;
    }
  }

  .request-send {
    display: block;
    a {
      color: #f56b2b;
      display: block;
      padding: 10px 12px;
      font-size: 16px;
      border: solid 1px transparent;
      border-radius: 8px;
      transition: .3s ease-in-out;
      &:hover {
        background-color: #f56b2b;
        color: #ffffff;
        border-color: #f56b2b;
      }
    }
  }
}
