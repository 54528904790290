.page-content {
  padding: 0;
  background-color: #ffffff;
  margin-bottom: 0;
  @media only screen and (max-width: 992px) {
    margin: 70px 0;
  }
  .shop {
    width: 100%;
    &-banner {
      width: 100%;
      height: auto;
      padding: 0;
      margin: 0;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        object-position: center;
      }
    }
    &-container {
      width: 65rem;
      margin: 50px auto;
      @media screen and (max-width: 1150px) {
        width: calc(100% - 50px);
        padding: 0 25px;
        margin: 0 0 40px 0;
      }
      @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0 20px;
      }
      @media screen and (max-width: 480px) {
        width: calc(100% - 30px);
        padding: 0 15px;
        margin: 0 0 30px 0;
      }
      &-search {
        .search-filters {
          .search-filters-row.last-row {
            display: none;
          }
          &-select {
            .categories-wrapper {
              display: none;
            }
            .simple-list {
              display: block;
            }
          }
        }
      }
      &-small-title {
        margin: 30px 0;
        padding: 0;
        @media screen and (max-width: 1150px) {
          margin: 30px 0 20px 0;
        }
        @media screen and (max-width: 768px) {
          margin: 30px 0 15px 0;
        }
        p {
          font-size: .9rem;
          line-height: 1.1rem;
          margin: 0;
          > span {
            margin-right: 4px;
          }
        }
      }
      &-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        &-item {
          width: 50%;
          margin: 0 0 15px 0;
          display: block;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          &:nth-child(2n+1) {
            .shop-container-items-item-inner {
              margin: 0 5px 0 0;
              @media screen and (max-width: 768px) {
                margin: 0;
              }
            }
          }
          &:nth-child(2n) {
            .shop-container-items-item-inner {
              margin: 0 0 0 5px;
              @media screen and (max-width: 768px) {
                margin: 0;
              }
            }
          }
          &-inner {
            padding: 10px;
            display: flex;
            background-color: #ffffff;
            flex-direction: row;
            border-radius: 8px;
            //border: solid 1px #dadce0;
            box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
            overflow: hidden;
            text-decoration: none;
            transition: .3s ease-in-out;
            border: solid 1px transparent;
            &:hover {
              background-color: lighten(#f56b2b, 42%);
              border-color: lighten(#ec6501, 20%);
            }
            &-image {
              width: 150px;
              height: 102px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
              }
              &-count {
                position: absolute;
                bottom: 5px;
                left: 5px;
                ul {
                  display: flex;
                  flex-direction: row;
                  padding: 3px 5px;
                  border-radius: 3px;
                  background-color: rgba(0, 0, 0, .4);
                  align-items: center;
                  li {
                    color: #ffffff;
                    font-size: .75rem;
                    margin-right: 4px;
                    &:last-child {
                      margin-right: 0;
                    }
                    svg {
                      font-size: .85rem;
                    }
                  }
                }
              }
            }
            &-text {
              width: calc(100% - 120px);
              padding: 0 0 0 15px;
              display: flex;
              flex-direction: column;
              &-title {
                margin: 0 0 2px 0;
                h2 {
                  font-size: 1.2rem;
                  line-height: 1.4rem;
                  color: #000000;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  transition: .3s ease-in-out;
                  @media only screen and (max-width: 480px) {
                    font-size: 1.1rem;
                    line-height: 1.2rem;
                  }
                  &:hover {
                    color: #f56b2b;
                  }
                }
              }
              &-description {
                margin: 0 0 10px 0;
                p {
                  color: #000000;
                  font-size: .9rem;
                  line-height: 17px;
                  -webkit-line-clamp: 2;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }
              }
              &-category {
                margin: 0 0 12px 0;
                p {
                  font-size: .8rem;
                  font-weight: 700;
                  font-family: "AvenirMedium", sans-serif;
                  line-height: 17px;
                  color: #ec6501;
                  text-transform: uppercase;
                  -webkit-line-clamp: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }
              }
              &-location {
                margin: 0;
                ul {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  li {
                    list-style: none;
                    color: #676767;
                    margin-right: 15px;
                    font-size: .85rem;
                    line-height: 14px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg {
                      margin: 0 5px 0 0;
                    }
                    &:last-child {
                      padding: 3px 7px 4px 7px;
                      background-color: #f56b2b;
                      color: #ffffff;
                      border-radius: 0;
                      margin-right: 0;
                      span {
                        position: relative;
                        top: 1px;
                        margin-right: 4px;
                        font-size: .75rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      /*&-load-more {
        width: 100%;
        display: block;
        text-align: center;
        margin: 15px 0 30px 0;
        button {
          background-color: #ffffff;
          text-transform: none;
          color: #1a73e9;
          padding: 6px 30px;
          border: solid 1px #1a73e9;
          transition: .3s ease-in-out;
          &:hover {
            color: #ffffff;
            background-color: #1a73e9;
          }
        }
      }*/
    }
  }
}

.MuiInputBase-root.Mui-focused {
  background-color: #ffffff !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #1877d3 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1877d3 !important;
}

.MuiFormControl-root .MuiInputLabel-outlined {
  transform: translate(14px, 10px) scale(1);
}

.MuiOutlinedInput-input {
  padding: 10px 32px 8px 10px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #dbdce0 !important;
}
