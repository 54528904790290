.saved-adverts {
  width: 100%;
  padding: 0 0 40px;
  margin: 0;
  @media screen and (max-width: 992px) {
    padding: 0;
    margin: 0 0 70px !important;
  }
  h1 {
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 40px;
    text-align: Left;
    &:after {
      content: "";
      position: relative;
      display: block;
      bottom: -15px;
      width: 50px;
      height: 3px;
      background-color: #333333;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 30px;
    &-inner {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .dashboard-ad-view {
        width: calc(50% - 15px);
        @media only screen and (max-width: 1200px) {
          width: calc(50% - 5px);
        }
        .dashboard-ad-view-link {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
        }
        .dashboard-ad-view-image {
          width: 180px;
        }
        .dashboard-ad-view-link-text {
          width: calc(100% - 180px);
          padding: 20px;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          .dashboard-ad-view-link-image {
            width: 180px;
          }
          .dashboard-ad-view-link-text {
            width: calc(100% - 220px);
            padding: 20px;
          }
        }
        @media screen and (max-width: 768px) {
          .dashboard-ad-view-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .dashboard-ad-view-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
      .dashboard-ad-view-item-footer {
        justify-content: center;
      }
      .dashboard-ad-view-item-footer-delete {
        width: 100%;
      }
    }
  }
  .saved-saved {
    width: 100%;
    .MuiTable-root {
      border: 1px solid rgba(224, 224, 224, 1);
      .MuiTableBody-root {
        .MuiTableRow-root {
          &:nth-child(odd) {
            background-color: #f2f2f2;
          }
          td {
            a {
              color: #000000;
              &:hover {
                text-decoration: underline !important;
              }
            }
          }
        }
      }
    }
  }
}
