.creation-success-message {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 74px);
  overflow: auto;
  @media only screen and (max-width: 992px) {
    height: calc(100vh - 70px);
  }
  &-inner {
    width: 22.5rem;
    padding: 25px 15px;
    border-radius: 8px;
    border: 1px solid #dadce0;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 768px) {
      border: none;
    }
    h1 {
      font-size: 18px;
      font-weight: 400;
      line-height: 32px;
      text-align: center;
    }
    &-icon {
      width: 100%;
      margin: 15px 0 0 0;
      padding: 0;
      display: flex;
      justify-content: center;
      ul {
        width: auto;
        margin: 0 auto;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #5cc171;
        background-color: #f1f1f5;
        border-radius: 20px;
        padding: 10px;
        li {
          list-style: none;
          font-weight: 500;
          font-size: 14px;
          &:nth-child(1) {
            width: 24px;
            height: 24px;
            min-width: 18px;
            min-height: 18px;
            //border: solid 1px #5cc171;
            border-radius: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
              font-size: 24px;
              .path {
                stroke-dasharray: 1000;
                stroke-dashoffset: 0;
              }
              .path.circle {
                -webkit-animation: dash 2s ease-in-out;
                animation: dash 2s ease-in-out;
              }
              .path.line {
                stroke-dashoffset: 1000;
                -webkit-animation: dash 2s 2s ease-in-out forwards;
                animation: dash 2s 2s ease-in-out forwards;
              }
              .path.check {
                stroke-dashoffset: -100;
                -webkit-animation: dash-check 2s 2s ease-in-out forwards;
                animation: dash-check 1s 1s ease-in-out forwards;
              }
              @-webkit-keyframes dash {
                0% {
                  stroke-dashoffset: 1000;
                }
                100% {
                  stroke-dashoffset: 0;
                }
              }
              @keyframes dash {
                0% {
                  stroke-dashoffset: 1000;
                }
                100% {
                  stroke-dashoffset: 0;
                }
              }
              @-webkit-keyframes dash-check {
                0% {
                  stroke-dashoffset: -100;
                }
                100% {
                  stroke-dashoffset: 900;
                }
              }
              @keyframes dash-check {
                0% {
                  stroke-dashoffset: -100;
                }
                100% {
                  stroke-dashoffset: 900;
                }
              }
            }
          }
          &:nth-child(2) {
            margin-left: 10px;
          }
        }
      }
    }
    &-wrapper {
      width: calc(100% - 20px);
      padding: 20px 10px;
      margin: 18px 0 0 0;
      background-color: #f1f1f5;
      border-radius: 8px;
      box-shadow: 4px 5px 5px 0px rgba(58,52,52,0.20);
      -webkit-box-shadow: 4px 5px 5px 0px rgba(58,52,52,0.20);
      -moz-box-shadow: 4px 5px 5px 0px rgba(58,52,52,0.20);
      p {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 25px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      button {
        width: 100%;
        background-color: #1a73e9;
        color: #ffffff;
        margin-top: 0;
        font-size: 16px;
        padding: 10px;
        border-radius: 30px;
        border: solid 1px #1a73e9;
        transition: .3s ease-in-out;
        &:hover {
          background-color: transparent;
          color: #1a73e9;
        }
      }
    }
  }
}