.new-password {
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 74px);
  overflow: auto;
  @media only screen and (max-width: 992px) {
    height: calc(100vh - 70px);
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    padding: 60px;
    margin: 0;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    min-height: 350px;
    @media only screen and (max-width: 768px) {
      padding: 40px 20px;
      border: none;
    }
    h2 {
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      margin-bottom: 50px;
    }
    &-item {
      width: 100%;
      margin-bottom: 25px;
      position: relative;
      &:last-child {
        margin-bottom: 0;
      }
      .MuiFormControl-root {
        width: 100%;
        .MuiOutlinedInput-input {
          padding: 15px 38px 11px 10px !important;
        }
      }
    }
    &-button {
      width: 100%;
      display: block;
      margin-top: 40px;
      background-color: #1a73e9 !important;
      color: #ffffff !important;
      border-radius: 20px !important;
      padding: 15px 20px;
      -webkit-transition: .3s ease-in-out;
      -moz-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      border: solid 1px #1a73e9 !important;
      &:hover {
        background-color: transparent !important;
        color: #1a73e9 !important;
      }
    }
  }
}