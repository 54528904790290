.add-item {
  width: 100%;
  height: auto;
  display: flex;
  margin: 20px 0 20px 0;
  flex-direction: row;
  transition: 0.2s box-shadow cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
  //border: solid 1px #dadce0;
  //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  background-color: transparent;

  @media screen and (max-width: 768px) {
    height: auto;
    margin-bottom: 10px;
    padding: 0;
  }

  &-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    //box-shadow: 0 12px 24px 0 rgba(0,0,0,.13), 0 -1px 6px 1px rgba(24,24,24,.04);
    border-radius: 8px;
    position: relative;
    font-family: 'Open sans', sans-serif;

    .MuiSkeleton-root {
      @media screen and (max-width: 768px) {
        width: 100% !important;
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }
    &-notice {
      position: absolute;
      background-color: #000000;
      top: -20px;
      left: 0;
      display: block;
      padding: 5px 12px 22px;
      border-radius: 10px 10px 10px 10px;
      z-index: 1;
      p {
        color: #ffffff;
        margin: 0;
        padding: 0;
        font-size: 11px;
      }
    }
    &-image {
      width: 100%;
      min-width: 100%;
      height: 200px;
      position: relative;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
      background-color: #ffffff;
      z-index: 2;
      @media screen and (max-width: 768px) {
        width: 100%;
        height: 250px;
      }
      @media screen and (max-width: 480px) {
        height: 200px;
        a {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      &:hover {
        .add-item-inner-image-description {
          visibility: visible;
          opacity: 1;
          pointer-events: all;
        }
      }
      a {
        display: block;
        height: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      &-description {
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, .8);
        visibility: hidden;
        opacity: 0;
        z-index: 2;
        pointer-events: none;
        transition: .3s ease-in-out;
        padding: 30px;
        p {
          -webkit-transition: .2s ease-in-out;
          -moz-transition: .2s ease-in-out;
          transition: .2s ease-in-out;
          -webkit-line-clamp: 4;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          width: calc(100% - 20px);
          text-align: center;
          color: #ffffff;
          font-size: 16px;
          line-height: 22px;
        }
      }
      &-count {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        padding: 4px 6px;
        border-radius: 0 8px 0 8px;
        background-color: rgba(0, 0, 0, .5);
        @media screen and (max-width: 768px) {
          bottom: auto;
        }
        ul {
          display: flex;
          flex-direction: row;
          align-items: center;
          li {
            font-size: 18px;
            color: #ffffff;
            &:first-child {
              margin-right: 6px;
            }
            &:last-child {
              font-size: 14px;
            }
          }
        }
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      position: relative;
      width: calc(100% - 30px);
      padding: 30px 15px 15px 15px;
      border-radius: 0 0 8px 8px;
      background-color: #ffffff;
      border-top: 1px solid #f2f2f2;
      &-call {
        position: absolute;
        top: -18px;
        left: 10px;
        z-index: 10;
        button {
          font-family: 'Open sans', sans-serif;
          border-radius: 20px;
          padding: 7px 12px;
          cursor: pointer;
          font-size: 13px;
          font-weight: 400;
          background-color: #E4733E;
          color: #ffffff;
          border: solid 1px #f56b2b;
          outline: none;
          transition: .3s ease-in-out;
          box-shadow: 0 3px 8px 1px rgba(200, 200, 200, .8);
          @media only screen and (max-width: 480px) {
            padding: 5px 9px;
            font-size: 12px;
          }
          &:hover {
            background-color: #ffffff;
            color: #f56b2b;
          }
        }
      }
      &-title {
        h3 {
          a {
            display: block;
            text-decoration: none;
            font-size: 16px;
            line-height: 20px;
            color: #3b3b3b;
            letter-spacing: 0;
            font-family: 'Open sans', sans-serif;
            font-weight: 800;
            -webkit-transition: .2s ease-in-out;
            -moz-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 20px;
            }
            @media screen and (max-width: 480px) {
              font-size: 15px;
              line-height: 18px;
            }
            &:hover {
              color: #f56b2b;
            }
          }
        }
      }
      &-price {
        width: 100%;
        padding: 0;
        margin: 7px 0 0 0;
        p {
          font-family: 'Open sans', sans-serif;
          font-weight: 700;
          color: #f56b2b;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          @media only screen and (max-width: 480px) {
            font-size: 12px;
          }
          span {
            margin-right: 4px;
            &.pricing {
              margin-right: 0;
              color: #666666;
              //font-weight: normal;
              //text-transform: lowercase;
            }
          }
          &.negociable {
            margin-top: 3px;
            font-weight: normal;
            color: #1a73e9;
            font-size: 12px;
          }
        }
        .free-object {
          font-size: 13px;
          margin-top: 4px;
          display: block;
          color: #5cc171;
        }
      }
      &-location {
        width: 100%;
        padding: 8px 0 0 0;
        display: flex;
        flex-direction: column;
        font-family: 'Open sans', sans-serif;
        @media screen and (max-width: 768px) {
          padding: 5px 0;
          flex-direction: column;
          .MuiSkeleton-root {
            margin-bottom: 10px;
          }
        }
        ul {
          display: flex;
          flex-direction: row;
          align-items: center;
          li {
            font-size: 13px;
            line-height: 18px;
            color: #888888;
            font-weight: 500;
            margin-right: 4px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 768px) {
              font-size: 13px;
            }
          }
        }
        &-left {
          width: 100%;
          margin: 0;
        }
        &-right {
          width: 100%;
          margin: 3px 0 0 0;
        }
      }
      &-favorite {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        position: absolute;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -17px;
        right: 10px;
        background-color: #ffffff;
        border: solid 1px #666666;
        box-shadow: 0 3px 8px 1px rgba(200, 200, 200, .8);
        @media screen and (max-width: 480px) {
          width: 30px;
          height: 30px;
          bottom: 8px;
          right: 8px;
        }
        label {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          color: #000;
          font-size: .8rem;
          cursor: pointer;
        }
        input[type=checkbox] {
          display: none;
          & ~ label {
            position: relative;
            .switch {
              &-on,
              &-off {
                font-size: 1.25rem;
                transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                @media only screen and (max-width: 480px) {
                  font-size: 1.15rem;
                }
              }
              &-off {
                color: #757575;
              }
              &-on {
                position: absolute;
                opacity: 0;
                color: #f56b2b;
              }
            }
          }
          &:checked {
            & ~ label {
              color: #f56b2b;

              .switch {
                &-inner {
                  transform: translateY(-50%) translateX(100%);
                  box-shadow: 0 0 .2rem rgba(#f56b2b, .75);
                }

                &-outer {
                  background: #f56b2b;
                  border-color: #f56b2b;
                }

                &-on {
                  opacity: 1;
                }

                &-off {
                  color: #f56b2b;
                }
              }
            }
          }
        }
      }
    }
  }
}


.dialog {
  .MuiDialog-container {
    .MuiPaper-root {
      .contact {
        width: 280px;
      }
    }

    .MuiDialogTitle-root {
      h2 {
        text-align: center;
      }
    }
    .buttons {
      padding: 0;
      display: flex;
      flex-direction: column;
      .hidden-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #ec6501;
        margin-bottom: 10px;
        min-height: 42px;
        svg {
          font-size: 24px;
          margin-bottom: 0;
          color: #fff;
          margin-right: 5px;
        }
        span {
          color: #fff;
          text-transform: none;
          font-size: 14px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        }
      }
      &-button {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        margin: 0 0 10px 0 !important;
        border-radius: .3rem;
        min-height: 2.5rem;
        font-size: .9rem;
        border: 1px solid transparent;
        cursor: pointer;
        outline: none;
        -webkit-transition: .2s ease-in-out;
        -moz-transition: .2s ease-in-out;
        transition: .2s ease-in-out;

        &:last-child {
          margin-bottom: 0 !important;
        }

        svg {
          font-size: 1.75rem;
          margin-right: .5rem;
        }

        &.orange {
          background-color: #f56b2b;
          border: solid 1px #f56b2b;
          color: #ffffff;
          &:hover {
            background-color: #ffffff;
            color: #f56b2b;
          }
        }

        &.outline {
          color: #1877d3;
          border: solid 1px #1877d3;
          background-color: #ffffff;
          &:hover {
            background-color: #1877d3;
            color: #ffffff;
          }
        }

        &.green {
          background-color: #05cd51;
          border: solid 1px #05cd51;
          color: #ffffff;
          &:hover {
            background-color: #ffffff;
            color: #05cd51;
          }
        }
      }
    }
  }
}
