.skeleton-shop-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
        width: calc(50% - 5px);
        display: flex;
        flex-direction: row;
        margin-bottom: 3px;
        @media only screen and(max-width: 768px) {
            width: 100%;
        }
        .skeleton-shop-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            &-image {
                width: 150px;
                display: flex;
                margin-right: 15px;
                .MuiSkeleton-root {
                    border-radius: 4px;
                }
            }
            &-infos {
                width: calc(100% - 165px);
                display: flex;
                flex-direction: column;
                &-title {
                    width: 100%;
                    display: flex;
                    margin: -8px 0 8px 0;
                }
                &-description {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 8px;
                }
                &-list {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                }
            }
        }
    }
}