.dashboard-ad-view {
  width: 100%;
  height: auto;
  display: flex;
  margin: 0 0 10px 0;
  flex-direction: row;
  position: relative;
  @media only screen and (max-width: 768px) {
    height: auto;
    margin-bottom: 20px;
    padding: 0;
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: solid 1px #dadce0;
    border-radius: 8px;
    overflow: hidden;
    &:hover {
      .dashboard-ad-view-item-link-body-text-title h3 {
        color: #f56b2b;
      }
    }
    &-body {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0;
      .MuiSkeleton-root {
        @media only screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
      /*@media only screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }*/
      &-image {
        width: 160px;
        height: 130px;
        position: relative;
        border-right: solid 1px #dadce0;
        border-radius: 8px 0 0 0;
        @media only screen and (max-width: 1200px) {
          width: 140px;
          max-height: 100%;
        }
        /* @media only screen and (max-width: 768px) {
           width: 100%;
           height: auto;
           max-height: 280px;
         }*/
        @media only screen and (max-width: 480px) {
          width: 130px;
          height: 100px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
        &-count {
          position: absolute;
          left: 0;
          bottom: 24px;
          padding: 4px 6px;
          background-color: rgba(0, 0, 0, .5);
          @media only screen and (max-width: 768px) {
            bottom: 3px;
          }
          ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            li {
              display: flex;
              font-size: 18px;
              color: #ffffff;
              &:first-child {
                margin-right: 6px;
              }
              &:last-child {
                font-size: 14px;
              }
            }
          }
        }
      }
      &-text {
        position: relative;
        width: calc(100% - 160px);
        padding: 20px;
        @media only screen and (max-width: 1200px) {
          padding: 12px;
        }
        /* @media only screen and (max-width: 768px) {
           width: calc(100% - 50px);
         }
         @media only screen and (max-width: 480px) {
           width: calc(100% - 40px);
           padding: 12px;
         }*/
        &-title {
          h3 {
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            -webkit-transition: .2s ease-in-out;
            -moz-transition: .2s ease-in-out;
            transition: .2s ease-in-out;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            @media screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 20px;
            }
            @media screen and (max-width: 480px) {
              font-size: 15px;
              line-height: 18px;
            }
          }
        }
        &-price {
          margin-top: 5px;
          @media only screen and (max-width: 480px) {
            margin-top: 5px;
          }
          p {
            color: #f56b2b;
            font-size: 14px;
            margin-bottom: 0;
            span {
              margin-right: 4px;
            }
            @media only screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
        &-icons {
          margin-top: 20px;
          padding: 0 20px 0 0;
          display: flex;
          flex-direction: row;
          @media only screen and (max-width: 480px) {
            margin-top: 10px;
          }
          /*@media only screen and (max-width: 1200px) {
            flex-direction: column;
            padding: 8px 0;
          }
          @media only screen and (max-width: 768px) {
            padding: 10px 0;
            flex-direction: column;
            .MuiSkeleton-root {
              margin-bottom: 10px;
            }
          }*/
          &-left, &-right {
            ul {
              display: flex;
              flex-direction: row;
              align-items: center;
              &:last-child {
                margin-right: 0;
              }
              li {
                font-size: 13px;
                color: #676767;
                display: flex;
                align-items: center;
                svg {
                  font-size: 18px;
                }
                span {
                  font-size: 14px;
                  margin-left: 10px;
                }
                @media only screen and (max-width: 1200px) {
                  margin-top: 5px;
                }
                @media only screen and (max-width: 768px) {
                  font-size: 13px;
                }
              }
            }
          }
          &-right {
            margin-left: 30px;
          }
        }
      }
    }
    &-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      border-top: solid 1px #dadce0;
      padding: 0;
      &-edited {
        width: 100%;
        display: flex;
        flex-direction: row;
        &-left {
          width: calc(100% - 200px);
          display: flex;
          padding: 0;
          flex-direction: row;
          justify-content: flex-start;
          @media only screen and (max-width: 1200px) {
            width: calc(100% - 180px);
          }
          @media only screen and (max-width: 1030px) {
            width: calc(100% - 160px);
          }
          @media only screen and (max-width: 768px) {
            width: calc(100% - 140px);
          }
          @media only screen and (max-width: 480px) {
            width: calc(100% - 120px);
          }
          @media only screen and (max-width: 320px) {
            width: calc(100% - 100px);
          }
          button {
            color: #1a73e9;
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: transparent;
            padding: 0 15px;
            border-radius: 0;
            &:hover {
              background-color: transparent;
            }
            span {
              font-size: 16px;
              @media only screen and (max-width: 1200px) {
                font-size: 14px;
              }
            }
            svg {
              font-size: 30px;
              margin-left: 5px;
              @media only screen and (max-width: 1200px) {
                font-size: 22px;
              }
            }
          }
        }
        &-right {
          width: 200px;
          padding: 10px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-left: solid 1px #dadce0;
          @media only screen and (max-width: 1200px) {
            width: 180px;
          }
          @media only screen and (max-width: 1030px) {
            width: 160px;
          }
          @media only screen and (max-width: 768px) {
            width: 140px;
          }
          @media only screen and (max-width: 480px) {
            width: 120px;
          }
          @media only screen and (max-width: 320px) {
            width: 100px;
          }
          button {
            width: 34px;
            min-width: 34px;
            height: 34px;
            min-height: 34px;
            border-radius: 50%;
            margin: 0 12px 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            box-shadow: 0 3px 8px 1px rgba(200, 200, 200, .8);
            color: #ec6501;
            padding: 0;
            transition: .3s ease-in-out;
            @media only screen and (max-width: 1200px) {
              width: 34px;
              min-width: 34px;
              height: 34px;
              min-height: 34px;
            }
            &:nth-child(2) {
              svg {
                font-size: 22px;
                @media only screen and (max-width: 1200px) {
                  font-size: 20px;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
            /*&:hover {
              background-color: #05cd51;
              color: #ffffff;
            }*/
            svg {
              font-size: 18px;
              @media only screen and (max-width: 1200px) {
                font-size: 16px;
              }
            }
          }
        }
      }
      &-saved {
        width: 100%;
        display: flex;
        flex-direction: row;
        &-left {
          width: 50%;
          display: flex;
          padding: 0;
          flex-direction: row;
          a {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            display: flex;
            color: #000;
            font-size: 16px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
            &:hover {
              background-color: #1a73e9;
              color: #fff;
            }
            span {
              font-size: 16px;
              @media only screen and (max-width: 1200px) {
                font-size: 14px;
              }
            }
            svg {
              font-size: 30px;
              margin-left: 5px;
              @media only screen and (max-width: 1200px) {
                font-size: 22px;
              }
            }
          }
          a {

          }
        }
        &-right {
          width: 50%;
          padding: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          border-left: solid 1px #dadce0;
          button {
            width: 100%;
            min-width: 44px;
            height: 44px;
            min-height: 44px;
            border-radius: 0;
            margin: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            padding: 0;
            font-size: 16px;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            &:hover {
              background-color: #de0000;
              color: #fff;
            }
            @media only screen and (max-width: 480px) {
              font-size: 14px;
            }
            &:nth-child(2) {
              svg {
                font-size: 22px;
                @media only screen and (max-width: 1200px) {
                  font-size: 20px;
                }
              }
            }
            &:last-child {
              margin-right: 0;
            }
            /*&:hover {
              background-color: #05cd51;
              color: #ffffff;
            }*/
            svg {
              font-size: 18px;
              @media only screen and (max-width: 1200px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

.dialog .MuiPaper-rounded .change-container{
  max-height: unset;
  .MuiDialogContent-root {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  .dialog-container {
    &-item {
      position: relative;
      margin-bottom: 25px;
      &.success {
        p {
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            font-size: 20px;
            margin-right: 5px;
            color: #5cc171;
          }
        }
      }
      .is-invalid {
        .MuiFormLabel-root,
        .MuiSvgIcon-root {
          color: #f44336 !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #f44336 !important;
        }
      }
      .errorMessage {
        font-size: 13px !important;
        bottom: -23px !important;
        margin-bottom: 0;
      }
      p {
        margin-bottom: 20px;
      }
      .MuiInputLabel-formControl {
        background-color: #fff;
        padding: 0 3px;
        transform: translate(14px, 10px) scale(1);
        &.MuiInputLabel-shrink {
          z-index: 3;
          transform: translate(14px, -6px) scale(0.75);
        }
      }
    }
  }
  .confirmation-button {
    padding: 8px;
    .initial-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        width: calc(50% - 5px);
        &:disabled {
          background-color: #1a73e9;
        }
        .MuiCircularProgress-root {
          width: 20px !important;
          height: 20px !important;
          color: #ffffff !important;
          margin-left: 10px;
        }
      }
    }
    .succes-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &-item {
        width: calc(50% - 5px);
        &:nth-child(1) {
          a {
            background-color: transparent;
            color: #1a73e9;
            &:hover {
              background-color: #1a73e9;
              color: #fff;
            }
          }
        }
        &:nth-child(2) {
          a {
            background-color: #1a73e9;
            color: #fff;
            &:hover {
              background-color: transparent;
              color: #1a73e9;
            }
          }
        }
        a {
          display: block;
          width: calc(100% - 16px);
          text-align: center;
          border: solid 1px #1a73e9;
          padding: 6px 8px;
          font-size: 0.875rem;
          min-width: 64px;
          line-height: 1.75;
          border-radius: 4px;
          letter-spacing: 0.02857em;
          -moz-transition: .3s ease-in-out;
          -webkit-transition: .3s ease-in-out;
          transition: .3s ease-in-out;
        }
      }

    }
  }
  &.ad-delete {
    min-width: 420px;
  }
}
