.shop-details-wrapper {
  .loading {
    margin-top: 70px;
    min-height: calc(100vh - 70px);
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1000;
    overflow: hidden;
    width: 100%;
    @media only screen and(width: 992px) {
      min-height: calc(100vh - 58px);
      margin-top: 58px;
    }
  }
  .shop-details {
    &-container {
      width: 65rem;
      padding: 0 50px;
      margin: 0 auto;

      @media screen and (max-width: 1260px) {
        width: calc(100% - 60px);
        padding: 0 30px;
      }
      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
      }

      &-back {
        width: 100%;
        height: 300px;
        min-height: 300px;
        margin: 0 0 115px 0;
        border-radius: 0 0 30px 30px;
        position: relative;
        border: solid 1px #dadce0;
        background-color: #ffffff;

        @media only screen and (max-width: 992px) {
          width: 100%;
          height: 200px;
          min-height: 200px;
          margin-bottom: 100px;
          border-radius: 0;
          border-left: none;
          border-right: none;
        }

        @media only screen and (max-width: 768px) {
          width: 100%;
          height: 180px;
          min-height: 180px;
        }

        @media only screen and (max-width: 480px) {
          height: 150px;
          min-height: 150px;
          margin-bottom: 70px;
        }

        .MuiSkeleton-root {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 0 0 30px 30px;
          @media only screen and (max-width: 992px) {
            border-radius: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 0 0 30px 30px;
          object-position: center;
          @media only screen and (max-width: 992px) {
            border-radius: 0;
          }
        }

        &-shop-image {
          width: 140px;
          height: 120px;
          position: absolute;
          bottom: -70px;
          left: 50px;
          background-color: #ffffff;
          border-radius: 5px;
          padding: 5px;
          box-shadow: 0 0.1rem 0.8rem rgba(0, 0, 0, 0.25);
          @media only screen and (max-width: 992px) {
            width: 130px;
            left: 30px;
          }
          @media screen and (max-width: 768px) {
            left: 20px;
          }
          @media only screen and (max-width: 480px) {
            width: 100px;
            height: 80px;
            bottom: -50px;
            left: 15px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 5px;
          }
        }
      }

      &-infos {
        display: flex;
        flex-direction: row;
        margin: 50px 0;
        padding: 0;
        justify-content: space-between;
        font-family: 'AvenirMedium', sans-serif;
        @media screen and (max-width: 992px) {
          width: calc(100% - 60px);
          flex-direction: column;
          padding: 0 30px;
        }

        @media screen and (max-width: 768px) {
          width: calc(100% - 40px);
          padding: 0 20px;
        }

        @media screen and (max-width: 480px) {
          width: calc(100% - 30px);
          padding: 0 15px;
        }

        &-left {
          width: 300px;
          margin: 0 0 auto 0;
          background-color: #ffffff;
          box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
          //border: solid 1px #dadce0;
          //box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
          padding: 20px;
          border-radius: 8px;
          position: sticky;
          top: 90px;
          @media screen and (max-width: 1200px) {
            width: 300px;
          }
          @media screen and (max-width: 992px) {
            width: calc(100% - 40px);
            position: relative;
            top: 0;
            margin-bottom: 0;
          }
          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0;
            box-shadow: none;
            border-radius: 0;
            margin-bottom: 0;
            border: none;
          }

          &-title {
            width: 100%;
            padding: 0 0 15px 0;
            border-bottom: solid 1px #dadce0;
            margin: 0 0 20px 0;
            @media screen and (max-width: 768px) {
              border-bottom: none;
              margin-bottom: 5px;
            }
            h3 {
              font-size: 22px;
              line-height: 26px;
            }
          }
          &-city {
            margin-bottom: 10px;
            ul {
              li {
                font-size: 18px;
                line-height: 24px;
                margin: 0 0 5px 0;
                font-weight: 600;
                font-family: 'AvenirMedium', sans-serif;
                color: #000;
              }
            }
          }
          &-description,
          &-address,
          &-hours ,
          &-rccm {
            margin: 0 0 15px 0;
            @media screen and (max-width: 768px) {
              margin-bottom: 10px;
            }
            ul {
              display: flex;
              flex-direction: column;
              li {
                font-size: 18px;
                line-height: 24px;
                margin: 0 0 5px 0;
                font-weight: 600;
                font-family: 'AvenirMedium', sans-serif;
                &:last-child {
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 20px;
                  margin-bottom: 0;
                  color: #565555;
                }
              }
            }
          }

          &-rccm {
            ul {
              display: flex;
              flex-direction: row;

              li {
                color: #000000;
                font-size: 16px;
                margin: 0 5px 0 0;

                &:first-child {
                  color: #1a73e9;
                  font-weight: 600;
                }

                &:last-child {
                  font-weight: normal;
                  margin-bottom: 0;
                }
              }
            }
          }

          &-buttons {
            border-top: solid 1px #dadce0;
            padding: 30px 0;
            margin: 30px 0 0 0;
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 768px) {
              flex-direction: column;
              display: none;
            }
            &-button {
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              color: #fff;
              margin: 0 0 15px 0 !important;
              border-radius: .3rem;
              min-height: 2.5rem;
              font-size: .9rem;
              border: 1px solid transparent;
              cursor: pointer;
              -webkit-transition: .2s ease-in-out;
              -moz-transition: .2s ease-in-out;
              transition: .2s ease-in-out;
              text-transform: none;

              @media screen and (max-width: 768px) {
                width: 100%;
                height: 42px;
                margin: 0 0 10px 0 !important;
              }

              &:last-child {
                margin-bottom: 0 !important;
              }

              svg {
                font-size: 1.75rem;
                margin-right: .5rem;
              }

              &.orange {
                background-color: #f56b2b;
                border: solid 1px #f56b2b;
                color: #ffffff;

                &:hover {
                  background-color: #ffffff;
                  color: #f56b2b;
                }
              }

              &.outline {
                color: #1877d3;
                border: solid 1px #1877d3;
                background-color: #ffffff;

                &:hover {
                  background-color: #1877d3;
                  color: #ffffff;
                }
              }

              &.green {
                background-color: #05cd51;
                border: solid 1px #05cd51;
                color: #ffffff;

                &:hover {
                  background-color: #ffffff;
                  color: #05cd51;
                }
              }
            }
          }

          &-website {
            display: flex;
            flex-direction: row;
            padding: 20px 0 0 0;
            border-top: solid 1px #dadce0;
            justify-content: flex-end;
            a {
              font-size: 16px;
              text-decoration: none;
              color: #1a73e9;
              display: block;
              -webkit-transition: .3s ease-in-out;
              -moz-transition: .3s ease-in-out;
              transition: .3s ease-in-out;
              &:hover {
                color: #1a1a1a;
              }
              @media screen and (max-width: 768px) {
                justify-content: flex-start;
              }
              &:hover {
                text-decoration: none;
              }
            }
          }
        }

        &-right {
          width: calc(100% - 250px);
          padding: 0 0 0 30px;
          background-color: #ffffff;
          border-radius: 4px;
          @media screen and (max-width: 1200px) {
            width: calc(100% - 300px);
          }
          @media screen and (max-width: 992px) {
            width: 100%;
            padding: 0;
            margin-top: 40px;
          }
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 30px;
            box-shadow: none;
            border-radius: 0;
          }

          &-search {
            margin: 0;
            display: block;

            @media only screen and (max-width: 768px) {
              margin: 0 -15px;
            }

            .search-filters {
              padding-top: 20px;
              padding-left: 10px;
              padding-right: 10px;
              .search-filters-row > * {
                width: calc(100% / 2 - 2rem);
                margin: 0 1rem;
              }
              .search-filters-input {
                display: none;
              }
            }

            .search-filters-row.last-row {
              display: none;
            }

            .categories-wrapper {
              display: none;
            }

            .simple-list {
              display: block;
            }
          }

          &-items {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            @media screen and (max-width: 768px) {
              padding: 0;
            }

            &-title {
              width: 100%;
              margin: 30px 0;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              @media screen and (max-width: 768px) {
                flex-direction: column;
                padding: 0;
                margin-bottom: 10px;
              }

              &-left {
                display: flex;
                flex-direction: column;
                @media screen and (max-width: 768px) {
                  width: 100%;
                  margin-bottom: 15px;
                }

                &-breadcrumbs {
                  margin: 0 0 8px 0;
                  nav {
                    ol {
                      li {
                        a {
                          font-size: 14px;
                          color: #1a73e9;
                          &:hover {
                            text-decoration: underline !important;
                          }
                        }
                        p {
                          font-size: 14px;
                          color: rgba(0, 0, 0, 0.54);
                        }
                      }
                    }
                  }
                }

                &-count {
                  p {
                    font-size: 14px;
                  }
                }
              }

              &-right {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: #ffffff;
                padding: 2px 15px;
                //border: solid 1px #dadce0;
                border-radius: 8px;
                box-shadow: 0 8px 20px 1px rgba(220, 220, 220, .8);
                /*box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);*/
                @media screen and (max-width: 768px) {
                  width: calc(100% - 40px);
                  margin-bottom: 15px;
                  justify-content: space-between;
                }
                @media screen and (max-width: 480px) {
                  width: calc(100% - 30px);
                }

                &-tri {
                  margin: 0 10px 0 0;
                }

                .MuiFormControl-root {
                  .MuiInput-underline {
                    &:before {
                      border-bottom: none;
                    }

                    &:after {
                      display: none;
                    }
                  }

                  .MuiSelect-select {
                    &:focus {
                      background-color: transparent !important;
                    }
                  }

                  .MuiInput-underline:hover:not(.Mui-disabled):before {
                    border-bottom: none;
                  }
                }
              }
            }

            &-content {
              width: 100%;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              /*@media screen and (max-width: 768px) {
                padding: 0;
                display: block;
                -webkit-column-count: 2;
                -moz-column-count: 2;
                column-count: 2;
                -webkit-column-gap: 14px;
                -moz-column-gap: 14px;
                column-gap: 14px;
                -moz-column-fill: auto;
                column-fill: auto;
                > * {
                  display: inline-block;
                  width: 100%;
                  -webkit-column-break-inside: avoid; !* Chrome, Safari, Opera *!
                  page-break-inside: avoid; !* Firefox *!
                  break-inside: avoid; !* IE 10+ *!
                }
                .skeleton-advert-wrapper {
                  display: contents;
                  div {
                    width: 100%;
                  }
                }
              }
              @media screen and (max-width: 480px) {
                padding: 0;
              }*/
              .add-item {
                width: calc(33.33% - 10px);
                &:nth-child(3n+1) {
                  padding: 0 10px 0 0;
                }
                &:nth-child(3n+2) {
                  padding: 0 5px;
                }
                &:nth-child(3n+3) {
                  padding: 0 0 0 10px;
                }
                @media screen and (max-width: 768px) {
                  width: calc(50% - 7px);
                  &:nth-child(2n+1) {
                    padding: 0 7px 0 0 !important;
                  }
                  &:nth-child(2n) {
                    padding: 0 0 0 7px !important;
                  }
                  margin-bottom: 14px;
                  .add-item-link-image {
                    width: 100%;
                  }
                  .add-item-link-text {
                    width: calc(100% - 40px);
                  }
                }
                @media screen and (max-width: 480px) {
                  .add-item-link-text {
                    width: calc(100% - 20px);
                    padding: 10px;
                  }
                }
              }
            }

            &-mobile {
              width: 100%;
            }

            &-load-more {
              width: 100%;
              display: block;
              text-align: center;
              margin: 15px 0 30px 0;

              button {
                background-color: #ffffff;
                text-transform: none;
                color: #1a73e9;
                padding: 6px 30px;
                border: solid 1px #1a73e9;
                transition: .3s ease-in-out;

                &:hover {
                  color: #ffffff;
                  background-color: #1a73e9;
                }
              }
            }
          }
        }
      }
    }
  }
  footer {
    @media screen and (max-width: 768px) {
      margin-bottom: 58px;
    }
  }
  #menu-categories .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li img {
    margin-right: 0;
    display: none;
  }
}
