.my-adverts-search {
  width: 100%;
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 992px) {
    margin-top: 5px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
  &-form {
    width: 100%;
    margin: 0;
    padding: 0;
    form {
      width: 100%;
      background-color: transparent;
      display: flex;
      flex-direction: row;
      input {
        width: calc(100% - 50px);
        padding: 10px 15px;
        font-size: 15px;
        color: #000000;
        background-color: #ffffff;
        border: solid 1px #dadce0;
        border-radius: 4px 0 0 4px;
        outline: none;
        &:focus {
          border-color: #1a73e9;
          //box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
        }
        &::placeholder {
          color: #000000;
        }
      }
      button {
        min-width: 50px;
        height: 40px;
        color: #000000;
        border: solid 1px #dadce0;
        border-left: none;
        border-radius: 0 4px 4px 0;
        position: relative;
        top: 0;
        background-color: #ffffff;
        transition: .3s ease-in-out;
        &:hover {
          background-color: #1a73e9;
          color: #ffffff;
        }
      }
    }
  }
}
.my-adverts {
  @media only screen and (max-width: 992px) {
    margin: 0 0 40px 0;
  }
  .MuiTabs-root {
    .MuiTabs-flexContainer {
      .MuiButtonBase-root {
        width: 25%;
        max-width: 100%;
        text-transform: none;
        font-size: 16px;
        min-width: max-content;
        @media only screen and (max-width: 768px) {
          width: auto;
          font-size: 14px;
        }
        &.Mui-selected {
          color: #1d77d0;
        }
      }
    }
  }
  .MuiBox-root {
    padding: 30px 0;
    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      &.no-data {
        justify-content: center;
        font-size: 20px;
      }
      .dashboard-ad-view {
        width: calc(50% - 15px);
        @media only screen and (max-width: 1200px) {
          width: calc(50% - 5px);
        }
        .dashboard-ad-view-link {
          box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);
        }
        .dashboard-ad-view-image {
          width: 180px;
        }
        .dashboard-ad-view-link-text {
          width: calc(100% - 180px);
          padding: 20px;
        }
        @media screen and (max-width: 992px) {
          width: 100%;
          .dashboard-ad-view-link-image {
            width: 180px;
          }
          .dashboard-ad-view-link-text {
            width: calc(100% - 220px);
            padding: 20px;
          }
        }
        @media screen and (max-width: 768px) {
          .dashboard-ad-view-link-image {
            width: 100%;
          }
          .add-item-link-text {
            width: calc(100% - 40px);
          }
        }
        @media screen and (max-width: 480px) {
          .dashboard-ad-view-link-text {
            width: calc(100% - 20px);
            padding: 10px;
          }
        }
      }
    }
  }
}

.content-more-load {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 14px;
    margin-bottom: 15px;
    /*color: #989898;*/
    color: #333333;
  }
  button {
    width: auto;
    display: inline-flex;
    margin: 0;
    background-color: #1a73e9;
    color: #ffffff;
    padding: 8px 70px;
    text-transform: none;
    font-size: 18px;
    transition: .3s ease-in-out;
    border: solid 1px #1a73e9;
    font-weight: normal;
    &:disabled {
      background-color: #ccc;
      color: #000;
      border-color: #ccc;
      cursor: not-allowed;
      .MuiButton-label {
        .MuiCircularProgress-root {
          color: #000;
        }
      }
      &:hover {
        background-color: #ccc;
        color: #000000;
      }
    }
    &:hover {
      background-color: #ffffff;
      color: #1a73e9;
      box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    }
    .MuiButton-label {
      .MuiCircularProgress-root {
        width: 24px !important;
        height: 24px !important;
        margin-left: 15px;
        color: #ffffff;
      }
    }
  }
}
