.footer {
  width: 100%;
  position: relative;
  background: #fff;
  /*box-shadow: 0 -.1rem .25rem rgba(#000, .125);*/

  @media screen and (max-width: 880px) {
    padding-top: 0;
  }

  .container-wrapper {
    width: 100%;
    position: relative;
    background-color: #af5130;
    //background: url("../../imgs/c.svg") center center no-repeat;
    background-size: cover;
    overflow: hidden;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: -5%;
      left: -5%;
      width: 110%;
      height: 110%;
      background: url("../../imgs/b.svg") center center no-repeat;
      background-size: cover;
      z-index: 1;
      mix-blend-mode: multiply;
    }

    .container-wrapper-inner {
      width: 67.5rem;
      max-width: 100%;
      margin: 0 auto;
      padding: 0;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
        background: url("../../imgs/c-2.svg") center center no-repeat;
        background-size: contain;
        z-index: 1;
        mix-blend-mode: multiply;
        @media only screen and (max-width: 768px) {
          background: url("../../imgs/back-mobile-top.svg") no-repeat top center;
          background-size: contain;
          width: 100%;
          height: 100%;
          left: 0;
          top: -40px;
        }
      }

      &:after {
        display: none;
        @media only screen and (max-width: 768px) {
          content: "";
          display: block;
          position: absolute;
          background: url("../../imgs/back-mobile-bottom.svg") no-repeat bottom center;
          background-size: contain;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: -40px;
          z-index: 1;
          mix-blend-mode: multiply;
        }
      }

      @media screen and (max-width: 1100px) {
        width: calc(100% - 60px);
        padding: 0 30px;
      }

      @media screen and (max-width: 880px) {
        display: flex;
        flex-direction: column;
        align-items: center;

        .footer-banner-countries.mobile {
          width: max-content;

          & ~ hr {
            margin-bottom: 2rem;
          }
        }
      }

      @media screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 0 0 0 20px;
      }
    }
  }

  .footer-mansory {
    display: flex !important;
    width: 100% !important;
  }

  &-categories {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 35px 0 50px 0 !important;
    margin: 0;
    position: relative;
    z-index: 2;
    overflow: hidden;

    @media only screen and(max-width: 768px) {
      padding: 30px 0 !important;
    }

    .footer-categories-item-wrapper {
      max-height: 592px;
      overflow: hidden;
      @media only screen and(max-width: 768px) {
        max-height: unset;
      }
    }

    &-item {
      width: calc(100% - 20px);
      padding: 15px 0 25px 0;
      border-bottom: solid 1px #ffffff;
      margin-bottom: 15px;
      font-size: .9rem;
      font-family: 'AvenirMedium', sans-serif;

      .upper {
        color: #ffffff;
        line-height: 20px;
        margin-bottom: 0;
      }

      @media only screen and(max-width: 768px) {
        padding: 10px 0 15px 0;
        margin-bottom: 10px;
      }

      ul {
        margin-top: .5rem;
        padding-right: 10px;
        li {
          font-size: .8rem;
          line-height: 15px;
          margin-bottom: .25rem;
          color: #ffffff;
          @media only screen and (max-width: 768px) {
            line-height: 18px;
          }
        }
      }
    }
  }

  &-banner {
    background: #474747;
    padding: 1rem 0;
    color: #fff;

    .container {
      width: 67.5rem;
      max-width: 100%;
      margin: 0 auto;
      padding: 0;

      @media screen and (max-width: 1100px) {
        width: calc(100% - 60px);
        padding: 0 30px;
      }

      @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
        padding: 0 20px;
      }
    }

    &-nav {
      font-size: .8rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 3rem;
    }

    &-social {
      margin: 2rem 0;
      display: flex;
      align-items: center;

      &-left {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-right: 1px solid #b3b3b3;
        padding-right: 2rem;
        font-size: .8rem;

        img {
          height: 2rem;
          object-fit: contain;
          margin-left: .5rem;
        }
      }

      &-right {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 2rem;
        font-size: .8rem;

        svg {
          font-size: 1.75rem;
          margin-left: 1rem;
        }
      }

      @media screen and (max-width: 880px) {
        flex-direction: column;
        margin: 0 auto;

        &-left {
          width: 65%;
          flex-wrap: wrap;
          text-align: center;
          padding: 0;
          border: none;

          strong {
            width: 100%;
            font-size: 1.5rem;
            margin-bottom: 1rem;
            @media only screen and (max-width: 768px) {
              margin-top: 10px;
            }
          }

          a {
            max-width: calc(50% - 1rem);
            margin: .5rem;

            img {
              width: 100%;
              height: unset;
              margin: 0;
            }
          }
        }

        &-right {
          width: 100%;
          padding: 0;
          justify-content: center;

          svg {
            font-size: 1.75rem;
            margin: .5rem;
          }
        }

        .copy {
          margin: 1rem auto .5rem;
        }
      }
    }

    &-countries {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem 0;
      font-size: .8rem;
      font-family: 'AvenirMedium', sans-serif;

      @media only screen and (max-width: 880px) {
        width: 100%;
      }

      &.copyright {
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 880px) {
          justify-content: center;
        }
      }

      &-country {
        color: #000;
        display: flex;
        align-items: center;
        background: #fff;
        padding: .5rem;
        border-radius: .2rem;
        font-weight: 600;
        font-size: .9rem;
        cursor: pointer;
        position: relative;

        @media only screen and (max-width: 880px) {
          width: max-content;
          margin: 0 auto;
        }

        @media only screen and (max-width: 768px) {
          text-align: center;
          padding: 8px 30px 8px 8px;
          > svg {
            position: absolute;
            top: 50% !important;
            right: 8px;
            transform: translateY(-50%);
          }
        }

        & > img {
          margin-right: .5rem;
          height: 1rem;
        }

        & > svg {
          margin-left: 1.5rem;
        }
      }
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid #787e85;

    &.short {
      width: 50%;
      max-width: 20rem;
    }
    &.medium {
      width: 80%;
      max-width: 30rem;
      @media only screen and (max-width: 880px) {
        display: block;
      }
    }
    &.long {
      width: 100%;
    }
    &.blank {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      border: none;
      background: #fff;
      height: .2rem;
    }
  }
}
