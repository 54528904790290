.privacy-data-container {
  width: 100%;
  display: flex;
  max-width: 65rem;
  flex-direction: column;
  margin: 70px auto 0 auto;
  padding: 70px 30px;
  height: auto;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    max-width: 100%;
    margin: 50px auto 0 auto;
  }
  @media only screen and (max-width: 992px) {
    margin: 0;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
    margin: 70px auto 0 auto;
    padding: 30px 20px 40px 20px;
  }
  &-inner {
    width: 100%;
    height: auto;
    display: flex;
    margin-bottom: auto;
    flex-direction: column;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
      box-shadow: none;
      padding: 0;
    }
    &-header {
      width: calc(100% - 40px);
      padding: 40px 30px 30px 30px;
      display: flex;
      flex-direction: row;
      /*border-bottom: solid 1px #ddd;*/
      @media only screen and (max-width: 768px) {
        width: calc(100% - 20px);
        padding: 10px 10px 30px 10px;
      }
      @media only screen and (max-width: 480px) {
        width: calc(100% - 10px);
        padding: 5px 5px 30px 5px;
      }
      &-image {
        width: 60px;
        @media only screen and (max-width: 768px) {
          width: 54px;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      &-text {
        width: calc(100% - 60px);
        padding: 0 0 0 15px;
        h1 {
          font-family: 'Roboto', sans-serif;
          font-size: 26px;
          margin: 0;
          color: #ec6501;
          line-height: 32px;
          font-weight: bolder;
          @media only screen and (max-width: 768px) {
            font-size: 24px;
            line-height: 26px;
          }
          &:after {
            content: "";
            display: block;
            background-color: #1a73e9;
            width: 40px;
            height: 2px;
            position: relative;
            left: 0;
            bottom: -5px;
          }
        }
        p {
          font-size: 13px;
          margin: 15px 0 0 0;
          font-family: "Roboto", sans-serif;
        }
      }

    }
    &-body {
      width: calc(100% - 60px);
      padding: 0 30px 40px 30px;
      font-family: "Roboto", sans-serif;
      @media only screen and (max-width: 768px) {
        padding: 0 10px 30px 10px;
      }
      @media only screen and (max-width: 480px) {
        padding: 0 5px 30px 5px;
      }
      &-item {
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        h2 {
          font-size: 22px;
          line-height: 26px;
          margin: 0 0 20px 0;
          color: #1a73e9;
          font-family: "Roboto", sans-serif;
          font-weight: bolder;
          /*text-decoration: underline;*/
        }
        h3 {
          color: #000;
          font-size: 18px;
          line-height: 24px;
          text-decoration: underline;
          margin: 20px 0;
          font-weight: bolder;
        }
        p {
          font-size: 16px;
          line-height: 22px;
          margin-bottom: 10px;
          text-decoration: none;
          &:last-child {
            margin-bottom: 0;
          }
           a {
             color: #1a73e9;
             -webkit-transition: all .3s ease;
             -moz-transition: all .3s ease;
             transition: all .3s ease;
             text-decoration: none;
             &:hover {
               color: #000000;
               text-decoration: underline;
             }
           }
        }
        ul {
          margin: 0 0 20px 0;
          padding: 0 0 0 18px;
          li {
            list-style: circle;
            margin-bottom: 10px;
            line-height: 22px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

    }
  }
}
