.faq-container {
  width: 100%;
  max-width: 67.5rem;
  margin: 70px auto 0 auto;
  height: calc(100% - 70px);
  padding: 70px 0;
  font-family: "Roboto", sans-serif;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    padding: 40px 30px 60px 30px;
  }
  @media only screen and (max-width: 1040px) {
    width: calc(100% - 40px);
    padding: 30px 20px 50px 20px;
  }
  &-header {
    width: 100%;
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      width: 50px;
    }
    h1 {
      color: #ec6501;
      font-size: 26px;
      font-weight: 500;
      line-height: 32px;
      margin: 0 0 0 12px;
      @media only screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 26px;
      }
    }
  }
  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
    > p {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;
      a {
        color: #1a73e9;
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &-item {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      h2 {
        font-size: 20px;
        line-height: 26px;
        margin: 0;
        font-weight: 500;
        color: #1a73e9;
        @media only screen and (max-width: 768px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      &-accordions {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        &-item {
          margin-top: 15px;
          width: calc(50% - 15px);
          &:nth-child(2n+1) {
            margin-right: 15px;
          }
          &:nth-child(2n) {
            margin-left: 15px;
          }
          @media only screen and (max-width: 768px) {
            width: 100%;
            &:nth-child(2n+1) {
              margin-right: 0;
            }
            &:nth-child(2n) {
              margin-left: 0;
            }
          }
          .MuiAccordion-root {
            width: calc(100% - 40px);
            border: solid 1px #dedede;
            box-shadow: none;
            margin-bottom: 10px;
            padding: 15px 20px;
            border-radius: 4px !important;
            overflow: hidden;
            @media only screen and (max-width: 768px) {
              width: calc(100% - 30px);
              padding: 15px;
            }
            &.Mui-expanded {
              margin: 0;
              .MuiAccordionSummary-content {
                p {
                  color: #1a73e9;
                }
              }
              .MuiIconButton-root {
                &:after {
                  content: "";
                  width: 14px;
                  height: 3px;
                  background-color: #1a73e9;
                  display: block;
                }
                span {
                  display: none;
                  svg {
                    display: none;
                  }
                }
              }
              .MuiCollapse-container  {
                margin-top: 12px;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
            .MuiAccordionSummary-root {
              background-color: transparent;
              color: #000;
              margin-right: 0;
              margin-left: 0;
              padding: 0;
              min-height: 0;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              /*&.Mui-expanded {
              }*/
              .MuiAccordionSummary-content {
                width: calc(100% - 40px);
                margin: 0;
                flex-grow: initial;
                p {
                  font-family: inherit;
                  font-size: 18px;
                  line-height: 22px;
                  font-weight: 500;
                  @media only screen and (max-width: 768px) {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }
              }
              .MuiIconButton-root {
                position: relative;
                right: 0;
                width: 32px;
                height: 32px;
                color: #000;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: -8px;
              }
            }
            .MuiCollapse-container {
              transition: all .3s ease;
              .MuiAccordionDetails-root {
                padding: 0;
                display: flex;
                flex-direction: column;
                p {
                  font-size: 15px;
                  line-height: 20px;
                  margin-bottom: 12px;
                  strong {
                    font-weight: 500;
                  }
                  a {
                    color: #1a73e9;
                    -webkit-transition: .3s ease-in-out;
                    -moz-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                    &:hover {
                      text-decoration: underline;
                    }
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                ul {
                  margin: 5px 0 20px 0;
                  padding: 0 0 0 25px;
                  display: flex;
                  flex-direction: column;
                  li {
                    list-style: circle;
                    font-size: 15px;
                    line-height: 20px;
                    margin-bottom: 7px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
          .MuiAccordion-rounded:first-child,
          .MuiAccordion-rounded:last-child {
            border-radius: 0;
          }
        }
      }
      &-read-more {
        width: 100%;
        margin: 20px 0 0 0;
        button {
          background-color: transparent;
          border: solid 1px #1a73e9;
          padding: 10px 10px 8px 15px;
          border-radius: 4px;
          display: flex;
          flex-direction: row;
          color: #1a73e9;
          cursor: pointer;
          -webkit-transition: .3s ease-in-out;
          -moz-transition: .3s ease-in-out;
          transition: .3s ease-in-out;
          &:hover {
            background-color: #1a73e9;
            color: #fff;
          }
          span {
            font-size: 14px;
            display: block;
            text-transform: uppercase;
          }
          svg {
            font-size: 15px;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
