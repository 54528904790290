.search-results {
  padding: 0;
  margin: 0 0 15px 0;
  position: relative;
  display: flex;
  min-height: 300px;
  flex-direction: column;
  background-color: transparent;
  .skeleton-advert-wrapper {
    div {
      width: calc(33% - 10px);
      @media only screen and (max-width: 768px) {
        width: calc(50% - 10px);
      }
    }
  }
  .search-save-wrapper {
    display: flex;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: -webkit-sticky;
    position: sticky;
    top: 74px;
    z-index: 1302;
    left: 0;
    height: 0;
    width: calc(100% - 20px);
    background-color: #ffffff;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    padding: 0;
    margin: 0;
    border-radius: 0 0 5px 5px;
    transition: height, visibility, opacity .3s ease-in-out;
    @media screen and (max-width: 992px) {
      margin: 0;
      top: 70px;
      border-radius: 0;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      top: 70px;
      margin: 0 -10px;
    }
    &.show {
      height: auto;
      padding: 10px;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }
    &-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
      &-left {
        width: auto;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 768px) {
          width: 50%;
        }
        @media screen and (max-width: 480px) {
          width: 100%;
          margin-bottom: 10px;
        }
        p {
          margin-bottom: 10px;
          line-height: 20px;
          @media screen and (max-width: 480px) {
            text-align: center;
          }
        }
        button {
          width: auto;
          display: inline-flex;
          margin: 0 auto 0 0;
          color: #1a73e9;
          padding: 5px 12px;
          border: solid 1px #1a73e9;
          background-color: transparent;
          transition: .3s ease-in-out;
          text-transform: none;
          @media screen and (max-width: 480px) {
            width: 100%;
          }
          &:hover {
            background-color: #1a73e9;
            color: #ffffff;
          }
        }
      }
      &-right {
        width: auto;
        @media screen and (max-width: 768px) {
          width: 50%;
          display: flex;
          justify-content: flex-end;
        }
        @media screen and (max-width: 480px) {
          width: 100%;
          justify-content: center;
        }
        button {
          color: #1a73e9;
          font-size: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: transparent;
          transition: .3s ease-in-out;
          padding: 5px 10px 5px 12px;
          border:  solid 1px transparent;
          @media screen and (max-width: 480px) {
            width: 100%;
          }
          &:hover {
            border-color: #1a73e9;
          }
          svg {
            margin-left: 5px;
            font-size: 22px;
            width: 22px;
          }
          .MuiButton-label {
            text-transform: none !important;
          }
        }
      }
    }
  }
  .advertisements-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /*@media screen and (max-width: 768px) {
      width: 100%;
      padding: 0;
      display: block;
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 14px;
      -moz-column-gap: 14px;
      column-gap: 14px;
      -moz-column-fill: unset;
      column-fill: auto;
      > * {
        display: inline-block;
        width: 100%;
        -webkit-column-break-inside: avoid; !* Chrome, Safari, Opera *!
        page-break-inside: avoid; !* Firefox *!
        break-inside: avoid; !* IE 10+ *!
      }
    }
    @media screen and (max-width: 480px) {
      padding: 0;
    }*/

    .add-item {
      width: calc(33.33% - 12px);
      break-inside: avoid-column;
      &:nth-child(3n+1) {
        padding: 0 12px 0 0;
      }
      &:nth-child(3n+2) {
        padding: 0 6px;
      }
      &:nth-child(3n+3) {
        padding: 0 0 0 12px;
      }
      @media screen and (max-width: 992px) {
        width: calc(50% - 12px);
        &:nth-child(2n+1) {
          padding: 0 10px 0 0;
        }
        &:nth-child(2n) {
          padding: 0 0 0 10px;
        }
      }
      @media screen and (max-width: 768px) {
        width: calc(50% - 7px);
        &:nth-child(2n+1) {
          padding-right: 7px;
        }
        &:nth-child(2n) {
          padding-left: 7px;
        }
        margin-bottom: 14px;
        .add-item-link-image {
          width: 100%;
        }
        .add-item-link-text {
          width: calc(100% - 40px);
        }
      }
      @media screen and (max-width: 480px) {
        .add-item-link-text {
          width: calc(100% - 20px);
          padding: 10px;
        }
      }
    }

    &-item {
      position: relative;
      margin: 1rem 0;
      width: 100%;
      height: 10rem;
      background: #fff;
      border-radius: .1rem;
      overflow: hidden;
      transition: .2s box-shadow cubic-bezier(0.785, 0.135, 0.15, 0.86);

      & > a {
        display: flex;
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
        cursor: pointer;

        @media screen and (max-width: 880px) {
          flex-direction: column;
        }
      }

      &:hover {
        box-shadow: 0 0 .2rem rgba(#000, .375);
      }

      &-switch {
        position: absolute;
        z-index: 10;
        bottom: .5rem;
        right: .5rem;

        label {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          color: #000;
          font-size: .8rem;
          cursor: pointer;
        }

        input[type=checkbox] {
          display: none;

          & ~ label {
            position: relative;

            .switch {
              &-on,
              &-off {
                font-size: 1.25rem;
                transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
              }

              &-off {
                color: #757575;
              }

              &-on {
                position: absolute;
                opacity: 0;
                color: #f56b2b;
              }
            }
          }

          &:checked {
            & ~ label {
              color: #f56b2b;

              .switch {
                &-inner {
                  transform: translateY(-50%) translateX(100%);
                  box-shadow: 0 0 .2rem rgba(#f56b2b, .75);
                }

                &-outer {
                  background: #f56b2b;
                  border-color: #f56b2b;
                }

                &-on {
                  opacity: 1;
                }

                &-off {
                  color: #f56b2b;
                }
              }
            }
          }
        }
      }

      &-image {
        width: 16rem;
        max-width: 16rem;
        min-width: 16rem;
        height: 100%;
        object-fit: cover;
        object-position: center;

        @media screen and (max-width: 880px) {
          font-size: 1rem;
          width: 100%;
          max-width: unset;
          min-width: unset;
        }

        &-count {
          display: flex;
          align-items: center;
          position: absolute;
          padding: .25rem .5rem 0;
          background: rgba(#000, .5);
          color: #fff;
          bottom: 1.5rem;
          left: 0;
          font-weight: 600;
          font-size: .9rem;

          svg {
            font-size: 1.1rem;
            margin-right: .25rem;
            margin-bottom: .3rem;
          }
        }

        &-ads {
          display: flex;
          align-items: center;
          position: absolute;
          padding: .125rem .25rem;
          background: #fff;
          color: #000;
          top: .25rem;
          left: .25rem;
          border-radius: .1rem;
          font-size: .8rem;
          box-shadow: 0 0 .2rem rgba(#000, .75);
        }
      }

      &-content {
        padding: 1.5rem;
        width: calc(100% - 16rem);
        box-sizing: border-box;

        &-name {
          font-size: 1.125rem;
          text-transform: uppercase;
          color: #000;
          transition: .2s font-size cubic-bezier(0.785, 0.135, 0.15, 0.86),
          .2s color cubic-bezier(0.785, 0.135, 0.15, 0.86);

          &:hover {
            color: #f56b2b;
          }

          @media screen and (max-width: 880px) {
            font-size: 1rem;
          }
        }

        &-meta {
          display: flex;
          align-items: center;
          color: #676767;
          margin: 1rem 1.25rem 1rem 0;
          font-size: .8rem;

          svg {
            margin-right: .25rem;

            &:not(:first-child) {
              margin-left: 1rem;
            }
          }
        }

        &-price {
          color: #f56b2b;
          font-weight: 600;
        }
      }

      &.advertisements-list-item--ads {
        .advertisements-list-item-content {
          &-name {
            color: #000;
          }

          &-desc {
            color: #89919c;
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: block;
          }
        }
      }

      @media screen and (max-width: 880px) {
        width: calc(50% - 1rem);
        margin: .5rem;
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        height: unset;
        box-shadow: 0 0 .2rem rgba(#000, .375);

        &-image {
          width: 100%;
          height: 10rem;

          &-count {
            bottom: unset;
            top: 1rem;
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: .75rem;

          &-price {
            order: -1;
            margin-bottom: 1rem;
          }

          &-meta {
            margin-bottom: unset;
          }
        }

        &.advertisements-list-item--ads {
          .advertisements-list-item-content {
            &-price {
              order: 1;
              margin: 1rem 0 0 auto;
            }
          }
        }
      }
    }
  }
}
