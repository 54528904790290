.simple-list {
  &-category {
    width: 100%;
    .MuiFormLabel-root {
      padding-left: 4px;
    }
    .MuiInputBase-root {
      .MuiSelect-root {
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
          width: 20px;
          margin-right: 10px;
        }
      }
      svg {
        display: none;
      }
    }
  }
}

.MuiPopover-root {
  .MuiPaper-root {
    .MuiList-root {
      .MuiButtonBase-root {
        &:hover {
          color: #ec6501;
        }
        img {
          filter: none !important;
          width: 24px !important;
        }
      }
    }
  }
}
