.cookies-popup {
  width: 450px;
  height: auto;
  padding: 0;
  position: fixed;
  left: -100%;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  z-index: 1000;
  background-color: #ffffff;
  border-radius: 15px;
  transition: left .3s ease-in-out;
  -webkit-box-shadow: 0 10px 35px 2px rgba(100,100,100,0.3);
  -moz-box-shadow: 0 10px 35px 2px rgba(100,100,100,0.3);
  box-shadow: 0 10px 35px 2px rgba(100,100,100,0.3);

  @media screen and (max-width: 480px) {
    width: 100%;
    border-radius: 0;
    left: 0;
    bottom: 0;
  }

  &.show {
    left: 15px;
    @media screen and (max-width: 480px) {
      left: 0;
      width: 100%;
      margin: 0 auto;
    }
  }

  &-header {
    width: calc(100% - 50px);
    display: flex;
    flex-direction: row;
    padding: 25px 25px 10px;
    justify-content: space-between;
    align-items: center;
    &-text {
      ul {
        display: flex;
        flex-direction: column;
        li {
          font-size: 16px;
          margin-bottom: 5px;
          &:nth-child(2) {
            font-size: 30px;
            @media screen and (max-width: 480px) {
              font-size: 24px;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    &-image {
      img {
        width: 50px;
        position: relative;
        right: 0;
        top: 0;
      }
    }
  }

  &-text {
    width: 100%;
    margin: 0 0 20px 0;
    padding: 0;
    p {
      max-width: 100%;
      margin: 0 auto;
      text-align: left;
      font-size: 14px;
      padding: 0 25px;
      line-height: 20px;
      color: #747272;
      @media screen and (max-width: 768px) {
        br {
          display: none;
        }
      }
      @media screen and (max-width: 480px) {
        max-width: 100%;
        padding: 0 25px;
      }
      a {
        font-size: 20px;
        font-weight: bold;
        text-decoration: underline;
        text-decoration-color: #cccccc;
        text-underline-position: under;
        transition: .2s ease-in-out;
        &:hover {
          color: #1a73e9;
        }
      }
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    button {
      width: 50%;
      min-height: 50px;
      border-top: solid 1px #ececec;
      text-transform: none;
      font-size: 15px;
      transition: .2s ease-in-out;
      border-right: solid 1px #ececec;
      border-radius: 0 0 0 15px;
      @media screen and (max-width: 480px) {
        border-radius: 0;
      }
      &:hover {
        background-color: #f56b2b;
        color: #ffffff;
      }
    }
    a {
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      min-height: 50px;
      border-top: solid 1px #ececec;
      border-radius: 0 0 15px 0;
      text-transform: none;
      font-size: 15px;
      transition: .2s ease-in-out;
      &:hover {
        background-color: #f56b2b;
        color: #ffffff;
      }
      @media screen and (max-width: 480px) {
        border-radius: 0;
      }
    }
  }
}

.page-cookies {
  width: 100%;
  display: flex;
  padding: 0;
  margin: 70px 0;
  background-color: #ffffff;
  &-container {
    width: 100%;
    display: flex;
    max-width: 1000px;
    padding: 30px;
    border: solid 1px #eaebed;
    margin: 0 auto;
    border-radius: 10px;
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
}
