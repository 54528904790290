.signup {
  width: 100%;
  height: calc(100vh - 74px);
  padding: 100px 0;
  margin: 74px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;

  @media only screen and (max-width: 992px) {
    width: calc(100% - 30px);
    padding: 0 15px;
    height: calc(100vh - 70px);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }

  &-form {
    display: flex;
    flex-direction: column;
    width: 30rem;
    padding: 40px 40px 60px;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    @media only screen and (max-width: 768px) {
      border: none;
    }

    &.pro {
      width: 36rem;

      .half-form-wrapper {
        .errorMessage {
          bottom: -8px;
        }
      }

      @media screen and (max-width: 700px) {
        .signup-form-line {
          flex-direction: column;

          > * {
            margin-left: 0!important;
            margin-right: 0!important;
          }
        }
      }
    }

    &-title {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    &-subtitle {
      text-align: center;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }

    &.pro &-subtitle {
      text-align: left;
      margin-top: 20px;
      margin-bottom: 0;
      font-weight: normal;
    }

    a {
      color: #0073f1;
      margin: 1rem 0;

      &:hover {
        text-decoration: underline;
      }
    }

    .MuiCheckbox-colorPrimary.Mui-checked {
      color: #0073f1;
    }

    .MuiButton-label {
      color: #0073f1;
      text-transform: initial;
      font-family: 'Avenir', sans-serif;
      font-size: 1rem;
    }

    .MuiFormControlLabel-root {
      .MuiTypography-body1 {
        @media screen and (max-width: 480px) {
          font-size: .9rem;
        }
      }
    }

    .MuiOutlinedInput-input {
      padding: .65rem 1rem!important;
    }

    .select-category,
    .select-city,
    .select-district {
      .MuiInputLabel-formControl {
        top: -.7rem;
        left: .5rem;
        padding: 0 .5rem;
        background: #fff;
        z-index: 10;
        pointer-events: none;
      }
      .MuiSelect-select:focus {
        background: #fff;
      }
      .MuiInputLabel-shrink {
        transform: translate(0, 6px) scale(0.75);
      }
    }

    .MuiTextField-root {
      width: 100%;
      margin: 10px 0;

      .MuiFormLabel-root.Mui-focused {
        color: #0073f1!important;
      }

      .MuiOutlinedInput-root.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: #0073f1!important;
        }
      }

      &.is-invalid {
        .MuiFormLabel-root.Mui-focused {
          color: #ff4444 !important;
        }

        .MuiOutlinedInput-root.Mui-focused {
          .MuiOutlinedInput-notchedOutline {
            border-color: #ff4444!important;
          }
        }
      }
    }

    &-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 480px) {
        flex-direction: column;
      }
      .password-div {
        display: flex;
        align-items: center;
        margin-left: 0;
        position: relative;
        .MuiFormControl-root {
          margin-right: 0 !important;
          .MuiInputBase-input {
            padding-right: 45px !important;
          }
        }
        svg {
          font-size: 1.5rem;
        }
        .errorMessage {
          left: 0;
        }
      }

      &.mt {
        margin-top: 1rem;
      }

      &.first-line {
        margin: 0;

        .firstname {
          /*width: 16rem;*/
          margin: 10px 0;
        }

        .email {
          width: 100%;
          margin: 10px 0;
        }

        @media screen and (max-width: 480px) {
          flex-direction: column;

          .firstname,
          .email {
            width: 100%;
          }
        }
      }

      &.second-line {
        margin-top: 0;
        .MuiInputBase-input {
          padding-right: 45px !important;
        }
      }

      &.last-line {
        width: 100%;
        margin-top: 2rem;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 480px) {
          margin-top: 0;
        }
        .cgu {
          margin-top: 0;
        }
        .half-form-wrapper:first-child {
          @media screen and (max-width: 480px) {
            margin-bottom: 20px;
            text-align: center;
          }
        }
        .half-form-wrapper:last-child {
          display: flex;
          justify-content: flex-end;
          button {
            min-width: 205px;
            &:disabled {
              background-color: #1a73e9;
            }
            .MuiCircularProgress-root {
              width: 20px !important;
              height: 20px !important;
              color: #ffffff !important;
              margin-left: 10px;
            }
          }
          @media screen and (max-width: 480px) {
            justify-content: flex-start;
            button {
              width: 100%;
            }
          }
        }
      }
    }

    &.pro &-line {
      & > * {
        width: 100%;
      }

      .MuiFormControl-root {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }

    .phones {
      .tel-input {
        .errorMessage {
          bottom: -25px;
        }
        .whatsapp-input ~ .errorMessage {
          left: 60px;
        }
      }
    }

    .tel-input {
      margin-bottom: 20px;
      .phone-input,
      .whatsapp-input {
        &.is-invalid {
          input {
            border-color: #f44336;
          }
          .flag-dropdown:before {
            color: #f44336;
          }
        }
      }
      .errorMessage {
        bottom: -22px;
      }
    }

    .cgu {
      margin-top: 5px;
      .MuiFormControlLabel-root {
        .MuiTypography-root {
          @media screen and (max-width: 480px) {
            font-size: .8rem;
            line-height: 1rem;
          }
          label {
            font-size: 14px;
            a {
              text-decoration: underline;
            }
          }
        }
      }
      .MuiTypography-root {
        > label {
          position: relative;
          top: 3px;
        }
      }
      .errorMessage {
        left: 30px;
      }
    }

    &-button {
      background: #0073f1!important;

      span {
        color: #fff!important;
      }
    }

    .react-tel-input {
      margin: 20px 0 0 0;

      .special-label {
        display: none;
      }
      .form-control {
        padding: .55rem 1rem;
        margin-left: 3rem;
        width: calc(100% - 3rem);

        & ~ .flag-dropdown {
          &::before {
            left: 3.5rem;
            color: #6b6f74;
            transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
            content: attr(data-placeholder)!important;
            position: absolute;
            top: -7px;
            display: block;
            background: #fff;
            padding: 0 5px;
            font-size: 13px;
            white-space: nowrap;
          }

          .selected-flag {
            padding: 0;
          }
        }

        &:focus {
          & ~ .flag-dropdown {
            &::before {
              color: #0073f1;
            }
          }
        }
      }
      &.is-invalid {
        .form-control {
          &:focus {
            box-shadow: 0 0 0 1px #f4f4f4;
            & ~ .flag-dropdown {
              &::before {
                color: #ff4444;
              }
            }
          }
        }
      }
    }

    &.pro {
      .react-tel-input {
        margin-bottom: 0;

        /*&:first-child {
          margin-right: 1rem;
        }

        &:last-child {
          margin-left: 1rem;
        }*/
      }

      .last-line {
        & > * {
          &:first-child {
            .MuiTypography-body1 {
              font-size: .8rem;
            }
          }

          &:last-child {
            width: 16rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 560px) {
    padding: 4rem 0 0;

    &-form {
      width: 100%;
      padding: 3rem 1rem 4rem;
      border-radius: 0;
    }
  }

  .pro {
    @media screen and (max-width: 700px) {
      margin-top: -8rem;
      margin-bottom: -8rem;

      &.signup-form {
        width: 100%;
        padding: 3rem 1rem 4rem;
        border-radius: 0;

        .react-tel-input {
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
      }
    }

    @media screen and (max-width: 560px) {
      width: 100%;
      padding: 2rem 1rem 4rem;
      border-radius: 0;
      margin-top: unset;
      margin-bottom: unset;
    }
  }
}

.informations, .account {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

/*.half-form-wrapper {
  .errorMessage {
    bottom: -6px;
  }
}*/
