.my-saved-search {
  width: 100%;
  padding: 0 0 40px;
  margin: 0;
  @media screen and (max-width: 992px) {
    padding: 0;
    margin: 0 0 70px !important;
  }
  h1 {
    width: 100%;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 40px;
    text-align: Left;
    &:after {
      content: "";
      position: relative;
      display: block;
      bottom: -15px;
      width: 50px;
      height: 3px;
      background-color: #333333;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border: solid 1px #dadce0;
      border-radius: 8px;
      /*box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.25);*/
      padding: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      &-title {
        width: calc(100% - 30px);
        padding: 20px 15px;
        @media screen and (max-width: 992px) {
          width: calc(100% - 20px);
          padding: 15px 10px;
        }
        h4 {
          font-size: 18px;
          @media screen and (max-width: 768px) {
           font-size: 16px;
          }
        }
      }
      &-criteria {
        border-top: solid 1px #dadce0;
        align-items: center;
        padding: 15px 15px 5px 15px;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          li {
            list-style: none;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
      &-footer {
        width: calc(100% - 30px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-top: solid 1px #dadce0;
        align-items: center;
        padding: 15px;
        @media screen and (max-width: 992px) {
          width: 100%;
          padding: 10px 0;
          flex-wrap: wrap;
        }
        &-item {
          width: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          &.date {
            p {
              line-height: 22px;
            }
            @media screen and (max-width: 992px) {
              width: calc(50% - 10px);
              padding: 0 0 10px 10px;
              font-size: 14px;
            }
            @media screen and (max-width: 730px) {
              width: 100%;
              padding: 0 10px 10px 10px;
            }
          }
          &.actions {
            justify-content: space-between;
            @media screen and (max-width: 992px) {
              width: calc(50% - 10px);
              padding: 0 10px 10px 0;
            }
            @media screen and (max-width: 730px) {
              width: 100%;
              padding: 10px 10px 10px 10px;
              border-top: solid 1px #dadce0;
            }
            button {
              background-color: transparent;
              color: #000;
              padding: 8px 20px;
              margin-right: 40px;
              transition: .3s ease-in-out;
              svg {
                font-size: 20px;
                margin-right: 5px;
              }
              &:first-child {
                &:hover {
                  background-color: #05cd51;
                  color: #ffffff;
                }
              }
              &:last-child {
                margin-right: 0;
                &:hover {
                  background-color: #ff4444;
                  color: #ffffff;
                }
                svg {
                  font-size: 22px;
                  margin-right: 2px;
                }
              }
            }
          }
          &.search-button {
            justify-content: flex-end;
            @media screen and (max-width: 992px) {
              border-top: solid 1px #dadce0;
              padding: 10px 10px 0 10px;
              width: 100%;
            }
            button {
              display: block;
              background-color: #1a73e9;
              color: #ffffff;
              border: solid 1px #1a73e9;
              padding: 11px 20px;
              border-radius: 4px;
              transition: .3s ease-in-out;
              @media screen and (max-width: 992px) {
                width: 100%;
                text-align: center;
              }
              &:hover {
                background-color: #ffffff;
                color: #1a73e9;
              }
            }
          }
        }
      }
    }
  }
}
