.search {
  position: relative;
  padding: 74px 0 6rem;
  background: #f2f2f2;

  @media screen and (max-width: 1150px) {
    padding: 74px 0 6rem;
  }

  @media screen and (max-width: 992px) {
    padding: 70px 0 6rem;
  }

  .container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    @media screen and (max-width: 65rem) {
      padding: 0;
    }
    @media screen and (max-width: 992px) {
      padding: 0;
    }
    &-carousel {
      width: 100%;
      position: relative;
      padding: 0;
      margin-bottom: 20px;
      .slider-pause {
        position: absolute;
        bottom: 40px;
        right: 40px;
        padding: 0;
        margin: 0;
        display: flex;
        @media only screen and (max-width: 768px) {
          bottom: 20px;
          right: 20px;
        }
        button {
          width: 44px !important;
          min-width: 44px !important;
          height: 44px !important;
          min-height: 44px !important;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
          background-color: rgba(0, 0, 0, .5);
          border-radius: 50%;
          svg {
            color: #ffffff;
          }
        }
      }
      .slick-list,
      .slick-track,
      .slick-slide,
      .slick-slide img {
        width: 100%;
        @media screen and (min-width: 320px) and (max-width: 768px) {
          height: 220px;
        }
        @media screen and (min-width: 769px) and (max-width: 992px) {
          height: 280px;
        }
        @media screen and (min-width: 993px) and (max-width: 1023px) {
          height: 360px;
        }
        @media screen and (min-width: 1024px) and (max-width: 1499px) {
          height: 430px;
        }
        @media screen and (min-width: 1500px) and (max-width: 1999px) {
          height: 500px;
        }
        @media screen and (min-width: 2000px) and (max-width: 2499px) {
          height: 600px;
        }
        @media screen and (min-width: 2500px) and (max-width: 2999px) {
          height: 700px;
        }
        @media screen and (min-width: 3000px) and (max-width: 3499px) {
          height: 800px;
        }
        @media screen and (min-width: 3500px) and (max-width: 3999px) {
          height: 900px;
        }
        @media screen and (min-width: 4000px) and (max-width: 4499px) {
          height: 1000px;
        }
        @media screen and (min-width: 5000px) and (max-width: 80000px) {
          height: 1100px;
        }
      }
      .slick-slide img {
        object-fit: cover;
      }
    }
  }

  & > * {
    position: relative;
    z-index: 1;
  }

  &-filters {
    width: 100%;
    max-width: 65rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 0;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: .2s ease-in-out;
    padding: 0;

    @media screen and (max-width: 1150px) {
      width: calc(100% - 50px);
      padding: 25px 25px 0 25px;
      margin-bottom: 30px;
    }

    @media screen and (max-width: 992px) {
      display: none;
      width: calc(100% - 40px);
      padding: 0 20px;
      position: relative;
      transform: none;
      left: 0;
      top: 0;
      margin: -100px 0 30px 0;
    }

    &-inner {
      width: calc(100% - 40px);
      display: flex;
      padding: 20px 20px 25px 20px;
      flex-direction: column;
      background-color: rgba(0, 0, 0, .5);
      //box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
      border: none;
      border-radius: 8px;
      position: relative;

      &-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0 0 15px 0;
        font-family: 'Open sans', sans-serif;

        @media screen and (max-width: 992px) {
          padding: 0;
        }

        @media screen and (max-width: 768px) {
          flex-direction: row;
          margin-bottom: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.first {
          margin-bottom: 15px;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        &-button {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: 0;
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          @media only screen and (max-width: 768px) {
            width: 100%;
          }
          button {
            width: auto;
            margin: 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #ec6501 !important;
            color: #fff !important;
            padding: 10px 20px !important;
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
            overflow: hidden;
            font-size: 16px;
            line-height: 18px;
            border-radius: 8px;
            box-shadow: 0 5px 10px 1px rgba(100, 100, 100, .4);
            text-transform: none !important;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
            &.reset {
              background-color: #fff !important;
              color:#000 !important;
              margin-left: 20px;
              @media only screen and (max-width: 768px) {
                margin: 15px 0 0 0;
              }
              &:hover {
                color: #fff !important;
              }
            }
            .search-block {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            &:hover {
              background-color: darken(#ec6501, 10%) !important;
              box-shadow: 0 2px 4px 1px rgba(80, 80, 80, .5);
            }
            svg {
              font-size: 22px;
              margin-right: 8px;
            }
            .MuiCircularProgress-root {
              width: 20px !important;
              height: 20px !important;
              color: #ffffff !important;
              margin-left: 10px;
              svg {
                margin-right: 0;
              }
            }
          }
        }

        &-wrapper {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          @media only screen and(max-width: 768px) {
            width: 100%;
            flex-direction: column;
          }
          &-left,
          &-right {
            label {
              width: 100%;
              font-size: 15px;
              margin: 0 0 10px 0;
              text-align: left;
              display: block;
              color: #000000;
            }
            button {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              line-height: 22px;
              text-transform: none;
              font-size: 15px;
              color: #666666;
              padding: 0;
              min-height: 38px;
              border-radius: 8px;
              overflow: hidden;
              background-color: #ffffff;
              font-weight: normal;
              &:hover {
                background-color: #ffffff;
              }
              .icon-wrapper {
                width: 38px;
                height: 38px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: transparent;
                //margin-right: 10px;
                img {
                  width: 20px;
                }
                svg {
                  font-size: 20px;
                  color: #ec6501;
                  /*color: rgba(155, 155, 155, 1);*/
                }
              }
              span {
              }
            }
            .MuiFormControl-root.select-town {
              width: 100%;
              font-size: .9rem !important;

              &.Mui-focused {
                background-color: #ffffff !important;
                color: #1877d3 !important;
                .MuiOutlinedInput-notchedOutline {
                  border-color: #1877d3 !important;
                }
                .MuiInputLabel-outlined {
                  transform: translate(14px, 14px) scale(1);
                }
              }
            }
          }

          &-left {
            width: calc(33.33% - 10px);
            padding: 0 10px 0 0;
            @media only screen and (max-width: 768px) {
              width: 100%;
              padding: 0;
              margin-bottom: 15px;
            }
          }

          &-center {
            width: calc(33.33% - 10px);
            padding: 0 5px;
            position: relative;
            display: flex;
            flex-direction: column;

            @media only screen and (max-width: 768px) {
              width: 100%;
              padding: 0;
              margin-bottom: 15px;
            }

            .input-wrapper {
              display: flex;
              flex-direction: row;
              overflow: hidden;
              input {
                width: 100%;
                padding: 0 10px;
                min-height: 38px;
                border: solid 1px #dbdce0;
                border-radius: 8px;
                background-color: #ffffff;
                color: #666666;
                font-size: 15px;
                text-align: left;
                outline: none;
                &::placeholder {
                  color: #666666;
                }
              }
            }
            label {
              width: 100%;
              color: #000000;
              text-align: left;
              font-size: 16px;
              margin: 0 0 10px 0;
            }
          }

          &-right {
            width: calc(33.33% - 10px);
            padding: 0 0 0 10px;
            @media only screen and (max-width: 768px) {
              width: 100%;
              padding: 0;
              margin-bottom: 15px;
            }
          }
        }

        &-filters {
          width: 100%;
          display: flex;
          flex-direction: column;
          &-title {
            width: 100%;
            display: flex;
            font-size: 15px;
            flex-direction: column;
            margin: 0 0 15px 0;
            h4 {
              font-size: 16px;
              color: #ffffff;
              font-family: 'Open sans', sans-serif;
              position: relative;
              &:after {
                content: "";
                margin-left: 55px;
                width: calc(100% - 55px);
                background-color: #ffffff;
                display: inline-block;
                height: 1px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                vertical-align: middle;
              }
            }
          }
          &-items {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            &-item {
              margin: 0 15px 15px 0;
              &:last-child {
                margin-right: 0;
              }
              button {
                background-color: #ffffff;
                box-shadow: none;
                color: #6f7072;
                border-radius: 8px;
                //border: solid 1px #dbdce0;
                font-family: 'Open sans', sans-serif;
                transition: .3s ease-in-out;
                &:hover {
                  background-color: #ffffff;
                  box-shadow: none;
                }
                &.model-button {
                  cursor: not-allowed;
                  pointer-events: none;
                  background-color: rgba(220, 220, 220, 1);
                  color: #9b9b9b;
                }
                &.enabled {
                  cursor: initial;
                  pointer-events: all;
                  color: #6f7072;
                  background-color: #ffffff;
                }
                &.active {
                  background-color: #ec6501 !important;
                  color: #ffffff !important;
                }
              }
            }
            .items-wrapper {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              margin-right: 15px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
      }

      .last-row {
        margin: 5px 0 0 0;
        flex-direction: row;
        @media screen and (max-width: 1150px) {
          margin-top: 15px;
        }
        @media screen and (max-width: 768px) {
          flex-direction: row;
          margin: 0 0 10px 0;
        }
        @media screen and (max-width: 480px) {
          flex-direction: column;
        }

        &-save {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          @media screen and (max-width: 480px) {
            width: 100%;
            justify-content: flex-start;
          }
          &-inner {
            width: auto;
            display: inline-flex;
          }
          ul {
            position: relative;
            display: flex;
            flex-direction: row;
            cursor: pointer;
            li {
              display: flex;
              flex-direction: row;
              align-items: center;
              font-size: 13px;
              -webkit-transition: .2s ease-in-out;
              -moz-transition: .2s ease-in-out;
              transition: .2s ease-in-out;
              &:first-child {
                margin-right: 15px;
              }
              &:last-child {
                padding-left: 30px;
              }
              &.outline {
                visibility: visible;
                opacity: 1;
                pointer-events: visible;
                position: absolute;
                top: -5px;
                left: 0;
              }
              &.colored {
                visibility: hidden;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                top: -5px;
                left: 0;
              }
              svg {
                font-size: 22px;
              }
            }
          }
          &.active {
            ul {
              li {
                &:first-child {
                  margin-right: 15px;
                }
                /*&:last-child {
                  color: #ec6501;
                }*/
                &.outline {
                  visibility: hidden;
                  opacity: 0;
                  pointer-events: none;
                  position: absolute;
                  top: -5px;
                  left: 0;
                }
                &.colored {
                  color: #ec6501;
                  visibility: visible;
                  opacity: 1;
                  pointer-events: visible;
                  position: absolute;
                  top: -5px;
                  left: 0;
                }
              }
            }
          }
        }

        &-switch {
          width: 50%;
          @media screen and (max-width: 480px) {
            width: 100%;
            margin-bottom: 15px !important;
          }
          &-inner {
            width: auto;
            display: inline-flex;
            .MuiFormControlLabel-root {
              margin-left: -10px;
              margin-right: 0;
              .MuiButtonBase-root {
                &.Mui-checked {
                  color: #ec6501;
                }
              }
              .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                background-color: darken(#ec6501, 7%);
              }
              .MuiTypography-root {
                font-size: 13px;
                font-family: 'AvenirLight', sans-serif;
              }
            }
          }
        }
      }
    }
  }
}

.mobile-filter {
  display: none;
  @media only screen and (max-width: 992px) {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #fff;
    z-index: 1302;
    transition: height .3s ease-in-out;
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1301;
      border: solid 1px #666666;
      border-radius: 50%;
      outline: none;
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      -webkit-transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
      -moz-transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
      transition: opacity .5s ease-in-out, visibility .5s ease-in-out;
      svg {
        font-size: 28px;
      }
    }
    &.open {
      width: 100%;
      height: 100%;
      .close-button {
        visibility: visible;
        opacity: 1;
        pointer-events: all;
      }
      .search-filters {
        padding: 65px 0 0 0;
        height: calc(100% - 65px);
      }
    }
    .search-filters {
      width: 100%;
      height: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      box-shadow: none;
      overflow-y: auto;
      margin: 0;
      &-inner {
        background-color: transparent;
        border: 0;
        &-row {
          &-wrapper {
            &-left {
              button {
                border: solid 1px #dbdce0;
              }
            }
            &-right {
              button {
                border: solid 1px #dbdce0;
              }
            }
          }
          &-filters {
            &-title {
              h4 {
                font-size: 18px;
                color: #000;
              }
            }
            &-items {
              .items-wrapper {
                margin-right: 0;
              }
              &-item {
                margin: 0 10px 10px 0;

                button {
                  padding: 5px 12px;
                  background-color: #ddd;
                }
              }
            }
          }
        }
        &-row-button {
          bottom: 10px;
          position: relative;
          width: 100%;
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
          button {
            width: auto;
            min-width: 200px;
            @media only screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.search-wrapper {
  .footer {
    &-categories {
      display: flex;
    }
  }
}

.filterContent {
  @media only screen and (max-width: 992px) {
    z-index: 1303 !important;
    background-color: rgba(0, 0, 0, .5);
  }
  &.color {
    .MuiList-root {
      background-color: red;
    }
  }
  .MuiPaper-root.MuiPopover-paper {
    padding: 0;
    margin-top: 5px;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    @media only screen and (max-width: 992px) {
      box-shadow: none;
    }
    .popper-title {
      font-size: 15px;
      margin: 15px 0 0 0;
      padding: 10px 20px;
      font-family: 'Open sans', sans-serif;
      font-weight: 700;
    }
    .price {
      min-width: 300px;
      display: flex;
      padding: 0 20px;
      flex-direction: row;
      .price-min {
        width: 50%;
        padding: 0 10px 0 0;
        .MuiFormControl-root {
          width: 100%;
        }
      }
      .price-max {
        width: 50%;
        padding: 0 0 0 10px;
        .MuiFormControl-root {
          width: 100%;
        }
      }
    }
    .brand,
    .model,
    .fuel,
    .year,
    .mileage,
    .gearbox,
    .doors,
    .area,
    .rooms,
    .color,
    .place,
    .state,
    .storage,
    .memory {
      min-width: 300px;
      padding: 0 20px;
      .MuiFormControl-root {
        width: 100%;
        margin: 15px 0 0 0;
      }
      .MuiFormGroup-root {
        display: flex;
        flex-direction: row;
        .MuiFormControlLabel-root {
          width: 50%;
          padding: 0;
          margin: 0;
          .MuiRadio-colorSecondary.Mui-checked {
            color: #ec6501;
          }
        }
      }
      .MuiSlider-root {
        width: 94%;
        margin: 0 auto;
        display: flex;
        color: #f56b2b;
        .MuiSlider-rail {
          height: 4px;
          background-color: #f56b2b;
        }
        .MuiSlider-track {
          height: 4px;
          background-color: #f56b2b;
        }
        .MuiSlider-mark {
          width: 0;
          height: 4px;
        }
        .MuiSlider-markLabel{
          top: 30px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.54);
          @media screen and (max-width: 768px) {
            top: 35px;
          }
        }
        .MuiSlider-markLabel[data-index="0"] {
          transform: translate(-30%);
          left: 0;
        }
        .MuiSlider-markLabel[data-index="1"] {
          right: -10px;
          left: unset !important;
          transform: none;
        }
        .MuiSlider-thumb {
          width: 20px;
          height: 20px;
          margin-top: -9px;
          background-color: #fff;
          border: solid 1px #cad1d9;
          .MuiSlider-valueLabel {
            left: calc(-50% - 0px);
          }
        }
      }
    }
    .color {
      padding: 0;
      .select-color {
        margin: 0;
        .color-list {
          width: 100%;
          padding: 15px 0;
          max-height: 300px;
          overflow: auto;
          .MuiListItem-container {
            padding: 0;
            .MuiButtonBase-root {
              padding: 8px 20px;
              display: flex;
              flex-direction: row;
              align-items: center;
              span {
                width: auto;
                height: auto;
                margin-right: unset;
              }
              .MuiListItemIcon-root {
                min-width: 30px;
                span {
                  display: block;
                  width: 16px;
                  height: 16px;
                }
              }
            }
            .MuiListItemSecondaryAction-root {
              padding-right: 10px;
              .MuiButtonBase-root {
                padding: 8px;
              }
              .MuiIconButton-label {
                height: 24px;
              }
            }
          }
        }
      }

    }
    .mileage,
    .rooms {
      padding-bottom: 15px;
    }
    /* .gearbox {
       .MuiFormControl-root {
         display: flex;
         flex-direction: row;
         .MuiFormControlLabel-root {
           width: 50%;
         }
       }
     }*/
    .popper-footer {
      width: 100%;
      border-top: solid 1px #dbdce0;
      padding: 0;
      margin: 25px 0 0 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        width: 50%;
        padding: 10px;
        border-radius: 0;
        &:nth-child(1) {
          border-right: solid 1px #dbdce0;
          background-color: #f0f0f0;
          color: #000000;
        }
        &:nth-child(2) {
          color: #ffffff;
          background-color: #1a73e9;
        }
      }
    }
  }
}

#color-popover {
  .MuiPaper-root {
    .popper-footer {
      margin-top: 0;
    }
  }
}

