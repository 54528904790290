.dashboard {
  width: 100%;
  min-height: calc(100vh - 80px);
  margin: 74px 0 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 0;
  left: 0;
  background-color: #fff;
  @media only screen and (max-width: 992px) {
    margin-top: 80px;
  }
  &-sidebar {
    width: 280px;
    height: 100vh;
    overflow: auto;
    padding: 0 0 30px 0;
    margin: 0;
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 74px;
    @media only screen and (max-width: 1200px) {
      width: 250px;
    }
    @media only screen and (max-width: 992px) {
      display: none;
    }
    &-menu {
      width: 100%;
      padding: 30px 0;
      @media only screen and (max-width: 1200px) {
        padding: 25px 0 30px 0;
      }
      ul {
        width: 100%;
        padding: 0;
        margin: 0;
        li {
          width: 100%;
          @media only screen and(max-width: 992px) {
            a {
              border-bottom: solid 1px #dadce0;
            }
            &:first-child {
              a {
                border-top: solid 1px #dadce0;
              }
            }
          }
          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 0;
            color: #3c4043;
            border-radius: 0 50px 50px 0;
            position: relative;
            @media only screen and (max-width: 992px) {
              width: 50px;
              padding: 14px 0;
              border-radius: 0;
            }
            @media only screen and (max-width: 480px) {
              padding: 10px 0;
              width: 40px;
            }
            &.active {
              color: #1a73e9;
              background-color: #e8f0fe;
              /*span {
                border-right: solid 4px #1d77d0;
              }*/
            }
            svg {
              font-size: 28px;
              margin: 0 12px 0 16px;
              &.message,
              &.profile,
              &.favorite,
              &.create,
              &.search-saved {
                font-size: 22px;
              }
              @media only screen and (max-width: 992px) {
                margin: 0 14px;
              }
              @media only screen and (max-width: 480px) {
                margin: 0 10px;
              }
            }
            span {
              display: block;
              width: 100%;
              height: 100%;
              font-size: 15px;
              padding: 14px 20px 14px 0;
              border-right: solid 5px transparent;
              @media only screen and (max-width: 992px) {
                display: none;
              }
            }
            .badge {
              width: auto;
              min-width: 10px;
              height: 20px;
              padding: 0 6px;
              border-radius: 10px;
              display: flex;
              flex-direction: row;
              align-content: center;
              justify-content: center;
              position: absolute;
              flex-wrap: wrap;
              font-size: 0.75rem;
              top: 50%;
              right: 20px;
              border: none;
              background-color: #ec6501;
              transform: scale(1) translate(50%, 0%) translateY(-50%);
              transform-origin: 100% 0%;
              color: #fff;
              transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              font-weight: 500;
              line-height: 1;
            }
          }
          &:hover {
            a {
              background-color: rgba(0, 0, 0, 0.039);
            }
          }
        }
      }
    }
  }
  &-content {
    width: calc(100% - 395px);
    padding: 40px 50px;
    margin: 0;
    position: relative;
    left: 285px;
    background-color: #ffffff;
    @media only screen and (max-width: 1200px) {
      width: calc(100% - 300px);
      left: 250px;
      padding: 20px;
    }
    @media only screen and (max-width: 992px) {
      width: calc(100% - 40px);
      left: 0;
    }
    @media only screen and (max-width: 480px) {
      width: calc(100% - 30px);
      padding: 15px;
    }
    > div {
      max-width: 1300px;
      margin: 0 auto;
    }
  }
  .MuiSpeedDial-root {
    display: none;
    @media only screen and (max-width: 992px) {
      display: flex;
      position: fixed;
      bottom: 15px;
      right: 15px;
    }
    .MuiButtonBase-root.MuiSpeedDial-fab {
      background-color: #f56b2b;
      svg {
        font-size: 26px;
      }
    }
    .MuiSpeedDial-actions {
      .MuiSpeedDialAction-staticTooltip {
        .MuiSpeedDialAction-staticTooltipLabel {
          width: max-content;
          display: inline-flex;
          margin: 0 auto;
          color: #000000;
          background-color: #f2f2f2;
        }
        .MuiButtonBase-root {
          color: #000000;
          background-color: #f2f2f2;
          .MuiFab-label {
            a {
              display: flex;
              justify-content: center;
              align-items: center;
              svg {
                font-size: 22px;
                &.message,
                &.profile,
                &.favorite,
                &.create,
                &.search-saved {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}
