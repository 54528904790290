.number-validation {
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 74px);
  overflow: auto;
  @media only screen and (max-width: 992px) {
    height: calc(100vh - 70px);
  }
  &-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22.5rem;
    //min-height: 323px;
    padding: 45px 10px;
    margin: 0;
    border: 1px solid #dadce0;
    background: #fff;
    border-radius: 8px;
    @media only screen and (max-width: 768px) {
      padding: 40px 20px;
      border: none;
    }
    h1 {
      font-size: 18px;
      text-align: center;
    }
    p {
      max-width: 380px;
      font-size: 14px;
      margin-top: 15px;
      line-height: 20px;
      text-align: center;
      font-weight: normal;
      padding: 0 15px;
      span {
        display: block;
        margin-top: 5px;
        &.count {
          color: #1a56af;
        }
      }
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 12px 0 0 0;
      .form-inner {
        width: auto;
        display: inline-flex;
        margin: 0 auto;
        padding: 0 20px;
        flex-direction: row;
        justify-content: center;
        border-bottom: solid 3px #1a73e9;
        .form-inner-item {
          width: auto;
          height: auto;
          position: relative;
          display: inline-flex;
          //border: solid 1px transparent;
          &.field {
            &:before {
              display: none;
            }
          }
          &:before {
            content: "";
            width: 14px;
            height: 1px;
            display: block;
            background-color: #000;
            position: absolute;
            top: calc(50% - 2px);
            left: 50%;
            transform: translate(-50%, -50%);
          }
          input {
            //border: solid 1px red;
            width: 28px;
            min-width: 28px;
            height: 28px;
            min-height: 28px;
            font-size: 18px;
            text-align: center;
            outline: none;
            background-color: #fff;
            color: #1a73e9;
            padding: 0;
            border-radius: 0;
            margin: 0 0 4px 0;
            border: none;
          }
        }
      }
      .form-instruction {
        width: 100%;
        margin: 10px 0 25px 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .resend-code {
          color: #1a56af;
          cursor: pointer;
          text-decoration: underline;
        }
        p {
          font-size: 13px;
          color: #b7b7b7;
          line-height: 18px;
          margin-bottom: 8px;
          padding: 0 15px;
          &:last-child {
            margin: 0;
          }
          span {
            display: unset;
          }
        }
      }
      .validation-button {
        background-color: #1a73e9;
        color: #ffffff;
        border-radius: 30px;
        padding: 10px 25px;
        border: solid 1px #1a73e9;
        transition: .3s ease-in-out;
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
        &:hover {
          background-color: #ffffff;
          color: #1a73e9;
        }
      }
    }
  }
}