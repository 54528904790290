.chat-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto 70px auto;
  padding: 0;
  h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    color: #000000;
    &:after {
      content: "";
      position: relative;
      display: block;
      bottom: -15px;
      width: 50px;
      height: 3px;
      background-color: #333333;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 5px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
    .MuiBadge-root {
      margin-left: 18px;
      .MuiBadge-badge {
        height: 22px;
        background-color: #eceef0;
        color: #000000;
        font-size: 14px;
        border: solid 1px #dadce0;
        padding: 10px;
      }
    }
  }
  &-inner {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
    &-container {
      width: 100%;
      height: 600px;
      display: flex;
      flex-direction: row;
      border-radius: 4px;
      overflow: hidden;
      border: solid 1px #dadce0;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        height: auto;
        margin-bottom: 90px;
      }
      &-menu {
        display: flex;
        width: 350px;
        height: 100%;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        border: none;
        @media screen and (max-width: 992px) {
          flex-direction: row;
          width: 100%;
          height: auto;
          overflow-x: scroll;
          overflow-y: hidden;
          border-bottom: solid 1px #dadce0;
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
          //border-bottom: solid 1px #dadce0;
          &::-webkit-scrollbar {
            display: none;
          }
        }
        &-search {
          width: calc(100% - 20px);
          margin: 0;
          padding: 10px;
          display: flex;
          flex-direction: row;
          @media screen and (max-width: 992px) {
            display: none;
          }
          &-form {
            width: 100%;
            padding: 0;
            form {
              width: 100%;
              background-color: transparent;
              display: flex;
              flex-direction: row;
              input {
                width: calc(100% - 50px);
                padding: 10px 15px;
                font-size: 15px;
                color: #000000;
                background-color: #ffffff;
                border: solid 1px #dadce0;
                border-radius: 4px 0 0 4px;
                outline: none;
                &:focus {
                  border-color: #1a73e9;
                }
                &::placeholder {
                  color: #000000;
                }
              }
              button {
                min-width: 50px;
                height: 39px;
                color: #000000;
                background-color: #ffffff;
                border: solid 1px #dadce0;
                border-left: none;
                border-radius: 0 4px 4px 0;
                position: relative;
                top: 0;
                transition: .3s ease-in-out;
                &:hover {
                  background-color: #1a73e9;
                  color: #ffffff;
                }
              }
            }
          }
        }
        &-item {
          width: calc(100% - 20px);
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin: 0;
          cursor: pointer;
          padding: 10px;
          border-top: solid 1px #dadce0;
          /*border-left: solid 1px #dadce0;*/
          border-right: solid 4px transparent;
          transition: .3s ease-in-out;
          position: relative;
          background-color: #ffffff;
          @media screen and (max-width: 992px) {
            width: auto;
            min-width: 300px;
            margin-right: 10px;
            padding-right: 40px;
            border-right: solid 1px #dadce0;
            border-top: none;
            border-bottom: none;
          }
          @media screen and (max-width: 992px) {
            min-width: 280px;
          }
          &:before {
            content: "";
            display: block;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            right: -4px;
            width: 4px;
            background-color: #1a73e9;
            height: 100%;
            transition: .3s ease-in-out;
            @media screen and (max-width: 992px) {
              width: 100%;
              height: 4px;
              right: 0;
              top: unset;
              bottom: 0;
            }
          }
          &:first-child {
            border-top: none;
          }
          &:hover,
          &.active {
            &:before {
              visibility: visible;
              opacity: 1;
            }
            background-color: #e8f0fe;
          }
          &-image {
            width: 60px;
            height: 60px;
            padding: 0;
            margin-right: 15px;
            border-radius: 0;
            overflow: hidden;
            @media screen and (max-width: 992px) {
              min-width: 60px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 0;
            }
          }
          &-name {
            width: calc(100% - 108px);
            @media screen and (max-width: 992px) {
              width: auto;
            }
            h4 {
              font-size: 16px;
              line-height: 18px;
              margin: 0 0 7px 0;
            }
            p {
              color: #6f6f6f;
              font-size: 13px;
              margin: 0 0 5px 0;
              &:last-child {
                margin-bottom: 0;
              }
            }
            .delete-discuss-message {
              width: 100%;
              border-top: solid 1px #ddd;
              margin: 7px 0 0 0;
              padding-top: 7px;
              font-style: italic;
              color: red;
              font-size: 11px;
              line-height: 12px;
            }
          }
          &-delete {
            position: absolute;
            top: 5px;
            right: 5px;
            background-color: #fff;
            border-radius: 50%;
            .MuiButtonBase-root {
              padding: 6px;
              transition: .3s ease-in-out;
              &:hover {
                background-color: #ffffff;
                .MuiSvgIcon-root {
                  color: #ff4444;
                }
              }
              .MuiSvgIcon-root {
                font-size: 17px;
                color: #000000;
                transition: .3s ease-in-out;
              }
            }
          }
          &-badge {
            display: flex;
            padding: 5px;
            position: absolute;
            bottom: 5px;
            right: 5px;
            background-color: #fff;
            border-radius: 50%;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            @media only screen and (max-width: 992px) {
              bottom: 8px;
            }
            span {
              width: auto;
              min-width: 8px;
              height: 20px;
              padding: 0 6px;
              border-radius: 10px;
              display: flex;
              flex-direction: row;
              align-content: center;
              justify-content: center;
              flex-wrap: wrap;
              font-size: 0.75rem;
              border: none;
              background-color: #ec6501;
              color: #fff;
              transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
              font-weight: 500;
              line-height: 1;
            }
          }
        }
        .chat-wrapper-inner-menu-item:first-child {
          border-radius: 8px 0 0 0;
          @media screen and (max-width: 992px) {
            border-radius: 0;
          }
        }
        .chat-wrapper-inner-menu-item:last-child {
          border-radius: 0 0 0 8px;
          @media screen and (max-width: 992px) {
            border-radius: 0;
          }
        }
      }
      &-content {
        width: calc(100% - 350px);
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-top: 0;
        border-left: solid 1px #dadce0;
        border-radius: 0;
        overflow: hidden;
        @media screen and (max-width: 992px) {
          width: 100%;
          border-radius: 0;
        }
        @media screen and (max-width: 768px) {
          height: 450px;
        }
        &-user {
          width: calc(100% - 20px);
          padding: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          border-bottom: solid 1px #dadce0;
          background-color: #ffffff;
          position: relative;
          @media screen and (max-width: 992px) {
            width: calc(100% - 21px);
          }
          &-image {
            width: 50px;
            height: 50px;
            border: solid 3px #1a73e9;
            border-radius: 50%;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }
          &-infos {
            display: flex;
            ul {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              li {
                font-size: 14px;
                margin-bottom: 8px;
                &:first-child {
                  font-size: 17px;
                }
                &:last-child {
                  margin-bottom: 0;
                  color: #92959E;
                }
              }
            }
          }
          &-menu {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            .MuiButtonBase-root {
              padding: 8px;
              .MuiSvgIcon-root {
                color: #000000;
              }
            }
          }
        }
        &-messages {
          height: calc(100% - 50px);
          overflow: auto;
          padding: 20px 30px 30px 30px;
          background-color: #ffffff;
          @media only screen and (max-width: 992px) {
            max-height: 400px;
          }
          @media only screen and (max-width: 768px) {
            max-height: 350px;
            padding: 20px;
          }
          @media only screen and (max-width: 480px) {
            padding: 15px;
          }
          /*background-color: #F2F5F8;*/
          /*background-color: #f2f2f2;*/
          .load-more-button {
            width: 100%;
            display: flex;
            position: sticky;
            position: -webkit-sticky;
            top: -10px;
            z-index: 10;
            @media only screen and (max-width: 768px) {
              top: -15px;
            }
            @media only screen and (max-width: 480px) {
              top: -10px;
            }
            justify-content: center;
            button {
              font-size: 13px;
              &:hover {
                background-color: #E5E5EA;
              }
              svg {
                font-size: 15px;
                margin-right: 5px;
              }
            }
          }
          &-item {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            &:nth-child(1) {
              margin-top: 10px;
            }
            &:last-child {
              margin-bottom: 0;
            }
            &-inner {
              width: 100%;
              max-width: 400px;
              &-name {
                width: 100%;
                position: relative;
                margin-bottom: 15px;
                ul {
                  display: flex;
                  flex-direction: row;
                  li {
                    color: #000;
                    font-size: 14px;
                    font-weight: normal;
                    &:last-child {
                      color: #a8aab1;
                      padding-left: 6px;
                    }
                  }
                }
              }
              &-message {
                width: auto;
                max-width: 100%;
                display: inline-flex;
                background: #E5E5EA;
                padding: 8px 12px;
                line-height: 20px;
                font-size: 15px;
                font-weight: normal;
                border-radius: 7px;
                margin: 0;
                position: relative;
                color: #000000;
                &:after {
                  bottom: calc(100% - 1px);
                  left: 18px;
                  content: " ";
                  height: 0;
                  width: 0;
                  position: absolute;
                  pointer-events: none;
                  border: 10px solid transparent;
                  border-bottom-color: #E5E5EA;
                  margin-left: -10px;
                }
              }
            }
            &.left {

            }
            &.right {
              align-items: flex-end;
              .chat-wrapper-inner-container-content-messages-item-inner {
                text-align: right;
              }
              .chat-wrapper-inner-container-content-messages-item-inner-name ul {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-start;
              }
              .chat-wrapper-inner-container-content-messages-item-inner-name ul li:last-child {
                padding-right: 6px;
              }
              .chat-wrapper-inner-container-content-messages-item-inner-message {
                background-color: #1a73e9;
                color: #ffffff;
                text-align: left;
                min-width: 14px;
              }
              .chat-wrapper-inner-container-content-messages-item-inner-message:after {
                border-bottom-color: #1a73e9;
                right: 10px;
                left: unset;
              }
            }
          }
        }
        &-poster {
          width: calc(100% - 20px);
          height: 50px;
          border-top: solid 1px #dadce0;
          background-color: #ffffff;
          padding: 10px;
          display: flex;
          flex-direction: row;
          @media screen and (max-width: 768px) {
            padding: 0;
            width: 100%;
          }
          textarea {
            border: solid 1px #dadce0;
            width: calc(100% - 70px);
            height: calc(100% - 20px);
            padding: 10px;
            font-size: 15px;
            resize: none;
            border-radius: 4px 0 0 4px;
            background-color: #ffffff;
            font-family: inherit;
            @media screen and (max-width: 768px) {
              border: none;
            }
            &:focus {
              outline: none;
            }
          }
          button {
            width: 40px;
            height: 52px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: solid 1px #1a73e9;
            background-color: #1a73e9;
            color: #ffffff;
            border-radius: 0 4px 4px 0;
            transition: .3s ease-in-out;
            @media screen and (max-width: 768px) {
              height: 50px;
              border-radius: 0;
            }
            &:hover {
              color: #ffffff;
              border-color: darken(#1a73e9, 20%);
              background-color: darken(#1a73e9, 20%);
            }
            svg {
              font-size: 30px;
            }
          }
        }
      }
    }
    .no-chat {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      background-color: transparent;
      font-size: 18px;
      font-weight: 500;
    }
  }
}
