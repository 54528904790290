@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

html {
	width: 100vw;
	overflow: unset !important;
}

#root {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

body {
	margin: 0;
	min-height: 100vh;
	font-family: 'AvenirThin', 'Roboto', sans-serif;
	width: 100%;
	overflow-x: hidden;
	overflow-y: unset !important;

	&.no-scroll {
		overflow-y: hidden;
	}
}

a {
	color: inherit;
	text-decoration: none;
	font-weight: 400;
}

.react-parallax {
	position: absolute!important;
	overflow: visible!important;
	width: 100vw;
	height: 110%;
	top: 0;
	left: 0;

	&-background-children {
		width: 100%;
		height: 100%;
	}
}

.hover-underline:hover {
	text-decoration: underline;
}

.bold {
	font-weight: 600;
}

.semi {
	font-weight: 500;
}

.italic {
	font-style: italic;
}

.underlined {
	text-decoration: underline;
}

.dotted {
	display: flex;
	align-items: center;

	&::before {
		content: '•';
		margin-right: .5rem;
		margin-left: .25rem;
	}
}

.squared {
	display: flex;
	align-items: center;

	&::before {
		content: '⬝';
		margin-right: .5rem;
		margin-left: .25rem;
	}
}

.button {
	display: inline-block;
	padding: .75rem 1.25rem;

	&--rounded {
		border-radius: 99999rem;
	}
}

.spacer {
	position: relative;
	background: #f4f4f4;
	display: block;
	height: 3rem;
	width: 100%;
}

.upper {
	text-transform: uppercase;
}

.no-mobile {
	@media screen and (max-width: 880px) {
		display: none;
	}
}

.mobile {
	display: none;

	@media screen and (max-width: 880px) {
		display: inline-block;
	}
}

.btn {
	border-radius: .2rem;
	color: #fff;
	background: #f56b2b;
	padding: .5rem;
	font-weight: 600;

	&--large {
		padding: .7rem .5rem;
	}
}

.flag-dropdown {
	.country-list {
		max-height: 26rem;
		width: 275px;

		.search {
			width: 100%;
			padding: .5rem .25rem;

			&::before {
				display: none;
			}
		}
	}
}
