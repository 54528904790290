@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap');
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");

.MuiButton-root {
  text-transform: none !important;
  font-weight: 400 !important;
  /*font-family: 'AvenirMedium', sans-serif !important;*/
}

.page {
  &-content {
    width: 100%;
    display: flex;
    margin: 70px 0;
    padding: 50px 0;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 400px;
    @media screen and (max-width: 992px) {
      margin: 80px 0;
    }
  }
  &-container {
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    padding: 0;
  }
}

.MuiInputBase-root .MuiOutlinedInput-input {
  padding: 11px 32px 11px 10px !important;
}

.MuiLink-underlineHover:hover {
  text-decoration: none !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: #fff;
  padding: 0 5px 0 0;
}

.MuiFormControl-root.is-invalid .MuiTextField-root .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #0073f1 !important
}

.MuiFormControl-root.is-invalid .MuiFormLabel-root {
  color: #ff4444;
}

.is-invalid {
  .search {
    .search-box {
      border-color: #cacaca !important;
    }
  }
}

.MuiFormControl-root.is-invalid label {
  color: #f44336;
}

/* Nested menu style */

.nested-menu {
  position: relative;
  @media only screen and (max-width: 992px) {
    z-index: 1302 !important;
  }
  .closeButton {
    position: absolute;
    top: -50px;
    right: -18px;
    color: #ffffff;
    font-size: 30px;
    @media only screen and(max-width: 768px) {
      top: -47px;
      right: -8px;
    }
  }
  .MuiPaper-rounded {
    background-color: transparent;
    overflow: visible;
    box-shadow: none;
    .nested-menu-container {
      padding: 0;
      width: 360px;
      max-height: 435px;
      //overflow: auto;
      background-color: #ffffff;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.375);
      border-radius: .1rem;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 992px) {
        max-width: calc(100% - 20px);
        height: auto;
        max-height: 100%;
        margin: auto;
      }
      .MuiDialogTitle-root {
        height: 25px;
        padding: 15px 20px !important;
        @media only screen and (max-width: 480px) {
          height: auto;
          padding: 12px 15px !important;
        }
        .MuiTypography-root {
          font-size: 18px;
          @media only screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
      .MuiDialogContent-root {
        padding: 0;
        height: calc(100% - 116px);
        border-top: 1px solid #eaebed;
        border-bottom: 1px solid #eaebed;
        overflow-y: auto;
        .MuiList-root {
          padding: 0;
          .MuiListItem-root {
            padding: 5px;
            &.nested-menu-first-item {
              display: flex;
              padding: .5rem 8px .5rem 20px;
              flex-direction: row;
              span {
                font-size: 16px;
              }
              Button {
                min-width: 32px;
                min-height: 32px;
                display: flex;
                justify-content: center;
                padding: 0;
                margin-right: 8px;
                border: solid 1px #cccccc;
                svg {
                  font-size: 22px;
                  color: #666666;
                }
              }
            }
            &.nested-menu-item {
              display: flex;
              padding: .6rem 15px .6rem 20px;
              margin: 0;
              justify-content: space-between;
              //border-top: solid 1px rgba(25, 25, 25, 0.2);
              transition: .3s ease-in-out;
              cursor: pointer;
              color: #545454;

              .element-wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                img {
                  width: 24px;
                  margin-right: 10px;
                  //filter: grayscale(100%);
                }
                span {
                  font-size: 16px;
                  line-height: 20px;
                  color: #000000DE;
                  transition: .2s ease-in-out;
                  -moz-transition: .2s ease-in-out;
                  -webkit-transition: .2s ease-in-out;
                }
              }

              &:hover,
              &.active {
                color: #000000;
                background-color: #f5f5f5;
                span {
                  color: #ec6501;
                }
              }

              span {
                line-height: 22px;
              }

              svg {
                font-size: 22px;
              }
            }
            .MuiFormGroup-root {
              width: 100%;
              .MuiFormControlLabel-root {
                width: 100%;
                margin-left: 0;
                justify-content: space-between;
                .MuiRadio-colorSecondary {
                  color: #f56b2b;
                }
              }
            }
          }
        }
      }
      .MuiDialogActions-root {
        padding: 12px 8px 8px 8px;
        height: 34px;
        .nested-menu-confirmation-button {
          width: 100%;
          Button {
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #1a73e9;
            color: #ffffff;
            text-transform: none;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}

.MuiPopover-root {
  @media only screen and (max-width: 992px) {
    z-index: 1303 !important;
  }
}

/* Custom arrow button */

.custom-arrow-button {
  width: 100%;
  height: 40px !important;
  display: flex !important;
  justify-content: space-between !important;
  border: solid 1px #dadce0 !important;
  color: rgba(0, 0, 0, 0.54) !important;
  border-radius: 4px !important;
  text-transform: none !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  padding: 10px 5px 10px 10px !important;
  box-shadow: none !important;
  font-family: 'Roboto', sans-serif;
  &:hover {
    background-color: #ffffff !important;
    border-color: #dadce0 !important;
  }
  &.is-invalid {
    border-color: #ff4444 !important;
    color: #ff4444 !important;
  }
}

/* Carousel style */

.image-gallery {
  .image-gallery-content {
    &.fullscreen {
      .image-gallery-slide-wrapper {
        .image-gallery-slide {
          height: auto;
        }
      }
    }
    .image-gallery-slide-wrapper {
      .image-gallery-slide {
        height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width: 992px) {
          height: 450px;
        }
        @media only screen and (max-width: 768px) {
          height: 400px;
        }
        @media only screen and (max-width: 480px) {
          height: 300px;
        }
        img {
          object-fit: cover;
          object-position: center center;
        }
      }
      .image-gallery-left-nav,
      .image-gallery-right-nav {
        display: flex;
        padding: 0;
        background-color: rgba(0, 0, 0, .5);
        filter: none;
        &:hover {
          background-color: #f56b2b;
          color: #ffffff;
        }
        svg {
          width: 40px;
          height: 40px;
        }
      }

      .image-gallery-fullscreen-button{
        display: flex;
        padding: 8px;
        background-color: rgba(0, 0, 0, .5);
        filter: none;
        &:hover {
          color: #ffffff;
        }
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  .carouselThumnail {
    height: 80px;
    &.active,
    &:hover {
      border-color: #f56b2b;
    }
    .image-gallery-thumbnail-inner {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.homepage-testimonials .testimonials .rec-dot {
  height: 12px !important;
  width: 12px !important;
  transform: scale(0.7) !important;
  margin: .3rem !important;
}

/* Global two adds show */

/* Form general style */

.half-form-wrapper {
  width: 50%;
  position: relative;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  .MuiFormControl-root {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.half-form-wrapper:nth-child(2n+1) {
  padding: 0 10px 0 0;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
}

.half-form-wrapper:nth-child(2n) {
  padding: 0 0 0 10px;
  @media screen and (max-width: 480px) {
    padding: 0;
  }
  .errorMessage {
    left: 10px;
    @media screen and (max-width: 480px) {
      left: 0;
    }
  }
}

.full-form-wrapper {
  width: 100%;
  position: relative;
  .MuiFormControl-root {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.tel-input {
  .errorMessage {
    left: 50px;
  }
}

/* Error message */

.errorMessage {
  position: absolute;
  left: 0;
  bottom: -12px;
  color: #f44336;
  margin: 0;
  font-size: 0.8rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  padding: 0;
}

.is-invalid .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

.MuiTextField-root.is-invalid .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f44336 !important;
}

/* Show and hide password input */

.show-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #555555;
  font-size: 1.5rem;
  margin-left: .5rem;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    transform: rotate(-45deg);
    width: 2px;
    height: 100%;
    background: #555555;
    border-right: 2px solid #fff;
    transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  }

  &.active {
    &::after {
      height: 0;
    }
  }
}

/* favorite popup add and remove */

.advertisements-list-popup {
  position: fixed;
  left: .5rem;
  bottom: .5rem;
  transition: .25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  background: rgba(#000, .75);
  border-radius: .1rem;
  color: #fff;
  padding: .5rem;
  z-index: 10;
  opacity: 0;
  transform: translateX(-100%);

  &.opened {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Dialog container */

.MuiDialog-root.dialog {
  z-index: 10000000 !important;
  .MuiDialog-paperScrollBody {
    margin: 50px 32px;
  }
  @media only screen and (max-width: 992px) {
    z-index: 1301 !important;
  }
}

.MuiPopover-root {
  z-index: 10000001 !important;
}

.MuiDialog-root .MuiDialog-container {
  height: calc(100% - 70px);
  outline: 0;
  position: relative;
  top: 70px;
  @media only screen and (max-width: 992px) {
    height: 100vh;
    top: 0;
    .MuiDialog-paperScrollPaper {
      width: 100%;
      height: calc(100% - 100px);
    }
  }
}

@media only screen and (max-width: 768px) {
  .MuiDialog-paper {
    margin: 25px !important;
  }
}

@media only screen and (max-width: 480px) {
  .MuiDialog-paper {
    margin: 20px !important;
  }
}

.dialog {
  &.diffusion {
    min-width: 50px;
    .diffusion-dialog {
      min-width: 500px;
      max-height: 500px !important;
      @media only screen and (max-width: 992px)  {
        min-width: 0;
        height: 100%;
        max-height: 100% !important;
        .MuiDialogContent-root {
          padding: 0 !important;
        }
      }
      #simple-dialog-title {
        padding: 12px 20px !important;
        height: auto;
        h2 {
          font-size: 18px;
          line-height: 20px;
          text-align: left;
        }
      }
      .prohibited-items {
        width: calc(100% - 40px);
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 20px;
        font-family: "Roboto", sans-serif;
        @media only screen and (max-width: 992px) {
          height: auto;
          padding: 20px;
        }
        ul {
          margin: 0;
          padding: 0;
          @media only screen and (max-width: 992px) {
            padding-left: 20px;
          }
          li {
            list-style: circle;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .MuiDialogActions-root {
        button {
          background-color: #1a73e9;
          color: #fff;
        }
      }
    }
  }
  &.suggestion  {
    .change-container {
      min-width: 500px;
    }
    .suggestion-container {
      width: 100%;
      &-item {
        width: 100%;
        margin-bottom: 20px;
        .MuiFormControl-root {
          width: 100%;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .suggestion-footer {
      button {
        padding: 8px 20px;
        &:first-child {
          //border: solid 1px #ddd;
        }
        &:last-child {
          background-color: #1a73e9;
          color: #fff;
          margin-right: 0;
          border: solid 1px #1a73e9;
          &:hover {
            background-color: transparent;
            color: #1a73e9;
          }
        }
      }
    }
  }
  .closeButton {
    position: absolute;
    top: -60px;
    right: -18px;
    color: #ffffff;
    font-size: 30px;
    @media only screen and (max-width: 992px) {
      top: -40px;
    }
  }
  .MuiPaper-rounded {
    background-color: transparent;
    overflow: visible;
    box-shadow: none;
    .change-container {
      padding: 0;
      width: 360px;
      max-height: 435px;
      //overflow: auto;
      background-color: #ffffff;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.375);
      border-radius: .1rem;
      display: flex;
      flex-direction: column;
      &.delete-modal {
        .MuiDialogContent-root {
          overflow: visible;
        }
      }
      @media only screen and (max-width: 768px) {
        width: 90vw;
        height: auto;
        max-height: 74vh;
        margin: auto;
      }
      .MuiDialogTitle-root {
        height: 25px;
        padding: 15px 20px !important;
        @media only screen and (max-width: 480px) {
          height: auto;
          padding: 12px 15px !important;
        }
        .MuiTypography-root {
          font-size: 18px;
          @media only screen and (max-width: 480px) {
            font-size: 16px;
          }
        }
      }
      .MuiDialogContent-root {
        padding: 20px 15px;
        height: calc(100% - 116px);
        border-top: 1px solid #eaebed;
        border-bottom: 1px solid #eaebed;
        overflow-y: auto;
        .dialog-container {
          p {
            text-align: center;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .react-tel-input {
            margin: 0;
            input {
              width: 100%;
            }
            .special-label {
              display: none;
            }
            .form-control {
              padding: .55rem 1rem;
              margin-left: 3rem;
              width: calc(100% - 3rem);

              & ~ .flag-dropdown {
                &::before {
                  left: 3.5rem;
                  color: #6b6f74;
                  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  content: attr(data-placeholder)!important;
                  position: absolute;
                  top: -7px;
                  display: block;
                  background: #fff;
                  padding: 0 5px;
                  font-size: 13px;
                  white-space: nowrap;
                }

                .selected-flag {
                  padding: 0;
                }
              }

              &:focus {
                & ~ .flag-dropdown {
                  &::before {
                    color: #0073f1;
                  }
                }
              }
            }
            &.is-invalid {
              .form-control {
                &:focus {
                  box-shadow: 0 0 0 1px #f4f4f4;
                  & ~ .flag-dropdown {
                    &::before {
                      color: #ff4444;
                    }
                  }
                }
              }
            }
          }
          &-item {
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
            .MuiTextField-root {
              width: 100%;
              margin: 0;

              .MuiOutlinedInput-input {
                padding: 14px 32px 10px 10px !important;
              }
            }
            .MuiFormControl-root {
              width: 100%;
            }
          }
        }
      }
      .confirmation-button {
        padding: 12px 8px 8px 8px;
        height: auto;
        width: calc(100% - 16px);
        button {
          width: 100%;
          display: flex;
          min-height: 40px;
          justify-content: center;
          background-color: #1a73e9;
          color: #ffffff;
          text-transform: none;
          margin-bottom: 0;
        }
      }
    }
  }
}

.MuiDialog-container {
  @media screen and (max-width: 992px) {
    height: calc(100% - 58px);
    position: relative;
    top: 58px;
  }
}

/* Tabs style */
.MuiTabs-scroller {
  border-bottom: solid 1px #dbdce0 !important;
}
.MuiTabs-indicator {
  height: 4px !important;
  background-color: #1d77d0 !important;
}

/* Countries flag */
.react-tel-input .flag-dropdown .country-list .search {
  box-shadow: 0 0.1rem 0.15rem rgba(100, 100, 100, 0.25) !important;
}
.react-tel-input .flag-dropdown .country-list .search-box {
  width: calc(100% - 10px) !important;
}

/* Load More Section */

.load-more-content {
  width: 100%;
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 14px;
    margin-bottom: 15px;
    /*color: #989898;*/
    color: #333333;
  }
  button {
    width: auto;
    display: inline-flex;
    margin: 0;
    background-color: #1a73e9;
    color: #ffffff;
    padding: 8px 70px;
    text-transform: none;
    font-size: 18px;
    transition: .3s ease-in-out;
    border: solid 1px #1a73e9;
    font-weight: normal;
    @media only screen and (max-width: 480px) {
      width: 100%;
      padding: 8px 40px;
      font-size: 16px;
    }
    &:disabled {
      background-color: #ccc;
      color: #000;
      border-color: #ccc;
      cursor: not-allowed;
      .MuiButton-label {
        .MuiCircularProgress-root {
          color: #000;
        }
      }
      &:hover {
        background-color: #ccc;
        color: #000000;
      }
    }
    &:hover {
      background-color: #ffffff;
      color: #1a73e9;
      box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    }
    .MuiButton-label {
      .MuiCircularProgress-root {
        width: 24px !important;
        height: 24px !important;
        margin-left: 15px;
        color: #ffffff;
      }
    }
  }
}

/* Default modal style */

@media screen and (max-width: 992px) {
  .dialog .MuiPaper-rounded .change-container {
    width: auto;
  }
}

#menu-categories .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: .3s ease-in-out;
  &:hover {
    color: #f56b2b;
    img {
      margin-right: 10px;
      filter: invert(47%) sepia(97%) saturate(418%) hue-rotate(333deg) brightness(96%) contrast(86%);
    }
  }
}

#menu-categories .MuiPaper-root.MuiMenu-paper .MuiList-root.MuiMenu-list li img {
  margin-right: 10px;
  width: 20px;
  transition: .3s ease-in-out;
}

/* Fixed buttons */

.mobile-fixed-buttons {
  width: 100%;
  display: none;
  flex-direction: row;
  background-color: #f56b2b;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  margin: 0;
  padding: 0;
  min-height: 58px;
  @media screen and (max-width: 768px) {
    display: flex;
  }
  &-item {
    width: 33.33%;
    padding: 0;
    a {
      width: 100%;
      height: 100% !important;
      display: inline-flex;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.875rem;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      &:hover {
        background-color: rgba(0, 0, 0, 0.04);
      }
      .direct-link {
        background: transparent;
        color: #fff !important;
      }
      svg {
        font-size: 22px;
        margin-bottom: 0;
        color: #fff;
      }
      span {
        color: #fff;
        text-transform: none;
        font-size: 14px;
      }
    }
    button {
      width: 100%;
      padding: 7px 10px 5px 10px;
      color: #ffffff;
      font-weight: normal;
      border-radius: 0 !important;
      background-color: transparent;
      min-height: 100%;
      border: none;
      &.whatsapp {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
        span {
          display: block;
          margin-top: 5px;
        }
      }
      &.hidden-message {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        svg {
          font-size: 22px;
          margin-bottom: 0;
          color: #fff;
        }
        span {
          color: #fff;
          text-transform: none;
          font-size: 14px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        }
      }
      .MuiButton-label {
        display: flex;
        flex-direction: column;
        svg {
          font-size: 22px;
          margin-bottom: 0;
        }
        span {
          text-transform: none;
          font-size: 14px;
        }
      }
    }
  }
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #f56b2b !important;
}

.logo-font {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 20px 0;
  padding: 0;
  h3 {
    font-size: 3rem;
    line-height: 2rem;
    margin: 0;
    padding: 0;
    text-transform: lowercase;
    font-family: 'airstream-webfont', sans-serif;
    transition: 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    color: #f56b2b;
  }
}

/* Footer breadcrumb */

.footer-breadcrumb {
  width: 100%;
  ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    li {
      font-size: 14px;
      &.separator {
        margin: 0 10px;
      }
      a {
        color: #1a73e9;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

/* Color display  */

.MuiPopover-root {
  .MuiPaper-root {
    .MuiList-root {
      .MuiButtonBase-root {
        display: flex;
        align-items: center;
        span {
          display: block;
          width: 16px;
          height: 16px;
          border: none;
          margin-right: 10px;
        }
      }
    }
  }
}

#onesignal-bell-container.onesignal-reset.onesignal-bell-container-bottom-right {
  bottom: 0;
  right: unset !important;
  left: 0 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right {
  bottom: 20px;
  right: unset !important;
  left: 20px !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-sm .onesignal-bell-launcher-message {
  right: unset !important;
  left: 44px !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right .onesignal-bell-launcher-message:after {
  left: unset !important;
  right: 100% !important;
  border-right-color: #000 !important;
  border-left-color: transparent !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog {
  bottom: 61px !important;
  right: unset !important;
  left: 0 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:before {
  right: unset !important;
  left: 0 !important;
}

#onesignal-bell-container.onesignal-reset .onesignal-bell-launcher.onesignal-bell-launcher-bottom-right.onesignal-bell-launcher-md .onesignal-bell-launcher-dialog:after {
  right: unset !important;
  left: 14px !important;
}

.quill-style {
  width: 100% !important;
  margin-bottom: 30px !important;
  height: max-content !important;
}