.removed-add-wrapper {
  width: 100%;
  display: flex;
  max-width: 70rem;
  flex-direction: column;
  margin: 100px auto;
  padding: 30px;
  height: auto;
  overflow-y: hidden;
  background-color: #ffffff;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    height: calc(100vh - 210px);
    max-width: 100%;
    margin: 50px auto;
  }
  @media only screen and (max-width: 992px) {
    margin: 70px 0 20px 0;
    min-height: calc(100vh - 130px);
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    height: auto;
  }
  h2 {
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }
}
