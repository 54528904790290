.app-dialog {
  z-index: 100000 !important;
  .MuiDialog-container {
    height: 100vh;
    top: 0;
    .MuiDialog-paperWidthSm {
      max-width: 640px;
      margin: 0;
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        height: auto;
        max-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
      }
      .closeButton {
        @media only screen and (max-width: 992px) {
          top: 40px;
          right: -15px;
        }
        @media only screen and (max-width: 768px) {
          top: -40px;
          right: 0;
        }
        @media only screen and (max-width: 480px) {
          top: -35px;
          right: 0;
        }
      }
      .MuiDialogContent-root {
        padding: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media only screen and (max-width: 768px) {
          width: calc(100% - 40px);
          padding: 20px;
        }
        @media only screen and (max-width: 600px) {
          width: calc(100% - 30px);
          padding: 20px 15px;
        }
        @media only screen and (max-width: 480px) {
          width: calc(100% - 20px);
          padding: 20px 10px;
        }
        .app-dialog-container {
          width: calc(640px - 60px);
          min-height: auto;
          background: rgb(255,90,97);
          background: linear-gradient(-240deg, rgba(255,90,97,1) 40%, rgba(254,155,64,1) 100%);
          border-radius: 10px;
          padding: 30px;
          display: flex;
          flex-direction: row;
          overflow: hidden;
          position: relative;
          z-index: 2;
          font-family: "AvenirMedium", sans-serif;
          @media only screen and (max-width: 480px) {
            flex-direction: column;
          }
          @media only screen and (max-width: 768px) {
            width: calc(100% - 40px);
            padding: 20px;
          }
          @media only screen and (max-width: 600px) {
            width: calc(100% - 30px);
            padding: 20px 15px;
          }
          &:after {
            content: "";
            width: 340px;
            height: 340px;
            border: solid 1px #fff;
            display: block;
            position: absolute;
            bottom: -100px;
            right: -50px;
            border-radius: 50%;
            z-index: -1;
            @media only screen and (max-width: 480px) {
              width: 480px;
              height: 480px;
              bottom: calc(-50% - 100px);
              right: unset;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &-left {
            width: calc(100% - 250px);
            display: flex;
            flex-direction: column;
            padding: 0 15px 0 0;
            @media only screen and (max-width: 768px) {
              width: 50%;
              padding: 0 10px 0 0;
            }
            @media only screen and (max-width: 480px) {
              width: 100%;
              padding-right: 0;
            }
            &-header {
              width: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              &-image {
                width: 60px;
                img {
                  width: 100%;
                }
              }
              &-text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0 0 0 10px;
                h3 {
                  color: #fff;
                  font-size: 22px;
                  line-height: 26px;
                  margin: 0;
                }
                p {
                  font-size: 16px;
                  line-height: 20px;
                  color: #fff;
                  margin: 0;
                }
              }
            }
            &-body {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin: 30px 0 0 0;
              p {
                color: #fff;
                margin: 0;
                font-size: 15px;
                line-height: 20px;
                font-weight: normal;
                text-align: center;
              }
              &-qr-image {
                width: 110px;
                height: 110px;
                margin: 25px 0 0 0;
                background-color: #fff;
                img {
                  width: 100%;
                }
              }
              &-qr-text {
                background-color: #fff;
                padding: 5px 15px;
                position: relative;
                margin: 20px 0 0 0;
                border-radius: 5px;
                &:before {
                  content: "";
                  display: block;
                  position: absolute;
                  top: -20px;
                  left: 50%;
                  transform: translateX(-50%);
                  border-color: transparent transparent #fff transparent;
                  border-width: 10px;
                  border-style: solid;
                }
                p {
                  font-weight: 500;
                  color: #000;
                  font-family: "AvenirMedium", sans-serif;
                }
              }
            }
            &-footer {
              width: 100%;
              display: flex;
              flex-direction: row;
              margin: 20px 0 0 0;
              ul {
                width: 100%;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                li {
                  margin-right: 10px;
                  &:last-child {
                    margin-right: 0;
                  }
                  a {
                    img {
                      width: auto;
                      height: 34px;
                      @media only screen and (max-width: 600px) {
                        height: 30px;
                      }
                    }
                  }
                }
              }
            }
          }
          &-right {
            width: 250px;
            padding: 0 0 0 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            @media only screen and (max-width: 768px) {
              width: 50%;
            }
            @media only screen and (max-width: 480px) {
              width: 100%;
              display: none;
            }
            img {
              width: auto;
              height: 440px;
              @media only screen and (max-width: 520px) {
                width: 100%;
                height: auto;
                padding: 0 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}
