.dashboard-ad-details {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    font-size: 24px;
    margin-bottom: 50px;
    display: block;
    padding-bottom: 10px;
    position: relative;
    &:after {
      content: '';
      width: 50px;
      height: 3px;
      display: block;
      background-color: #f56b2b;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
  }
  &-left {
    width: 40%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 0 auto 0;
    @media only screen and (max-width: 1200px) {
      width: calc(45% - 15px);
      padding-right: 15px;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;
    }
    &-item {
      width: calc(33.33% - 10px);
      height: 100px;
      overflow: hidden;
      margin-bottom: 15px;
      padding: 0;
      @media only screen and (max-width: 1200px) {
        height: 90px;
      }
      @media only screen and (max-width: 992px) {
        height: 180px;
      }
      @media only screen and (max-width: 768px) {
        width: calc(33.33% - 10px);
        height: 120px;
      }
      @media only screen and (max-width: 480px) {
        width: calc(50% - 10px);
        height: 130px;
      }
      &:nth-child(3n+1) {
        padding-right: 10px;
      }
      &:nth-child(3n+2) {
        padding-left: 5px;
        padding-right: 5px;
      }
      &:nth-child(3n+3) {
        padding-left: 10px;
      }
      @media only screen and (max-width: 480px) {
        &:nth-child(2n+1) {
          padding: 0 10px 0 0;
        }
        &:nth-child(2n) {
          padding: 0 0 0 10px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }
  &-right {
    width: calc(60% - 40px);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 0 40px;
    justify-content: space-between;
    @media only screen and (max-width: 1200px) {
      width: calc(55% - 15px);
      padding-left: 15px;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
      padding-left: 0;
    }
    &-item {
      width: calc(50% - 15px);
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 15px;
      }
      &.full-width {
        width: 100%;
      }
      label {
        color: #a4a4a4;
        font-size: 16px;
        margin-bottom: 10px;
        display: block;
      }
      input {
        width: calc(100% - 28px);
        background-color: #f5f8f9;
        border-radius: 5px;
        padding: 10px 15px;
        border: solid 1px #dadce0;
        font-size: 16px;
        outline: none;
        transition: .3s ease-in-out;
        &:focus {
          border-color: #1a73e9;
        }
      }
    }
    .submit-validation {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      button {
        background-color: #1a73e9;
        color: #ffffff;
        border: solid 1px #1a73e9;
        padding: 8px 20px;
        transition: .3s ease-in-out;
        font-size: 16px;
        &:hover {
          background-color: #ffffff;
          color: #1a73e9;
        }
      }
    }
  }
}