.cgu-container {
  width: 100%;
  display: flex;
  max-width: 70rem;
  flex-direction: column;
  margin: 100px auto 50px auto;
  padding: 30px;
  height: calc(100vh - 250px);
  overflow-y: hidden;
  background-color: #ffffff;
  @media only screen and (max-width: 1200px) {
    width: calc(100% - 60px);
    height: calc(100vh - 210px);
    max-width: 100%;
    margin: 50px auto;
  }
  @media only screen and (max-width: 992px) {
    margin: 70px 0 20px 0;
    min-height: calc(100vh - 130px);
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
    height: auto;
  }
  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 8px 20px 1px rgba(200, 200, 200, .8);
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      box-shadow: none;
      padding: 0;
    }
    &-tabs-wrapper {
      width: 300px;
      padding: 0;
      background-color: #f9f9f9;
      border-right: solid 1px #ddd;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      &-header {
        width: calc(100% - 30px);
        padding: 20px 15px;
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px #ddd;
        @media only screen and (max-width: 768px) {
          padding-top: 30px;
        }
        &-image {
          width: 70px;
          img {
            width: 100%;
            height: auto;
          }
        }
        &-text {
          width: calc(100% - 70px);
          padding: 0 0 0 15px;
          h1 {
            font-family: 'Roboto', sans-serif;
            font-size: 26px;
            margin: 0;
            color: #ec6501;
            line-height: 32px;
            font-weight: bolder;
            @media only screen and (max-width: 768px) {
              font-size: 24px;
              line-height: 26px;
            }
            &:after {
              content: "";
              display: block;
              background-color: #1a73e9;
              width: 40px;
              height: 2px;
              position: relative;
              left: 0;
              bottom: -3px;
            }
          }
          p {
            font-size: 13px;
            margin: 12px 0 0 0;
            font-family: "Roboto", sans-serif;
          }
        }
      }
      .cgu-container-tabs {
        @media only screen and (max-width: 768px) {
          border-bottom: solid 1px #ddd;
          .MuiButtonBase-root.MuiTabScrollButton-root {
            &:first-child {
              border-right: solid 1px #ddd;
            }
            &:last-child {
              border-left: solid 1px #ddd;
            }
          }
        }
      }
      .MuiTabs-scroller {
        width: 100%;
        border: none !important;
        .MuiTabs-indicator {
          display: none !important;
        }
        .MuiTabs-flexContainer {
          width: 100%;
          button {
            width: 100%;
            min-width: 100%;
            padding: 12px 20px;
            text-align: left;
            text-transform: capitalize;
            border-right: 4px solid transparent;
            font-family: 'Roboto', sans-serif;
            line-height: 20px;
            border-bottom: solid 1px #ddd;
            background-color: transparent;
            @media only screen and (max-width: 768px) {
              width: auto;
              font-size: 13px;
              line-height: 18px;
              padding: 12px 15px 8px 15px;
              min-width: 300px;
              border-right: none;
              border-bottom: solid 4px transparent;
              text-align: center;
              border-right: solid 1px #ddd;
              &:last-child {
                border-right: none;
              }
              &.Mui-selected {
                color: #ec6501;
                background-color: #fff;
                border-bottom-color: #ec6501;
                border-right-color: #ddd !important;
              }
            }
            &.Mui-selected {
              color: #ec6501;
              background-color: #fff;
              border-right-color: #ec6501;
            }
          }
        }
      }
    }
    &-tab-panel-wrapper {
      width: calc(100% - 300px);
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
      .MuiBox-root {
        padding: 0;
        .cgu-container-tab-panel-header {
          width: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          padding: 25px;
          @media only screen and (max-width: 768px) {
            padding-top: 30px;
          }
          @media only screen and (max-width: 480px) {
            width: calc(100% - 40px);
            padding: 30px 20px 20px 20px;
          }
          h2 {
            width: 100%;
            font-size: 24px;
            line-height: 30px;
            position: relative;
            font-weight: bolder;
            color: #333;
            @media only screen and (max-width: 768px) {
              font-size: 18px;
              line-height: 22px;
            }
            &:after {
              content: "";
              display: block;
              background-color: #000;
              width: 70px;
              height: 2px;
              position: absolute;
              left: 0;
              bottom: -20px;
            }
          }
        }
        .cgu-container-tab-panel-body {
          width: calc(100% - 50px);
          display: flex;
          flex-direction: column;
          padding: 25px;
          @media only screen and (max-width: 480px) {
            width: calc(100% - 40px);
            padding: 25px 20px;
          }
          &-item {
            margin: 0 0 30px 0;
            &:last-child {
              margin-bottom: 0;
            }
            h3 {
              font-size: 16px;
              font-weight: bolder;
              display: flex;
              align-items: center;
              margin-bottom: 5px;
              span {
                width: 22px;
                height: 22px;
                font-size: 11px;
                font-weight: lighter;
                line-height: 14px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: #fff;
                background-color: #1a73e9;
              }
            }
            p {
              font-size: 16px;
              line-height: 22px;
              margin-bottom: 12px;
              padding-left: 33px;
              &.except {
                padding-left: 0;
              }
              &:last-child {
                margin-bottom: 0;
              }
              a {
                text-decoration: none;
                color: #1a73e9;
                -webkit-transition: all .3s ease;
                -moz-transition: all .3s ease;
                transition: all .3s ease;
                &:hover {
                  color: #000;
                  text-decoration: underline;
                }
              }
            }
            ul {
              margin: 0;
              padding-left: 50px;
              li {
                margin-bottom: 10px;
                list-style-type: circle;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.MuiTabs-scrollButtonsDesktop {
  display: inline-flex !important;
}
