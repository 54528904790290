.dashboard-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 0 50px;
  padding: 0;
  @media screen and (max-width: 992px) {
    margin: 0 0 70px !important;
  }
  h2 {
    width: 100%;
    font-size: 24px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto 40px auto;
    color: #000000;
    &:after {
      content: "";
      position: relative;
      display: block;
      left: 50%;
      transform: translateX(-50%);
      bottom: -15px;
      width: 50px;
      height: 3px;
      background-color: #333333;
    }
    @media screen and (max-width: 992px) {
      max-width: 100%;
      margin-top: 5px;
      margin-bottom: 15px;
      font-size: 24px;
    }
    @media screen and (max-width: 768px) {
      font-size: 20px;
      text-align: left;
    }
  }
  &-container {
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: 0 auto;
    padding: 0;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    &-picture {
      width: calc(100% - 80px);
      border: 1px solid #dadce0;
      border-radius: 8px;
      background: #fff;
      margin-bottom: 25px;
      padding: 25px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 768px) {
        width: 100%;
        border: none;
        padding: 0;
      }
      &-text {
        display: flex;
        flex-direction: column;
        padding-right: 15px;
        h3 {
          font-size: 22px;
          font-weight: normal;
          color: #000000;
          margin-bottom: 20px;
          @media screen and (max-width: 768px) {
            font-size: 20px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 16px;
          line-height: 20px;
          color: #000000;
        }
      }
      &-choose {
        width: 105px;
        height: 105px;
        min-width: 105px;
        border-radius: .5rem;
        border: solid 2px #f56b2b;
        display: flex;
        flex-direction: column;
        padding: 10px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: .3s ease-in-out;
        @media screen and (max-width: 768px) {
          width: 95px;
          height: 95px;
          min-width: 95px;
        }
        @media screen and (max-width: 480px) {
          width: 80px;
          height: 80px;
          min-width: 80px;
        }
        input {
          display: none;
        }
        label {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          svg {
            color: #f56b2b;
            font-size: 2.5rem;
            margin-bottom: 15px;
            @media only screen and (max-width: 768px) {
              font-size: 2rem;
            }
          }
          span {
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            color: #f56b2b;
            @media only screen and (max-width: 768px) {
              font-size: 14px;
            }
          }
        }
      }
      &-default {
        width: 129px;
        height: 129px;
        min-width: 129px;
        border-radius: .5rem;
        border: solid 1px #dadce0;
        display: flex;
        flex-direction: column;
        padding: 0;
        position: relative;
        overflow: hidden;
        transition: .3s ease-in-out;
        @media screen and (max-width: 768px) {
          border: none;
          width: 115px;
          height: 115px;
          min-width: 115px;
        }
        @media screen and (max-width: 480px) {
          width: 100px;
          height: 100px;
          min-width: 100px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        button {
          width: 30px;
          height: 30px;
          position: absolute;
          top: -1px;
          right: -1px;
          background-color: #ff4444;
          color: #ffffff;
          border: none;
          outline: none;
          cursor: pointer;
          border-radius: 0 0 0 15px;
          svg {
            font-size: 20px;
          }
        }
      }
    }
    &-infos {
      width: calc(100% - 80px);
      border: 1px solid #dadce0;
      border-radius: 8px;
      background: #fff;
      padding: 25px 40px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        border: none;
      }
      &-item {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        @media screen and (max-width: 480px) {
          flex-direction: column;
        }

        &.submit {
          justify-content: flex-end;
          @media screen and (max-width: 480px) {
            justify-content: center;
          }
          button {
            background-color: #1a73e9;
            color: #ffffff;
            border: solid 1px #1a73e9;
            padding: 10px 40px;
            border-radius: 25px;
            transition: .3s ease-in-out;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            &:hover {
              background-color: #ffffff;
              color: #1a73e9;
            }
            .MuiCircularProgress-root {
              margin-left: 10px;
            }
          }
        }

        .half-form-wrapper,
        .full-form-wrapper {
          margin-bottom: 25px;
          @media screen and (max-width: 480px) {
            margin-bottom: 20px;
          }
        }

        .MuiOutlinedInput-input {
          padding: .65rem 1rem!important;
        }

        .select-category,
        .select-city,
        .select-district {
          .MuiInputLabel-formControl {
            top: -.7rem;
            left: .5rem;
            padding: 0 .5rem;
            background: #fff;
            z-index: 10;
            pointer-events: none;
          }
          .MuiSelect-select:focus {
            background: #fff;
          }
          .MuiInputLabel-shrink {
            transform: translate(0, 6px) scale(0.75);
          }
        }

        .MuiTextField-root {
          width: 100%;
          margin: 0;

          .MuiOutlinedInput-input {
            padding: 14px 32px 10px 10px !important;
          }

          .MuiOutlinedInput-multiline {
            padding: 0;
          }

          .MuiFormLabel-root.Mui-focused {
            color: #0073f1!important;
          }

          .MuiOutlinedInput-root.Mui-focused {
            .MuiOutlinedInput-notchedOutline {
              border-color: #0073f1!important;
            }
          }

          &.is-invalid {
            .MuiFormLabel-root.Mui-focused {
              color: #ff4444 !important;
            }

            .MuiOutlinedInput-root.Mui-focused {
              .MuiOutlinedInput-notchedOutline {
                border-color: #ff4444!important;
              }
            }
          }
        }
        .half-form-wrapper {
          @media screen and (max-width: 768px) {
            width: 100%;
            padding: 0;
          }
          @media screen and (max-width: 480px) {
            margin-bottom: 20px;
          }
          .react-tel-input {
            margin: 0;
            input {
              width: 100%;
              &:disabled {
                opacity: .5;
                &:hover {
                  border-color: #cccccc;
                }
              }
            }
            .special-label {
              display: none;
            }
            .form-control {
              padding: .55rem 1rem;
              margin-left: 3rem;
              width: calc(100% - 3rem);

              & ~ .flag-dropdown {
                &::before {
                  left: 3.5rem;
                  color: #6b6f74;
                  transition: .2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
                  content: attr(data-placeholder) !important;
                  position: absolute;
                  top: -7px;
                  display: block;
                  background: #fff;
                  padding: 0 5px;
                  font-size: 13px;
                  white-space: nowrap;
                }

                .selected-flag {
                  padding: 0;
                }
              }

              &:focus {
                & ~ .flag-dropdown {
                  &::before {
                    color: #0073f1;
                  }
                }
              }
            }
            &.is-invalid {
              .form-control {
                &:focus {
                  box-shadow: 0 0 0 1px #f4f4f4;
                  & ~ .flag-dropdown {
                    &::before {
                      color: #ff4444;
                    }
                  }
                }
              }
            }
          }
          button {
            width: 100%;
            transition: .3s ease-in-out;
            background-color: #f2f2f2;
            border: solid 1px #ececec;
            font-weight: normal;
            &:hover {
              border-color: #1a73e9;
              background-color: #1a73e9;
              color: #ffffff;
            }
            svg {
              font-size: 20px;
              margin-right: 5px;
            }
          }
        }
        .half-form-wrapper.name {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          @media screen and (max-width: 480px) {
            flex-direction: column;
          }
          .MuiFormControl-root {
            width: calc(50% - 10px);
            @media screen and (max-width: 480px) {
              width: 100%;
              padding: 0;
              margin-bottom: 20px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
        .half-form-wrapper.tel-input {
          .MuiFormControlLabel-root {
            width: 100%;
            margin-right: 0;
            .MuiTypography-root.MuiFormControlLabel-label {
              font-size: .9rem;
            }
          }
        }
      }
      h3 {
        font-size: 22px;
        font-weight: normal;
        color: #000000;
        margin-bottom: 20px;
        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
      }
      &-one {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
      }
    }
  }
}

.dialog {
  .MuiDialog-container {
    .connexion-number {
      width: 400px;
      .MuiDialogTitle-root {
        height: auto;
        padding: 30px 20px 15px 20px !important;
        h2 {
          text-align: center;
        }
      }
      .MuiDialogContent-root {
        padding: 15px 25px !important;
        border-top: none;
        border-bottom: none;
      }
      .dialog-container {
        &-item {
          position: relative;
          margin-bottom: 25px;
          .is-invalid {
            .MuiFormLabel-root,
            .MuiSvgIcon-root {
              color: #f44336 !important;
            }
            .MuiOutlinedInput-notchedOutline {
              border-color: #f44336 !important;
            }
          }
          .errorMessage {
            font-size: 13px !important;
            bottom: -23px !important;
            margin-bottom: 0;
          }
          p {
            margin-bottom: 20px;
          }
          .MuiInputLabel-formControl {
            background-color: #fff;
            padding: 0 3px;
            transform: translate(14px, 10px) scale(1);
            &.MuiInputLabel-shrink {
              z-index: 3;
              transform: translate(14px, -6px) scale(0.75);
            }
          }
        }
      }
      .MuiDialogActions-root.confirmation-button {
        width: calc(100% - 50px);
        height: auto;
        display: flex;
        justify-content: space-between;
        padding: 15px 25px 20px 25px !important;
        button {
          width: calc(50% - 8px);
          font-size: 16px;
          border-radius: 30px;
          padding: 10px;
        }
      }
    }
    .password-change {
      width: 300px;
      .MuiDialogTitle-root {
        text-align: center;
      }
    }
    .account-deletion {
      width: 400px;
      .MuiDialogTitle-root {
        text-align: center;
      }
    }
  }
}
